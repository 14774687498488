import { Injectable } from '@angular/core';
import { Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PlatformService } from '@common/util';

@Injectable()
export class AdminPlatformGuard  {
    constructor(
        private readonly platformService: PlatformService,
        private readonly router: Router
    ) {}

    public canLoad(route: Route): boolean {
        return this.Route(route.path);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.Route(state.url);
    }

    private Route(url: string | undefined): boolean {
        if (!this.platformService.isPlatformBrowser && url && url.includes('admin')) {
            void this.router.navigate(['shell']);
            return false;
        } else if (this.platformService.isPlatformBrowser && url && url.includes('shell')) {
            void this.router.navigate(['admin']);
            return false;
        }
        return true;
    }
}


@Injectable()
export class RegPlatformGuard  {
    constructor(
        private readonly platformService: PlatformService,
        private readonly router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const withToken: boolean = !!route.paramMap.get('token');
        return this.Route(state.url, withToken);
    }

    private Route(url: string, withToken: boolean): boolean {
        if (!this.platformService.isPlatformBrowser && withToken) {
            void this.router.navigate(['register', 'continue']);
            return false;
        } else if (this.platformService.isPlatformBrowser && !withToken) {
            void this.router.navigate([]);
            return false;
        }
        return true;
    }
}


