import { Mapper, UserStateModel, User } from '../models';

export class UserModelMapper implements Mapper<User, UserStateModel | null> {
    public mapFrom(raw: User): UserStateModel | null {
        if (!raw) {
            return null;
        }

        return {
            id: raw.id ?? null,
            firstName: raw.firstName ?? null,
            lastName: raw.lastName ?? null,
            email: raw.email ?? null,
            password: raw.password ?? null,

            hasUsablePassword: raw.hasUsablePassword ?? null,
            crispEmailHash: raw.crispEmailHash ?? null,
            FullName: raw.firstName + (raw.lastName ? ` ${raw.lastName}` : '') ?? null,

            phone: null // TODO: change after api model will be changed
        };
    }

    public mapTo(model: UserStateModel): User {
        return new User({
            id: model.id ?? null,
            firstName: model.firstName ?? null,
            lastName: model.lastName ?? null,
            email: model.email ?? null,
            password: model.password ?? null,

            hasUsablePassword: model.hasUsablePassword ?? null,
            crispEmailHash: model.crispEmailHash ?? null,
        });
    }
}
