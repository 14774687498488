import { Injectable } from '@angular/core';
import { LocalStorageKeyType } from '../enums';


@Injectable({ providedIn: 'root' })
export class StorageService {
    public setItem(key: LocalStorageKeyType, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public getItem(key: LocalStorageKeyType): unknown | null {
        const data: string | null = localStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    }

    public removeItem(key: LocalStorageKeyType): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
