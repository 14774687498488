import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fromSnackBarActions } from './snack-bar.actions';
import { SnackBarService } from '@common/util';

@Injectable()
export class SnackBarEffects {
    public showDefault: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromSnackBarActions.ShowDefault),
            tap((action: fromSnackBarActions.ShowDefault): void => {
                this.snackBarService.default(action.message, action.action, action.config);
            })
        ),
    { dispatch: false }
    );

    public showWarning: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromSnackBarActions.ShowWarning),
            tap((action: fromSnackBarActions.ShowWarning): void => {
                this.snackBarService.warning(action.message, action.action, action.config);
            })
        ),
    { dispatch: false }
    );

    public showDanger: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromSnackBarActions.ShowDanger),
            tap((action: fromSnackBarActions.ShowDanger): void => {
                this.snackBarService.danger(action.message, action.action, action.config);
            })
        ),
    { dispatch: false }
    );

    public showSuccess: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromSnackBarActions.ShowSuccess),
            tap((action: fromSnackBarActions.ShowSuccess): void => {
                this.snackBarService.success(action.message, action.action, action.config);
            })
        ),
    { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly snackBarService: SnackBarService
    ) {}
}
