export enum CONTACTS_LIST_SORT_COLUMNS {
    EVENTS_COUNT = 'eventCount',
    LAST_EVENT_DATE = 'last_record_dt',
    EMAIL = 'email',
    NAME = 'name',
}

export type ContactsListSortColumnType =
    | CONTACTS_LIST_SORT_COLUMNS.EVENTS_COUNT
    | CONTACTS_LIST_SORT_COLUMNS.LAST_EVENT_DATE
    | CONTACTS_LIST_SORT_COLUMNS.EMAIL
    | CONTACTS_LIST_SORT_COLUMNS.NAME;
