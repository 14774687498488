import { Directive, ViewContainerRef, Input, TemplateRef, HostListener } from '@angular/core';
import { DropDownService } from '../../services';

@Directive({
    selector: '[qwTooltip]',
    standalone: true
})
export class TooltipDirective {
    @Input('qwTooltip') public template: TemplateRef<any>;

    constructor(
        private readonly dds: DropDownService,
        private readonly viewContainerRef: ViewContainerRef
    ) {}

    @HostListener('click', ['$event'])
    public OpenTooltip(event: MouseEvent): void {
        this.dds.OpenTemplate(this.template, this.viewContainerRef, event.target as HTMLElement);
    }
}
