import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';
import { TimezoneNames } from '../constants';
import { getCurrentLanguage, getDaysOffset } from '../helpers';

@Pipe({
    name: 'timezoneName',
    standalone: true
})
export class TimezoneNamePipe implements PipeTransform {
    public transform(value: string): string {
        return TimezoneNames[value] || 'Неизвестная зона';
    }
}

@Pipe({
    name: 'moscowOffset',
    standalone: true
})
export class MoscowOffsetPipe implements PipeTransform {
    private readonly moscowOffset: number = moment.tz('Europe/Moscow').utcOffset();
    public transform(value: string): string {
        const zoneOffset: number = moment.tz(value).utcOffset();
        const delta: number = zoneOffset - this.moscowOffset;
        const hours: number = Math.trunc(delta / 60);
        const minutes: number = delta % 60;
        const hourStr: string = `${hours > 0 ? '+' : ''}${hours}`;
        const minuteStr: string = minutes ? `:${minutes < 10 ? '0' : ''}${minutes}` : '';
        return hourStr + minuteStr;
    }
}

@Pipe({
    name: 'durationOffset',
    standalone: true
})
export class DurationOffsetPipe implements PipeTransform {
    private readonly currentDate: number = Date.now();
    public transform(value: string | Date): string {
        const date: Date = typeof value === 'string' ? new Date(value) : value;
        const dateInMs: number = date.getTime();
        const delta: number = (dateInMs - this.currentDate) > 0 ? dateInMs - this.currentDate : 0;

        return getDaysOffset(delta);
    }
}

@Pipe({
    name: 'dateWithZone',
    standalone: true
})
export class DateWithZonePipe implements PipeTransform {
    private readonly defaultZone: string = moment.tz.guess(false);

    public transform(date: string | Date, timezone: string, format: string): string {
        moment.locale(getCurrentLanguage());

        return moment(date).tz(timezone || this.defaultZone).format(format);
    }
}

@Pipe({
    name: 'dateRelative',
    standalone: true
})
export class DateRelativePipe implements PipeTransform {
    private readonly defaultZone: string = moment.tz.guess(false);

    public transform(date: Date | string, timezone: string, format?: moment.CalendarSpec, referenceTime?: Date): string {
        const locDate: moment.Moment = moment(date).tz(timezone || this.defaultZone);

        if (!locDate) {
            return '';
        }

        return locDate.from(referenceTime);
    }
}
