import { HostProfile } from './host.model';
import { Team } from './team.model';
import { LocationType } from '../enums';

export interface GoogleAuthError {
    ok: false;
    code: string;
}

export enum AllowedLanguages {
    RU = 'RU',
    EN = 'EN'
}

export type Account = HostProfile | Team;
export enum Mode {
    PERSONAL,
    TEAM_MEMBER,
    TEAM
}

export type RequestContext = { team: string } | { profile: string };

// Integrations
export interface IntegrationPicture {
    source: string;
    src: string;
    alt: string;
}

export interface IntegrationStatus {
    title: string;
    color: string;
}

export interface IntegrationCardData {
    picture: IntegrationPicture;
    status: IntegrationStatus;
    categoryItems: string[];
    description: string;
    title: string;
}

export interface LocationTypeCardModel {
    type: LocationType;
    title: string;
    icon: {
        src: string;
        alt: string;
    },
    checked: boolean;
}
