import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CommonApiFacade } from './common-api-facade';
import {
    UserModelMapper,
    UpdateUserRequestModelMapper,
    UserMetadataModelMapper,
    convertPaginationApiModelToStateModel,
    convertBaseApiModelToStateModel,
    convertHostAnswerApiModelToStateModel,
    convertHostProfileShortApiModelToStateModel,
    convertPaymentPlansApiModelToStateModel,
    convertStripeSessionApiModelToStateModel,
    convertStripeTokenApiModelToStateModel,
    ApiModel,
    PaginationStateModel,
    HostAnswer,
    Schedule,
    UserStateModel,
    User,
    IPaymentPlansResponse,
    TeamMemberHostProfileShort,
    UserMetadataApiModel,
    UpdateUserRequestStateModel,
    UserMetadataStateModel,
    LocationLinkSettingsModelMapper,
    LocationLinkSettingsApiModel,
    LocationLinkSettingsStateModel,
} from '@common/util';
import { UserData } from 'libs/common/util/src/lib/models/types-generated';

/**
 * Implementation for "Adapter" pattern;
 * Layer for transformation api models to state models
 */
@Injectable()
export class CommonApiService {
    private readonly userModelMapper: UserModelMapper = new UserModelMapper();
    private readonly locationLinkSettingsModelMapper: LocationLinkSettingsModelMapper = new LocationLinkSettingsModelMapper();
    constructor(private readonly apiFacade: CommonApiFacade) {}

    public logout(): Observable<HttpResponse<null>> {
        return this.apiFacade.logout();
    }

    public getUser(): Observable<UserStateModel> {
        return this.apiFacade.getUser().pipe(
            map((response: User): UserStateModel => this.userModelMapper.mapFrom(response))
        );
    }

    public getUserMetadata(): Observable<UserMetadataStateModel> {
        const mapper: UserMetadataModelMapper = new UserMetadataModelMapper();
        return this.apiFacade.getUserMetadata().pipe(
            map((response: UserMetadataApiModel): UserMetadataStateModel => mapper.mapFrom(response))
        );
    }

    public updateUser(data: UpdateUserRequestStateModel): Observable<UserStateModel> {
        const mapper: UpdateUserRequestModelMapper = new UpdateUserRequestModelMapper();
        const apiData: UserData = mapper.mapTo(data);

        return this.apiFacade.updateUser(apiData).pipe(
            map((response: User): UserStateModel => this.userModelMapper.mapFrom(response))
        );
    }

    public loadHost(hostSlug: string): Observable<HostAnswer> {
        return this.apiFacade.loadHost(hostSlug).pipe(
            map((response: HttpResponse<any>): HostAnswer => convertHostAnswerApiModelToStateModel(response))
        );
    }

    public loadSchedule(hostSlug: string, activitySlug: string, year: number, month: number, params: object): Observable<{ days: Schedule }> {
        return this.apiFacade.loadSchedule(hostSlug, activitySlug, year, month, params);
    }

    // ================================
    // Root Registration
    // ================================

    public registerNewAccount(params: object): Observable<ApiModel> {
        return this.apiFacade.registerNewAccount(params).pipe(
            map((response: HttpResponse<ApiModel>): ApiModel => convertBaseApiModelToStateModel(response))
        );
    }

    public confirmRegistration(params: object): Observable<boolean> {
        return this.apiFacade.confirmRegistration(params).pipe(
            map((response: HttpResponse<any>): boolean => true)
        );
    }

    public repeatConfirmation(): Observable<HttpResponse<null>> {
        return this.apiFacade.repeatConfirmation();
    }

    // ================================
    // Onboarding
    // ================================

    public finishOnboarding(): Observable<HttpResponse<null>> {
        const data: { onboarding_finished: boolean } = { onboarding_finished: true };
        return this.apiFacade.finishOnboarding(data);
    }

    // ================================
    // Team
    // ================================

    public getTeamMembers(teamSlug: string, activitySlug: string): Observable<PaginationStateModel<TeamMemberHostProfileShort>> {
        return this.apiFacade.getTeamMembers(teamSlug, activitySlug).pipe(
            map((response: HttpResponse<any>): PaginationStateModel<TeamMemberHostProfileShort> => {
                return convertPaginationApiModelToStateModel(convertHostProfileShortApiModelToStateModel, response);
            })
        );
    }

    // ================================
    // Stripe
    // ================================

    public getStripeToken(): Observable<{ publicKey: string }> {
        return this.apiFacade.getStripeToken().pipe(
            map((response: HttpResponse<any>): { publicKey: string } => convertStripeTokenApiModelToStateModel(response))
        );
    }

    public createStripeSession(hostProfileId: string, paymentOptionId: number): Observable<{ sessionId: string }> {
        return this.apiFacade.createStripeSession(hostProfileId, paymentOptionId).pipe(
            map((response: HttpResponse<any>): { sessionId: string } => convertStripeSessionApiModelToStateModel(response))
        );
    }

    // TODO: avoid this method
    public getPaymentPlans(): Observable<IPaymentPlansResponse> {
        return this.apiFacade.getPaymentPlans().pipe(
            map((response: HttpResponse<any>): IPaymentPlansResponse => convertPaymentPlansApiModelToStateModel(response))
        );
    }

    // ================================
    // Locations Link Integration
    // ================================

    public getLocationsLinkSettings(hostId: string): Observable<LocationLinkSettingsStateModel[]> {
        return this.apiFacade.getLocationsLinkSettings(hostId).pipe(
            map((response: any): LocationLinkSettingsStateModel[] => {
                return response.list.map((link: LocationLinkSettingsApiModel) => this.locationLinkSettingsModelMapper.mapFrom(link));
            })
        );
    }

    public saveLocationLinkSettings( model: LocationLinkSettingsStateModel): Observable<LocationLinkSettingsStateModel | null> {
        const apiModel: LocationLinkSettingsApiModel = this.locationLinkSettingsModelMapper.mapTo(model);

        return this.apiFacade.saveLocationLinkSettings(apiModel).pipe(
            map((response: LocationLinkSettingsApiModel): LocationLinkSettingsStateModel | null  => {
                return this.locationLinkSettingsModelMapper.mapFrom(response);
            })
        );
    }

    public updateLocationLinkSettings( model: LocationLinkSettingsStateModel, settingsId: string): Observable<LocationLinkSettingsStateModel | null> {
        const apiModel: LocationLinkSettingsApiModel = this.locationLinkSettingsModelMapper.mapTo(model);

        return this.apiFacade.updateLocationLinkSettings(apiModel, settingsId).pipe(
            map((response: LocationLinkSettingsApiModel): LocationLinkSettingsStateModel | null  => {
                return this.locationLinkSettingsModelMapper.mapFrom(response);
            })
        );
    }
}
