import { HostProfile } from './host.model';
import { ActivityTypeStateModel } from './activity-type-state.model';
import { CURRENCY_ENUM, LocationType } from '../enums';

export interface Slot {
    full: string;
    time: string;
    participants: number;
}

export interface ICalendarDay {
    /** Date in YYYY-MM-DD format: "2023-05-16" */
    id: string;
    date: Date;
    slots: Slot[] | null;
}

export interface ContactData {
    participantEmail: string;
    participantName: string;
    comment: string;
    locationType: LocationType;
    locationContact: string | null;

    phone?: string;
}

export interface BookingData extends ContactData {
    host: HostProfile;
    activity: ActivityTypeStateModel;
    tz: string;
    begin: Date;
    end: Date;
}

export type Schedule = Array<{ date: string; slots: Slot[]; }>;

export class MonthSchedule {
    public hostProfile: HostProfile;
    public activityType: ActivityTypeStateModel;

    public year: number;
    public month: number;
    public tz: string;

    public days: Schedule;

    constructor(
        hostProfile: HostProfile,
        activityType: ActivityTypeStateModel,
        date: Date,
        tz: string,
        days: Schedule
    ) {
        this.hostProfile = hostProfile;
        this.activityType = activityType;

        this.year = date.getFullYear();
        this.month = date.getMonth();
        this.tz = tz;

        this.days = days;
    }

    public valueOf(): string {
        return MonthSchedule.GetValueOf(
            this.hostProfile.slug,
            this.activityType.slug,
            this.year,
            this.month,
            this.tz
        );
    }

    public static GetValueOf(host: string, slug: string, year: number, month: number, tz: string): string {
        return `${host}_${slug}_${year}_${month}_${tz}`;
    }
}

export interface BookEventModel {
    host: string;
    slot_time: string;
    activity_type: string;
    participant_name: string;
    participant_email: string;
    location_contact: string | null;
    location_type: LocationType;
    utm_labels: UtmLabelsModel;
    client_timezone: string;
    comment: string;
    phone?: string;
    event_id?: string | number;
    endTime?: string;
    hostId?: string;
}

export interface UtmLabelsModel {
    source?: string;
    medium?: string;
    campaign?: string;
    term?: string;
    content?: string;
    id?: string;
}

export interface EventResponseModel {
    ok: boolean;
    locationType: string;
    locationLink: string;
    hostProfileSlug?: string;
}

export interface PaidResponseModel {
    ok: boolean;
    amount: number;
    currency: CURRENCY_ENUM;
    token: string;
}

export interface HostAnswer {
    ok: boolean;
    hostProfile: HostProfile;
    activityTypes: ActivityTypeStateModel[];
}
