import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(private readonly http: HttpClient) {}

    private static get headers(): HttpHeaders {
        const headersConfig: { [name: string]: string | string[] } = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };

        return new HttpHeaders(headersConfig);
    }

    public get<R, T extends {} = {}>(url: string, params: T | HttpParams = new HttpParams()): Observable<R> {
        /** Put unique value in request to avoid caching on the request */
        if (!(params instanceof HttpParams)) {
            params = new HttpParams({ fromObject: params });
            params.set('timestamp', new Date().getTime().toString());
        } else {
            url = url.includes('?')
                ? (url += `&timestamp=${new Date().getTime()}`)
                : (url += `?timestamp=${new Date().getTime()}`);
        }

        return this.http.get<R>(`${url}`, {
            headers: ApiService.headers,
            params
        });
    }

    public post<R, T extends {}, P extends {} = {}>(
        url: string,
        data: T,
        params: T | HttpParams = new HttpParams(),
        headers: HttpHeaders = ApiService.headers
    ): Observable<R> {
        if (!(params instanceof HttpParams)) {
            params = new HttpParams({ fromObject: params });
        }

        const isFormData: boolean = data instanceof FormData;

        return this.http.post<R>(`${url}`, isFormData ? data : JSON.stringify(data), {
            headers: isFormData ? undefined : headers,
            params
        });
    }

    public patch<R, T extends {}>(url: string, data: T, params: T | HttpParams = new HttpParams()): Observable<R> {
        if (!(params instanceof HttpParams)) {
            params = new HttpParams({ fromObject: params });
        }

        const isFormData: boolean = data instanceof FormData;

        return this.http.patch<R>(`${url}`, isFormData ? data : JSON.stringify(data), {
            headers: isFormData ? undefined : ApiService.headers,
            params
        });
    }

    public put<R, T extends {}>(url: string, data: T, params: {} | HttpParams = new HttpParams()): Observable<R> {
        if (!(params instanceof HttpParams)) {
            params = new HttpParams({ fromObject: params });
        }

        return this.http.put<R>(`${url}`, JSON.stringify(data), {
            headers: ApiService.headers,
            params
        });
    }

    public delete<R, P extends {} = {}, T extends {} = {}>(
        url: string,
        params: P | HttpParams = new HttpParams(),
        data?: T
    ): Observable<R> {
        if (!(params instanceof HttpParams)) {
            params = new HttpParams({ fromObject: params });
        }

        return this.http.request<R>('delete', `${url}`, {
            headers: ApiService.headers,
            params,
            body: data
        });
    }

    public getFile(url: string, params: HttpParams = new HttpParams()): Observable<string> {
        return this.http.get(`${url}`, {
            params,
            responseType: 'text'
        });
    }

    public uploadFile(url: string, params: HttpParams = new HttpParams()): Observable<string> {
        return this.http.get(`${url}`, {
            params,
            responseType: 'text'
        });
    }
}
