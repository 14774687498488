import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';

import { FeaturePartialState } from './feature.state';

@Injectable()
export class FeatureEffects {
    constructor(private readonly actions$: Actions, private readonly store: Store<FeaturePartialState>) {}
}
