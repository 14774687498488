import { PageModel } from '../models';
import { DEFAULT_PAGE_MODEL } from '../constants';

function getEndIndex(pageNumber: number, pageSize: number, listSize: number): number {
    const potentialEnd: number = pageSize * pageNumber;
    return potentialEnd > listSize ? listSize : potentialEnd;
}

function hasNext(pageNumber: number , pageSize: number, listSize: number): boolean {
    return listSize > pageNumber * pageSize;
}

export function setPageModel(currentPageModel: PageModel, partialPageModel: Partial<PageModel>): PageModel {
    return {
        ...currentPageModel,
        ...partialPageModel,
        hasPrev: partialPageModel.pageNumber ? partialPageModel.pageNumber > 1 : currentPageModel.hasPrev,
        hasNext:
            partialPageModel.pageNumber && partialPageModel.pageSize && partialPageModel.listSize
                ? hasNext(partialPageModel.pageNumber, partialPageModel.pageSize, partialPageModel.listSize)
                : hasNext(partialPageModel.pageNumber, currentPageModel.pageSize, currentPageModel.listSize),
        endIndex:
            partialPageModel.pageNumber && partialPageModel.pageSize && partialPageModel.listSize
                ? getEndIndex(partialPageModel.pageNumber, partialPageModel.pageSize, partialPageModel.listSize)
                : getEndIndex(partialPageModel.pageNumber, currentPageModel.pageSize, currentPageModel.listSize),
        startIndex: partialPageModel.pageNumber
            ? (partialPageModel.pageNumber - 1) * currentPageModel.pageSize + 1
            : currentPageModel.startIndex,
        isFirstPage: partialPageModel.pageNumber ? partialPageModel.pageNumber === 1 : currentPageModel.isFirstPage,
        isLastPage:
            partialPageModel.pageNumber && partialPageModel.pageSize && partialPageModel.listSize
                ? !hasNext(partialPageModel.pageNumber, partialPageModel.pageSize, partialPageModel.listSize)
                : !hasNext(partialPageModel.pageNumber, currentPageModel.pageSize, currentPageModel.listSize)
    };
}

export function resetPageModel(currentPageModel: PageModel): PageModel {
    return {
        ...currentPageModel,
        pageNumber: DEFAULT_PAGE_MODEL.pageNumber,
        startIndex: DEFAULT_PAGE_MODEL.startIndex,
        endIndex: currentPageModel.pageSize
    };
}
