import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { createAction, props } from '@ngrx/store';
import { featureCreator } from '../feature.creator';
import { FEATURE_STATE_KEY } from '../feature.keys';
import { ActionCreatorBy } from '@common/util';

export namespace fromSnackBarActions {
    const actionTypeCreator: (actionDes: string) => string = featureCreator(FEATURE_STATE_KEY.SNACK_BAR_STATE);

    export const ShowDefault: ActionCreatorBy.Props<{ message: string; action?: string; config?: MatSnackBarConfig }> = createAction(
        actionTypeCreator('Show Default'),
        props<{ message: string; action?: string; config?: MatSnackBarConfig }>()
    );
    export type ShowDefault = ReturnType<typeof ShowDefault>;

    export const ShowWarning: ActionCreatorBy.Props<{ message: string; action?: string; config?: MatSnackBarConfig }> = createAction(
        actionTypeCreator('Show Warning'),
        props<{ message: string; action?: string; config?: MatSnackBarConfig }>()
    );
    export type ShowWarning = ReturnType<typeof ShowWarning>;

    export const ShowDanger: ActionCreatorBy.Props<{ message: string; action?: string; config?: MatSnackBarConfig }> = createAction(
        actionTypeCreator('Show Danger'),
        props<{ message: string; action?: string; config?: MatSnackBarConfig }>()
    );
    export type ShowDanger = ReturnType<typeof ShowDanger>;

    export const ShowSuccess: ActionCreatorBy.Props<{ message: string; action?: string; config?: MatSnackBarConfig }> = createAction(
        actionTypeCreator('Show Success'),
        props<{ message: string; action?: string; config?: MatSnackBarConfig }>()
    );
    export type ShowSuccess = ReturnType<typeof ShowSuccess>;

    export const Dismiss: ActionCreatorBy.Type = createAction(actionTypeCreator('Dismiss'));
    export type Dismiss = ReturnType<typeof Dismiss>;
}
