import { Mapper, ICalendar, ICalendarIntegration, Integrations } from '../../models';
import { CALENDAR_INTEGRATION_TYPE_ENUM } from '../../enums';
import {
    MsCalendarInfoFragment,
    GCalendarInfoFragment,
    FullGoogleIntegrationFragment,
    FullMicrosoftIntegrationFragment,
    IntegrationsFragment
} from 'libs/common/util/src/lib/models/types-generated';


type CalendarInfo = MsCalendarInfoFragment | GCalendarInfoFragment;
// tslint:disable-next-line: max-line-length
type CalendarIntegrationInfo =
    (FullGoogleIntegrationFragment | FullMicrosoftIntegrationFragment) & { profile: string, calendars: CalendarInfo[] };

export class CalendarMapper implements Mapper<CalendarInfo, ICalendar> {
    mapFrom(raw: CalendarInfo): ICalendar {
        return raw
            ? {
                id: raw.id,
                name: raw.name,
                includeBusy: raw.includeBusy,
                createEvent: raw.createEvent,
            }
            : undefined;
    }

    mapTo(model: ICalendar): CalendarInfo {
        return model;
    }
}

export class CalendarIntegrationMapper implements Mapper<CalendarIntegrationInfo, ICalendarIntegration> {
    private calendarMapper = new CalendarMapper();

    mapFrom(raw: CalendarIntegrationInfo): ICalendarIntegration {
        const email = raw.__typename === 'MicrosoftIntegration' ? null : raw.email;
        const calendars = raw.calendars.map(this.calendarMapper.mapFrom);
        return {
            email,
            calendarAccess: raw.calendarAccess,
            hostId: raw.profile,
            calendars,
            type: CALENDAR_INTEGRATION_TYPE_ENUM.GOOGLE
        };
    }

    mapTo(model: ICalendarIntegration): CalendarIntegrationInfo {
        return {
            profile: model.hostId,
            calendars: model.calendars,
            calendarAccess: model.calendarAccess,
            email: model.email
        };
    }
}

export class IntegrationMapper implements Mapper<IntegrationsFragment, Integrations> {
    mapFrom(raw: IntegrationsFragment): Integrations {
        if (!raw) { return { zoom: false }; }

        return {
            zoom: raw.zoom,
        };
    }

    mapTo(model: Integrations): IntegrationsFragment {
        return model;
    }
}
