import { EventApiModel, EventStateModel, HostApiModel, HostStateModel, ParticipantApiModel, ParticipantStateModel } from '../models';
import { convertActivityTypeApiBaseModelToStateModel } from './activity-type-base-model.converter';
import { convertBaseHostApiModelToStateModel } from './host-converter';

export function convertHostApiModelToStateModel(api: HostApiModel): HostStateModel {
    return {
        avatar: api.avatar ?? null,
        email: api.email ?? null,
        id: api.id ?? null,
        name: api.name ?? null,
        phone: api.phone ?? null,
        timezone: api.timezone_str ?? null,
        slug: api.slug ?? null,
    };
}

export function convertParticipantApiModelToStateModel(api: ParticipantApiModel): ParticipantStateModel {
    return {
        participant: convertBaseHostApiModelToStateModel(api.participant) ?? null,
        phone: api.phone ?? null,
        comment: api.comment ?? null,
        id: api.id ?? null,
    };
}

export function convertEventApiModelToStateModel(api: EventApiModel): EventStateModel {
    return {
        id: api.id,
        begin: api.begin_dt,
        end: api.end_dt,
        created: api.created,
        comment: api.comment,
        activityType: convertActivityTypeApiBaseModelToStateModel(api.activity_type),
        eventParticipants: api.event_participants.map((raw: ParticipantApiModel) => convertParticipantApiModelToStateModel(raw)),
        host: convertHostApiModelToStateModel(api.host),
        location: {
            contact: api.location_contact,
            link: api.location_link,
            type: api.location_type,
        },
        utmLabels: api.labels.map((raw: any) => ({
            key: raw.key,
            value: raw.value
        })),
    };
}
