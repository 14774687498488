import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
    selector: 'qw-admin-shell',
    templateUrl: './admin-shell.component.html',
    styleUrls: ['./admin-shell.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminShellComponent {}
