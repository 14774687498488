export type ComparatorType<T> = (aa: T, bb: T) => number;

export function safeCompare<T>(a: T, b: T, comparator: ComparatorType<T>): number {
    if (a == null) {
        if (b == null) {
            return 0;
        } else {
            return 1;
        }
    } else {
        if (b == null) {
            return -1;
        } else {
            return comparator(a, b);
        }
    }
}

export function safeNumCompare(a: number, b: number): number {
    return safeCompare(a, b, () => a - b);
}

export function safeDateCompare(a: string, b: string): number {
    const dateA: number = new Date(a).getMilliseconds();
    const dateB: number = new Date(b).getMilliseconds();

    return safeCompare(dateA, dateB, () => dateA - dateB);
}
