export enum LOCAL_STORAGE_KEYS_ENUM {
    IS_TG_NOTIFICATION_SHOWN = 'qlick.isTgNotificationShown',
    BOOKED_EVENT_DATA = 'qlick.bookedEventData',
    ONBOARDING_CURRENT_STEP = 'qlick.onboardingCurrentStep'
}

export type LocalStorageKeyType =
    | LOCAL_STORAGE_KEYS_ENUM.IS_TG_NOTIFICATION_SHOWN
    | LOCAL_STORAGE_KEYS_ENUM.BOOKED_EVENT_DATA
    | LOCAL_STORAGE_KEYS_ENUM.ONBOARDING_CURRENT_STEP;
