import { createAction, props } from '@ngrx/store';

import { featureCreator } from '../feature.creator';
import { FEATURE_STATE_KEY } from '../feature.keys';
import {
    UpdateUserRequestStateModel,
    UserMetadataStateModel,
    ActionCreatorBy,
    UserStateModel,
    ApiModel
} from '@common/util';

export namespace fromAppActions {
    const actionTypeCreator: (actionDes: string) => string = featureCreator(FEATURE_STATE_KEY.APP_STATE);

    export const Reset: ActionCreatorBy.Type = createAction(actionTypeCreator('Reset'));
    export type Reset = ReturnType<typeof Reset>;

    export const ResetError: ActionCreatorBy.Type = createAction(actionTypeCreator('Reset Error'));
    export type ResetError = ReturnType<typeof ResetError>;

    export const ResetIsChecked: ActionCreatorBy.Type = createAction(actionTypeCreator('Reset Is Checked'));
    export type ResetIsChecked = ReturnType<typeof ResetIsChecked>;

    // ================================
    //  Logout
    // ================================

    export const Logout: ActionCreatorBy.Type = createAction(actionTypeCreator('Logout'));
    export type Logout = ReturnType<typeof Logout>;

    export const LogoutSuccess: ActionCreatorBy.Type = createAction(actionTypeCreator('Logout Success'));
    export type LogoutSuccess = ReturnType<typeof LogoutSuccess>;

    export const LogoutError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Logout Error'),
        props<{ error: string }>()
    );
    export type LogoutError = ReturnType<typeof LogoutError>;

    // ================================
    //  Load User
    // ================================

    export const LoadUser: ActionCreatorBy.Type = createAction(actionTypeCreator('Load User'));
    export type LoadUser = ReturnType<typeof LoadUser>;

    export const LoadUserSuccess: ActionCreatorBy.Props<{ model: UserStateModel }> = createAction(
        actionTypeCreator('Load User Success'),
        props<{ model: UserStateModel }>()
    );
    export type LoadUserSuccess = ReturnType<typeof LoadUserSuccess>;

    export const LoadUserError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Load User Error'),
        props<{ error: string }>()
    );
    export type LoadUserError = ReturnType<typeof LoadUserError>;

    // ================================
    //  Fetch User
    // ================================

    export const FetchUser: ActionCreatorBy.Type = createAction(actionTypeCreator('Fetch User'));
    export type FetchUser = ReturnType<typeof FetchUser>;

    export const FetchUserSuccess: ActionCreatorBy.Props<{ model: UserStateModel }> = createAction(
        actionTypeCreator('Fetch User Success'),
        props<{ model: UserStateModel }>()
    );
    export type FetchUserSuccess = ReturnType<typeof FetchUserSuccess>;

    export const FetchUserError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Fetch User Error'),
        props<{ error: string }>()
    );
    export type FetchUserError = ReturnType<typeof FetchUserError>;

    // ================================
    //  Load User Metadata
    // ================================

    export const LoadUserMetadata: ActionCreatorBy.Type = createAction(actionTypeCreator('Load User Metadata'));
    export type LoadUserMetadata = ReturnType<typeof LoadUserMetadata>;

    export const LoadUserMetadataSuccess: ActionCreatorBy.Props<{ model: UserMetadataStateModel }> = createAction(
        actionTypeCreator('Load User Metadata Success'),
        props<{ model: UserMetadataStateModel }>()
    );
    export type LoadUserMetadataSuccess = ReturnType<typeof LoadUserMetadataSuccess>;

    export const LoadUserMetadataError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Load User Metadata Error'),
        props<{ error: string }>()
    );
    export type LoadUserMetadataError = ReturnType<typeof LoadUserMetadataError>;

    // ================================
    //  Update User
    // ================================

    export const UpdateUser: ActionCreatorBy.Props<{ model: UpdateUserRequestStateModel }> = createAction(
        actionTypeCreator('Update User'),
        props<{ model: UpdateUserRequestStateModel }>()
    );
    export type UpdateUser = ReturnType<typeof UpdateUser>;

    export const UpdateUserSuccess: ActionCreatorBy.Props<{ model: UserStateModel }> = createAction(
        actionTypeCreator('Update User Success'),
        props<{ model: UserStateModel }>()
    );
    export type UpdateUserSuccess = ReturnType<typeof UpdateUserSuccess>;

    export const UpdateUserError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Update User Error'),
        props<{ error: string }>()
    );
    export type UpdateUserError = ReturnType<typeof UpdateUserError>;

    // ================================
    // Repeat Confirmation Email
    // ================================

    export const RepeatConfirmation: ActionCreatorBy.Type = createAction(actionTypeCreator('Repeat Confirmation'));
    export type RepeatConfirmation = ReturnType<typeof RepeatConfirmation>;

    export const RepeatConfirmationSuccess: ActionCreatorBy.Type = createAction(actionTypeCreator('Repeat Confirmation Success'));
    export type RepeatConfirmationSuccess = ReturnType<typeof RepeatConfirmationSuccess>;

    export const RepeatConfirmationError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Repeat Confirmation Error'),
        props<{ error: string }>()
    );
    export type RepeatConfirmationError = ReturnType<typeof RepeatConfirmationError>;

    // ================================
    // Register
    // ================================

    export const Register: ActionCreatorBy.Props<{ email: string }> = createAction(
        actionTypeCreator('Register'),
        props<{ email: string }>()
    );
    export type Register = ReturnType<typeof Register>;

    export const RegisterSuccess: ActionCreatorBy.Props<{ response: ApiModel; email: string; }> = createAction(
        actionTypeCreator('Register Success'),
        props<{ response: ApiModel; email: string; }>()
    );
    export type RegisterSuccess = ReturnType<typeof RegisterSuccess>;

    export const RegisterError: ActionCreatorBy.Props<{ error: string }> = createAction(
        actionTypeCreator('Register Error'),
        props<{ error: string }>()
    );
    export type RegisterError = ReturnType<typeof RegisterError>;

    // ================================
    // Confirm registration
    // ================================

    export const ConfirmRegistrationOptimistic: ActionCreatorBy.Props<{ token: string }> = createAction(
        actionTypeCreator('Confirm Registration Optimistic'),
        props<{ token: string }>()
    );
    export type ConfirmRegistrationOptimistic = ReturnType<typeof ConfirmRegistrationOptimistic>;

    export const ConfirmRegistrationSuccess: ActionCreatorBy.Type = createAction(
        actionTypeCreator('Confirm Registration Success')
    );
    export type ConfirmRegistrationSuccess = ReturnType<typeof ConfirmRegistrationSuccess>;

    export const ConfirmRegistrationError: ActionCreatorBy.Type = createAction(
        actionTypeCreator('Confirm Registration Error')
    );
    export type ConfirmRegistrationError = ReturnType<typeof ConfirmRegistrationError>;
}
