import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { FEATURE_STATE_KEY } from './feature.keys';
import { FeatureState } from './feature.state';

import { appReducer } from './app/app.reducer';
import { snackBarReducer } from './snack-bar/snack-bar.reducer';

export const featureReducers: ActionReducerMap<FeatureState> = {
    [FEATURE_STATE_KEY.APP_STATE]: appReducer,
    [FEATURE_STATE_KEY.SNACK_BAR_STATE]: snackBarReducer,
};

export const FEATURE_REDUCERS: InjectionToken<ActionReducerMap<FeatureState>> = new InjectionToken<ActionReducerMap<FeatureState>>(
    'COMMON_FEATURE_REDUCERS'
);
