export enum PLAN_PERMISSION_CASE {
    FORMAT_EVENT_TYPE = 'Format.eventType',
    FORMAT_CONFIRMATION_TYPE_STANDARD = 'Format.confirmationType.Standard',
    FORMAT_CONFIRMATION_TYPE_PRO = 'Format.confirmationType.Pro',
    FORMAT_PRICE = 'Format.price',
    INTEGRATION_PAYMENT = 'Integration.payment',
    NOTIFICATIONS = 'Notifications'
}

export type PlanPermissionCaseType =
    | PLAN_PERMISSION_CASE.FORMAT_EVENT_TYPE
    | PLAN_PERMISSION_CASE.FORMAT_CONFIRMATION_TYPE_STANDARD
    | PLAN_PERMISSION_CASE.FORMAT_CONFIRMATION_TYPE_PRO
    | PLAN_PERMISSION_CASE.FORMAT_PRICE
    | PLAN_PERMISSION_CASE.INTEGRATION_PAYMENT
    | PLAN_PERMISSION_CASE.NOTIFICATIONS;
