import { EventResponseModel } from '../models';


export function convertBookEventApiModelToStateModel(api: any): EventResponseModel {
    return {
        ok: api.ok ?? false,
        locationType: api.locationType ?? null,
        locationLink: api.locationLink ?? null,
        hostProfileSlug: api.hostProfileSlug ?? null,
    };
}
