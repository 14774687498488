import { PaidResponseModel } from '../models';

export function convertReserveEventApiModelToStateModel(api: any): PaidResponseModel {
    return {
        ok: api.ok,
        amount: api.amount,
        currency: api.currency,
        token: api.token,
    };
}
