export class Team {
    constructor(
        public id: string,
        public name: string,
        public slug: string,
        public currentUserRole: TeamRole,
        public timezone: string,
    ) {}
}

export enum TeamRole {
    Owner = 'OWNER',
    Admin = 'ADMIN',
    Member = 'MEMBER'
}
