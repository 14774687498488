import { TeamMemberHostProfileShort } from '../models';

export function convertHostProfileShortApiModelToStateModel(api: any): TeamMemberHostProfileShort {
  return {
    avatar: api.avatar ?? null,
    email: api.email ?? null,
    id: api.id ?? null,
    fullName: api.name ?? null,
    phone: api.phone ?? null,
    slug: api.slug ?? null,
    timezone: api.timezone_str ?? null,
  };
}
