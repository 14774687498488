import { Mapper, UserMetadataApiModel, UserMetadataStateModel } from '../models';

export class UserMetadataModelMapper implements Mapper<UserMetadataApiModel, UserMetadataStateModel> {
    public mapFrom(raw: UserMetadataApiModel): UserMetadataStateModel {
        return {
            emailVerified: raw?.email_verified ?? false,
            onboardingFinished: raw?.onboarding_finished ?? false,
            crispProfileId: raw?.crisp_profile_id ?? null,
            tgHostEnabled: raw?.tg_host_enabled ?? false,
            tgLink: raw?.tg_link ?? null,
            tgUsername: raw?.tg_username ?? null
        };
    }

    public mapTo(model: UserMetadataStateModel): UserMetadataApiModel {
        return {
            email_verified: model.emailVerified,
            onboarding_finished: model.onboardingFinished,
            crisp_profile_id: model.crispProfileId,
            tg_host_enabled: model.tgHostEnabled,
            tg_link: model.tgLink,
            tg_username: model.tgUsername
        };
    }
}
