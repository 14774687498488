import { Injectable, NgZone, } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, Observer, BehaviorSubject } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';

import { DeleteGoogleIntegrationGQL } from 'libs/common/util/src/lib/models/types-generated';
import {
    EnvConfig,
    GoogleAuthError,
    LOGIN_PAGE_GOOGLE_MSG_AUTHORIZATION_NOT_FINISHED,
    LOGIN_PAGE_GOOGLE_MSG_UNKNOWN_ERROR
} from '@common/util';

declare global {
    // @ts-ignore
    const googleAcc: typeof import('google.accounts');
}

import CredentialResponse = google.accounts.id.CredentialResponse;
import CodeClientConfig = google.accounts.oauth2.CodeClientConfig;
import CodeResponse = google.accounts.oauth2.CodeResponse;
import ClientConfigError = google.accounts.oauth2.ClientConfigError;

interface GoogleAuthAnswer {
    ok: true;
    state: string;
}

@Injectable({ providedIn: 'root' })
export class GoogleService {
    public readonly isInitialized$: Observable<boolean>;
    public readonly error$: Observable<string | null>;

    private readonly isInitializedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly errorSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private readonly http: HttpClient,
        private readonly deleteGQL: DeleteGoogleIntegrationGQL,
        private readonly ngZone: NgZone,
        private readonly router: Router,
    ) {
        this.isInitialized$ = this.isInitializedSource.asObservable();
        this.error$ = this.errorSource.asObservable();
    }

    public GrandCalendarAccessGsi(): Observable<CodeResponse> {
        return new Observable((observer: Observer<CodeResponse>): void => {
            const config: CodeClientConfig = {
                client_id: EnvConfig.googleApiId,
                callback: (response: CodeResponse): void => {
                    if (response) {
                        this.GrandCalendar(response.code).pipe(
                            take(1),
                            catchError((error: HttpErrorResponse) => {
                                this.errorSource.next(LOGIN_PAGE_GOOGLE_MSG_UNKNOWN_ERROR);
                                return of(error);
                            })
                        ).subscribe((result: GoogleAuthAnswer | GoogleAuthError): void => {
                            if (result && result.ok) {
                                observer.next(response);
                                observer.complete();
                            } else {
                                observer.error(null);
                            }
                        });
                    } else {
                        observer.error(null);
                    }
                },
                scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                error_callback: (response: ClientConfigError): void => observer.error(response)
            };

            google.accounts.oauth2.initCodeClient(config).requestCode();
        });
    }

    public initializeGsi(): void {
        google.accounts.id.initialize({
            client_id: EnvConfig.googleApiId,
            callback: this.handleCredentialResponse.bind(this),
            auto_select: false,
            cancel_on_tap_outside: true
        });

        this.setInitialized(true);
    }

    public Delete(): Observable<boolean> {
        return this.deleteGQL.mutate().pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    private GrandCalendar(code: string): Observable<GoogleAuthAnswer | GoogleAuthError> {
        return this.http.post<GoogleAuthAnswer | GoogleAuthError>('integration/google/grant', { code });
    }

    private LinkWithGoogle(idToken: string): Observable<GoogleAuthAnswer> {
        return this.http.post<GoogleAuthAnswer | GoogleAuthError>('integration/google/login', { id_token: idToken }).pipe(
            map((answer: GoogleAuthAnswer | GoogleAuthError) => {
                if (answer.ok) {
                    return answer;
                }

                throw answer;
            })
        );
    }

    private setInitialized(value: boolean): void {
        this.isInitializedSource.next(value);
    }

    private handleCredentialResponse(response: CredentialResponse): void {
        this.LinkWithGoogle(response.credential).pipe(
            take(1),
            catchError((error: GoogleAuthError) => {
                this.parseError(error);
                return of(null);
            })
        ).subscribe((): void => {
            this.ngZone.run((): void => {
                void this.router.navigate(['admin']);
            });
        });
    }

    private parseError(error: GoogleAuthError | { error: string } | HttpErrorResponse): void {
        if (error instanceof HttpErrorResponse) {
            this.errorSource.next(error.message);
        } else if ('ok' in error && error.ok === false){
            this.errorSource.next(`${LOGIN_PAGE_GOOGLE_MSG_UNKNOWN_ERROR}${error.code}`);
        } else if ('error' in error) {
            switch (error.error) {
                case 'popup_closed_by_user':
                    this.errorSource.next(LOGIN_PAGE_GOOGLE_MSG_AUTHORIZATION_NOT_FINISHED);
                    break;

                default:
                    this.errorSource.next(error.error);
                    break;
            }
        }
    }
}
