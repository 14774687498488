import { Injectable } from '@angular/core';

import mixpanel from 'mixpanel-browser';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class MixpanelService {
    public init(userToken: string): void {
        mixpanel.init('8b00f974e72580beaddfe79de8fb69b4');
        mixpanel.identify(userToken);
    }

    public setPeople(user: User): void {
        mixpanel.people.set({
            $email: user.email,
            $nickname: user.FullName
        });
    }

    public track(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }
}
