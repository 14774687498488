import { Routes } from '@angular/router';
import { AdminPlatformGuard, WidgetExistGuard, ZoomConfirmGuard } from '@qw-admin/core';
import { AdminShellComponent, NotFoundComponent } from '@common/components';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('libs/qw-landing/landing/feature/src/lib/qw-landing-feature.module').then(
            (module: typeof import('libs/qw-landing/landing/feature/src/lib/qw-landing-feature.module')) => module.QwLandingFeatureModule
        ),
    },
    {
        path: 'widget',
        loadChildren: () => import('libs/qw-widget/widget/feature/src/lib/qw-widget-feature.module').then(
            (module: typeof import('libs/qw-widget/widget/feature/src/lib/qw-widget-feature.module')) => module.QwWidgetFeatureModule
        )
    },
    {
        path: 'admin',
        loadChildren: () => import('@qw-admin/admin/feature-shell').then(
            (module: typeof import('@qw-admin/admin/feature-shell')) => module.QwAdminFeatureShellModule
        ),
        canLoad: [AdminPlatformGuard]
    },
    {
        path: 'shell',
        component: AdminShellComponent,
        canActivate: [AdminPlatformGuard]
    },
    {
        path: 'confirm_integration/zoom',
        component: NotFoundComponent,
        canActivate: [ZoomConfirmGuard]
    },
    {
        path: '**',
        component: NotFoundComponent,
        canActivate: [WidgetExistGuard]
    },
];
