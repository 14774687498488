import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { FeaturePartialState } from './feature.state';

@Injectable()
export class FeatureFacade {

    // ================================
    // Selectors
    // ================================

    constructor(private readonly store: Store<FeaturePartialState>) {}

    // ================================
    // Actions
    // ================================

}
