import { getFeatureActionTypeCreator } from './get-feature-action-type-creator';

const actionTypeNameCache: Set<string> = new Set<string>();

function actionTypeNameCreator(featureName: string, partialFeatureName: string, actionName: string): string {
    return `[${featureName}][${partialFeatureName}] ${actionName}`;
}

export const actionTypeCreator: (
    featureName: string
) => (actionName: string) => (actionDesc: string) => string = getFeatureActionTypeCreator(actionTypeNameCache, actionTypeNameCreator);
