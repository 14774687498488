import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetGoogleIntegrationGQL, SetGoogleEventCalendarGQL, SetGoogleBlockerCalendarGQL } from 'libs/common/util/src/lib/models/types-generated';
import { GoogleCalendarRepository, CalendarIntegrationMapper, CalendarMapper, ICalendarIntegration, ICalendar } from '@common/util';


@Injectable()
export class GoogleCalendarRepositoryGraphQLRepository extends GoogleCalendarRepository {
    private integrationMapper: CalendarIntegrationMapper = new CalendarIntegrationMapper();
    private calendarMapper: CalendarMapper = new CalendarMapper();

    constructor(
        private readonly getGQL: GetGoogleIntegrationGQL,
        private readonly setDefalutGQL: SetGoogleEventCalendarGQL,
        private readonly setBLockersGQL: SetGoogleBlockerCalendarGQL,
    ) {
        super();
    }

    public getIntegration(profile: string): Observable<ICalendarIntegration> {
        return this.getGQL.fetch({ profile }).pipe(
            map(answer => answer.data),
            map(result => {
                if (result.integration.google) {
                    return {
                        calendars: result.hostProfile.googleCalendars,
                        ...result.integration.google,
                        profile
                    };
                } else {
                    return null;
                }
            }),
            map(data => data ? this.integrationMapper.mapFrom(data) : null)
        );
    }

    public setEventCalendar(profile: string, calendarId: number): Observable<ICalendar[]> {
        return this.setDefalutGQL.mutate({ profile, calendarId }).pipe(
            // @ts-ignore
            map(answer => answer.data.updateHostProfile.result),
            map(setResult => {
                if (setResult.__typename === 'ProfileUpdateSuccess') {
                    return setResult.hostProfile.googleCalendars.map(this.calendarMapper.mapFrom);
                }
                throw new Error(setResult.description);
            })
        );
    }

    public setBlockCalendars(profile: string, blockedCalendars: Array<ICalendar>): Observable<ICalendar[]> {
        const googleBloks = blockedCalendars.map(calendar => ({ id: calendar.id, includeBusy: calendar.includeBusy }));
        return this.setBLockersGQL.mutate({ profile, googleBloks }).pipe(
            // @ts-ignore
            map(answer => answer.data.updateHostProfile.result),
            map(result => {
                if (result.__typename === 'ProfileUpdateSuccess') {
                    return result.hostProfile.googleCalendars.map(this.calendarMapper.mapFrom);
                }
                throw new Error(result.description);
            })
        );
    }
}
