import { IPaymentPlansResponse } from '../models';

export function convertPaymentPlansApiModelToStateModel(api: any): IPaymentPlansResponse {
    return {
        plans: api.plans.map((plan: any) => ({
            id: plan.id,
            name: plan.name,
            activityTypeLimit: plan.activity_type_limit,
            currency: plan.currency,
            options: plan.options
                ? plan.options.map((option: any) => ({
                    id: option.id,
                    duration: option.duration,
                    price: option.price,
                    description: option.description
                }))
                : [],
        })),
        activeOptionId: api.active_option_id
    };
}
