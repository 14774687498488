import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
    ApiModel,
    UserRepository,
    Schedule,
    UserMetadataApiModel,
    User,
    ApiService,
    LocationLinkSettingsApiModel
} from '@common/util';
import { UserData } from 'libs/common/util/src/lib/models/types-generated';

/**
 * Implementation for "Adapter" pattern;
 * Layer for managing of data-sources:
 * - REST http-calls;
 * - gRPC calls;
 * - etc;
 */
@Injectable()
export class CommonApiFacade {
    private readonly userInformationUrl: string = 'api/users/profile';
    private readonly paymentUrl: string = 'api/payment';
    private readonly locationsSettingsUrl: string = 'api/link_host_settings';

    constructor(
        private readonly apiService: ApiService,
        private readonly userService: UserRepository,
    ) {}

    public logout(): Observable<HttpResponse<null>> {
        return this.apiService.delete('api/session');
    }

    public getUser(): Observable<User> {
        return this.userService.getUser();
    }

    public getUserMetadata(): Observable<UserMetadataApiModel> {
        return this.apiService.get<UserMetadataApiModel>(`${this.userInformationUrl}`);
    }

    public updateUser(data: UserData): Observable<User> {
        return this.userService.updateUser(data);
    }

    public loadHost(hostSlug: string): Observable<HttpResponse<any>> {
        return this.apiService.get(`api/schedule/${hostSlug}`);
    }

    public loadSchedule(hostSlug: string, activitySlug: string, year: number, month: number, params: object): Observable<{ days: Schedule }> {
        return this.apiService.get(`api/schedule/${hostSlug}/${activitySlug}/${year}/${month}`, params);
    }

    // ================================
    // Root Registration
    // ================================

    public registerNewAccount(params: object = {}): Observable<HttpResponse<ApiModel>> {
        return this.apiService.post('api/register', params);
    }

    public confirmRegistration(params: object = {}): Observable<HttpResponse<any>> {
        return this.apiService.post('api/register/continue', params);
    }

    public repeatConfirmation(): Observable<HttpResponse<null>> {
        return this.apiService.post('api/users/resend-welcome-email', {});
    }

    // ================================
    // Onboarding
    // ================================

    public finishOnboarding(data: object = {}): Observable<HttpResponse<null>> {
        return this.apiService.post(`${this.userInformationUrl}`, data);
    }

    // ================================
    // Team
    // ================================

    public getTeamMembers(teamSlug: string, activitySlug: string): Observable<HttpResponse<any>> {
        return this.apiService.get(`api/get-team-members/${teamSlug}/${activitySlug}`);
    }

    // ================================
    // Stripe
    // ================================

    public getStripeToken(): Observable<HttpResponse<any>> {
        return this.apiService.get(`${this.paymentUrl}/stripe_config`);
    }

    public createStripeSession(hostProfileId: string, paymentOptionId: number): Observable<HttpResponse<any>> {
        return this.apiService.post(`${this.paymentUrl}/stripe_session/${hostProfileId}/${paymentOptionId}`, {});
    }

    public getPaymentPlans(): Observable<HttpResponse<any>> {
        return this.apiService.get(`${this.paymentUrl}/plans`);
    }

    // ================================
    // Locations Integration
    // ================================

    public getLocationsLinkSettings(hostId: string): Observable<any> {
        return this.apiService.get(`${this.locationsSettingsUrl}/${hostId}`);
    }

    public saveLocationLinkSettings(model: LocationLinkSettingsApiModel): Observable<LocationLinkSettingsApiModel> {
        return this.apiService.post(`${this.locationsSettingsUrl}/${model.host_profile.toString()}`, model);
    }

    public updateLocationLinkSettings(model: LocationLinkSettingsApiModel, settingsId: string): Observable<LocationLinkSettingsApiModel> {
        return this.apiService.post(`${this.locationsSettingsUrl}/${model.host_profile.toString()}/${settingsId}`, model);
    }
}
