import { Injectable } from '@angular/core';
import { COMMON_ERROR_MSG, errorsMap } from '../constants';

@Injectable({ providedIn: 'root' })
export class MessageManagerService {
    public showMessage(key: string): string {
        return Boolean(this.getMessageText(key)) ? this.getMessageText(key)! : COMMON_ERROR_MSG;
    }

    private getMessageText(key: string): string | null | undefined {
        return errorsMap.has(key) ? errorsMap.get(key)?.msg : null;
    }
}
