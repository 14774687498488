import { HostAnswer, ActivityTypeApiModel, ActivityTypeStateModel } from '../models';
import { convertActivityTypeApiModelToStateModel } from './activity-type-model.converter';

export function convertHostAnswerApiModelToStateModel(api: any): HostAnswer {
    return {
        ok: api.ok  ?? null,
        hostProfile: {
            id: api.hostProfile.id ?? null,
            timezone: api.hostProfile.timezone ?? null,
            phone: api.hostProfile.phone ?? null,
            jobTitle: api.hostProfile.jobTitle ?? null,
            description: api.hostProfile.description ?? null,
            avatar: api.hostProfile.avatar ?? null,
            slug: api.hostProfile.slug ?? null,
            email: null,
            fullName: api.hostProfile.fullName ?? null,
            trackingId: api.hostProfile.google_analytics_id ?? null,
            paymentData: {
                // tslint:disable-next-line: no-string-literal
                cloudPaymentId: api.hostProfile['cloudpayments_public_id']
            }
        },
        activityTypes: api.activityTypes.map((raw: ActivityTypeApiModel): ActivityTypeStateModel => {
            return convertActivityTypeApiModelToStateModel(raw);
        })
    };
}
