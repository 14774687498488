import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GravatarModule } from 'ngx-gravatar';

import { EventInfoComponent } from './event-info/event-info.component';
import { AvatarPipe, CommonUtilModule, DurationPipe } from '@common/util';

@NgModule({
    declarations: [EventInfoComponent],
    imports: [
        CommonModule,
        CommonUtilModule,
        GravatarModule,
        AvatarPipe,
        DurationPipe,
    ],
    exports: [EventInfoComponent]
})
export class CommonComponentsModule {}
