export enum TARIFF_PLAN_NAME_ENUM {
    PRO = 'pro',
    STANDARD = 'standart',
    DEFAULT = 'default'
}

export type TariffPlanName =
    | TARIFF_PLAN_NAME_ENUM.PRO
    | TARIFF_PLAN_NAME_ENUM.STANDARD
    | TARIFF_PLAN_NAME_ENUM.DEFAULT;
