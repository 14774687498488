import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Observable, Observer } from 'rxjs';
import { ScriptModel } from '../models';
import { ScriptNameType } from '../enums';
import { ScriptStore } from '../constants';

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
    private scripts: ScriptModel[] = ScriptStore;

    constructor(@Inject(DOCUMENT) private readonly documentRef: Document) {}

    public load(scriptName: ScriptNameType): Observable<ScriptModel> {
        if (this.documentRef) {
            return new Observable<ScriptModel>((observer: Observer<ScriptModel>): void => {
                const script: ScriptModel | undefined = this.scripts.find((s: ScriptModel): boolean => s.name === scriptName);

                /** Complete if already loaded */
                if (script && script.loaded) {
                    observer.next(script);
                    observer.complete();
                } else {
                    /** Load the script */
                    const scriptElement: HTMLScriptElement = this.documentRef.createElement('script');
                    scriptElement.type = 'text/javascript';
                    scriptElement.src = script.src;
                    scriptElement.async = true;

                    if (script && script.text) {
                        scriptElement.text = script.text;
                    }

                    scriptElement.onload = (): void => {
                        script.loaded = true;
                        observer.next(script);
                        observer.complete();
                    };

                    scriptElement.onerror = (): void => {
                        observer.error('Couldn\'t load script ' + script.src);
                    };

                    this.documentRef.getElementsByTagName('body')[0].appendChild(scriptElement);
                }
            });
        }
    }
}
