import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { GoogleCalendarRepository, HostRepository, IntegrationRepository, TeamRepository, UserRepository } from '@common/util';

import { CopyToClipboardDirective, ExtraHeaderDirective } from './directives';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { AdminPlatformGuard, AuthGuard, RegPlatformGuard, WidgetExistGuard, ZoomConfirmGuard } from './guards';

import { GoogleCalendarRepositoryGraphQLRepository } from './data/calendars/google-calendar/google-calendar.gql.service';
import { IntegrationsGraphQLRepository } from './data/integration/integration.gql.service';
import { HostGraphQLRepository } from './data/host/host.gql.service';
import { UserGraphQLRepository } from './data/user/user.gql.service';
import { TeamGraphQLRepository } from './data/team/team.gql.service';
import { CommonDataAccessModule } from '@common/data-access';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,

        CommonDataAccessModule,
        MatSnackBarModule, // needed for the snackbar service

        // standalone directives
        CopyToClipboardDirective,
        ExtraHeaderDirective,
    ],
    providers: [
        AuthGuard,
        AdminPlatformGuard,
        RegPlatformGuard,
        WidgetExistGuard,
        ZoomConfirmGuard,
        CurrencyPipe
    ],
})
export class QwAdminCoreUtilModule {
    constructor(@Optional() @SkipSelf() parentModule: QwAdminCoreUtilModule) {
        if (parentModule) {
            throw new Error('QwAdminCoreUtilModule is already loaded. Import it in the AppModule only');
        }
    }

    public static forRoot(): ModuleWithProviders<QwAdminCoreUtilModule> {
        return {
            ngModule: QwAdminCoreUtilModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HeadersInterceptor,
                    multi: true
                },
                {
                    provide: HostRepository,
                    useClass: HostGraphQLRepository
                },
                {
                    provide: IntegrationRepository,
                    useClass: IntegrationsGraphQLRepository
                },
                {
                    provide: UserRepository,
                    useClass: UserGraphQLRepository
                },
                {
                    provide: GoogleCalendarRepository,
                    useClass: GoogleCalendarRepositoryGraphQLRepository
                },
                {
                    provide: TeamRepository,
                    useClass: TeamGraphQLRepository
                }
            ]
        };
    }
}
