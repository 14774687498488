import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { OrderPaymentData, PAYMENT_SERVICE_DATA, PaymentService, TariffPaymentData } from './payment.service';
import { PaymentInfo } from '../models';

interface CloudPaymentsParams {
    publicId: string;
    description: string;
    amount: number;
    currency: 'RUB' | 'USD' | 'EUR';
    invoiceId?: string;
    requireEmail?: boolean;
    accountId: string;
    skin: 'mini';
    data: {
        token: string;
        [key: string]: any;
    };
}

interface CloudPayments {
    charge(params: CloudPaymentsParams, success: (options: any) => void, failure: (reason: any, options: any) => void ): any;
}

// tslint:disable-next-line: class-name
declare class cp {
    public static CloudPayments: new () => CloudPayments;
}

const skin: 'mini' = 'mini' as const;
const requireEmail: boolean = true;

const GetTokenQuery: string = `
mutation GetTokenQuery($option: Int!){
    createPaymentRequest(option: $option){
        result {
            ... on CreatePaymentRequestSuccess {
                amount
                token
            }
        }
    }
}`;

@Injectable()
export class CloudPaymentsService extends PaymentService<TariffPaymentData, OrderPaymentData> {
    constructor(
        private readonly http: HttpClient,
        @Inject(PAYMENT_SERVICE_DATA) private readonly data: { publicId: string }
    ) {
        super();
    }

    public TariffPayment(params: TariffPaymentData): Observable<any> {
        return this.GetPaymentToken(params).pipe(
            switchMap((paymentInfo: PaymentInfo) => this.CloudPaymentWorkflow(params, paymentInfo))
        );
    }

    public OrderPayment(params: OrderPaymentData): Observable<any> {
        const widget: CloudPayments = new cp.CloudPayments();

        const widgetParams: CloudPaymentsParams = {
            skin,
            requireEmail,
            publicId: params.hostPaymentId,
            currency: params.currency,
            description: params.description,
            amount: params.amount,
            accountId: params.email,
            data: {
                token: params.token
            }
        };

        return new Observable((subscriber: Subscriber<any>): void => {
            widget.charge(
                widgetParams,
                (options: any): void => {
                    subscriber.next(options);
                    subscriber.complete();
                },
                (reason: any, options: any): void => {
                    subscriber.error({ reason, options });
                }
            );
        });
    }

    private GraphQL<TAnswer = any>(query: string, variables?: object): Observable<TAnswer> {
        return this.http.post('api/graphql', { query, variables }, {}).pipe(
            map((GQLAnswer: {data: TAnswer, errors: Array<any>}) => {
                if (GQLAnswer.errors instanceof Array) {
                    throw GQLAnswer.errors;
                }

                return GQLAnswer.data;
            })
        );
    }

    private GetPaymentToken(params: TariffPaymentData): Observable<PaymentInfo> {
        return this.GraphQL(GetTokenQuery, { option: params.option }).pipe(
            map(answer => {
                const result = answer.createPaymentRequest?.result;
                if (result) {
                    return result;
                }
                throw new Error('paymentNotAllowed');
            })
        );
    }

    private CloudPaymentWorkflow(payload: TariffPaymentData, paymentInfo: PaymentInfo): Observable<any> {
        const widget: CloudPayments = new cp.CloudPayments();

        const params: CloudPaymentsParams = {
            skin,
            publicId: this.data.publicId,
            currency: payload.currency,
            description: payload.description,
            amount: payload.amount,
            accountId: payload.hostProfileId,
            data: {
                token: paymentInfo.token,
                option: payload.option
            },
        };

        if (payload.durationInMonth && payload.durationInMonth === 1) {
            params.data.CloudPayments = {
                recurrent: {
                    interval: 'Month',
                    period: 1
                }
            };
        }

        return new Observable((subscriber: Subscriber<any>): void => {
            widget.charge(
                params,
                (options: any): void => {
                    subscriber.next(options);
                    subscriber.complete();
                },
                (reason: any, options: any): void => {
                    subscriber.error({ reason, options });
                }
            );
        });
    }
}
