import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';

import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { getCurrentLanguage, numericDecline, numericDeclineLocalized, serviceDuration } from '../helpers';
import { svgIcons, svgIconType } from '../constants';
import { CURRENCY_ENUM } from '../enums';
import { AllowedLanguages } from '../models';

@Pipe({
    name: 'duration',
    standalone: true
})
export class DurationPipe implements PipeTransform {
    public transform(duration: number): string {
        return serviceDuration(duration);
    }
}

@Pipe({
    name: 'numericDecline',
    standalone: true
})
export class NumericDeclinePipe implements PipeTransform {
    public transform(count: number, words: string[]): string {
        return `${count} ${numericDecline(count, words)}`;
    }
}

@Pipe({
    name: 'numericDeclineLocalized',
    standalone: true
})
export class NumericDeclineLocalizedPipe implements PipeTransform {
    public transform(count: number, words: string[]): string {
        const wordsRU: string[] = words.slice(0, 3);
        const wordsEN: string[] = words.slice(3);

        return `${count} ${numericDeclineLocalized(count, wordsRU, wordsEN)}`;
    }
}

@Pipe({
    name: 'fullname',
    standalone: true
})
export class FullNamePipe implements PipeTransform {
    public transform(user: {firstName: string, lastName: string}): string {
        let fullName: string = user.firstName;
        if (user.lastName) {
            fullName += ` ${user.lastName}`;
        }
        return fullName;
    }
}

@Pipe({
    name: 'phone',
    standalone: true
})
export class PhonePipe implements PipeTransform {
    public transform(phone: string): string {
        if (phone) {
            const phoneObj: PhoneNumber | undefined = parsePhoneNumberFromString(phone, 'RU');
            if (phoneObj) {
                return phoneObj.isValid() ? phoneObj.formatInternational() : phone;
            }
        }
        return '–';
    }
}

@Pipe({
    name: 'avatar',
    standalone: true
})
export class AvatarPipe implements PipeTransform {
    constructor(private readonly ds: DomSanitizer) {}

    public transform(avatarSrc: string): SafeUrl {
        const avatar: string = avatarSrc ?? 'https://via.placeholder.com/150';
        return this.ds.bypassSecurityTrustUrl(avatar);
    }
}

@Pipe({
    name: 'svgImage',
    standalone: true
})
export class SvgImagePipe implements PipeTransform {
    constructor(private readonly ds: DomSanitizer) {}

    public transform(icon: svgIconType): SafeHtml {
        const result: string = icon in svgIcons ? svgIcons[icon] : icon;
        return this.ds.bypassSecurityTrustHtml(result);
    }
}

@Pipe({
    name: 'findByKey',
    standalone: true
})
export class FindByKeyPipe implements PipeTransform {
    public transform(key: string, field: string, list: Array<any>): any {
        return list.find((element: any): boolean => element[field] === key);
    }
}

@Pipe({
    name: 'plan_currency',
    standalone: true
})
export class PlanCurrencyPipe implements PipeTransform {
    constructor(private readonly cp: CurrencyPipe) { }

    public transform(price: number, currency?: CURRENCY_ENUM): string | null {
        return this.cp.transform(price, currency, 'symbol-narrow', '1.0-2');
    }
}

@Pipe({
    name: 'instanceof',
    standalone: true
})
export class IntanceOfPipe implements PipeTransform {

    public transform(obj: any, prototype: any): boolean {
        return obj instanceof prototype;
    }
}

@Pipe({
    name: 'localizedDate',
    standalone: true
})
export class LocalizedDatePipe implements PipeTransform {
    public transform(value: any, pattern: string = 'mediumDate'): any {
        const language: AllowedLanguages = getCurrentLanguage();
        const datePipe: DatePipe = new DatePipe(
            language === AllowedLanguages.RU ? 'ru-RU' : 'en-US'
        );
        return datePipe.transform(value, pattern);
    }
}
