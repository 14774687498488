import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtmLabelsModel } from '../models';

@Injectable({ providedIn: 'root' })
export class UtmTagsService {
    // TODO: create enum
    private utmTags: string[] = ['source', 'medium', 'campaign', 'id', 'term', 'content'];

    constructor(private readonly route: ActivatedRoute) {}

    public getUtmTags(): UtmLabelsModel {
        return this.utmTags
            .map(x => [x, this.route.snapshot.queryParamMap.get(`utm_${x}`)])
            .filter(([, value]) => Boolean(value))
            .reduce((acc, [key, value]) => { acc[key] = value; return acc; }, Object.create(null));
    }
}
