import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { fromAppActions } from './app.actions';
import { fromAppQuery } from './app.selectors';
import { FeaturePartialState } from '../feature.state';
import { ModelStatus, UserStateModel, UpdateUserRequestStateModel, UserMetadataStateModel } from '@common/util';

@Injectable()
export class AppFacade {
    public readonly loading$: Observable<boolean> = this.store.pipe(select(fromAppQuery.getLoadingStatus));
    public readonly fetching$: Observable<boolean> = this.store.pipe(select(fromAppQuery.getFetchingStatus));
    public readonly modelStatus$: Observable<ModelStatus> = this.store.pipe(select(fromAppQuery.getModelStatus));
    public readonly errorMessage$: Observable<string | null | undefined> = this.store.pipe(select(fromAppQuery.errorMessage));

    public readonly isEmailVerified$: Observable<boolean> = this.store.pipe(select(fromAppQuery.isEmailVerified));
    public readonly isOnboardingFinished$: Observable<boolean> = this.store.pipe(select(fromAppQuery.isOnboardingFinished));
    public readonly crispProfileId$: Observable<string | null> = this.store.pipe(select(fromAppQuery.getCrispProfileId));

    public readonly isAccountChecked$: Observable<boolean> = this.store.pipe(select(fromAppQuery.isAccountChecked));
    public readonly user$: Observable<UserStateModel | null> = this.store.pipe(select(fromAppQuery.getUser));
    public readonly userMetaData$: Observable<UserMetadataStateModel> = this.store.pipe(select(fromAppQuery.getUserMetaData));

    public readonly isRegistered$: Observable<boolean> = this.store.pipe(select(fromAppQuery.isRegistered));
    public readonly isRegistering$: Observable<boolean> = this.store.pipe(select(fromAppQuery.isRegistering));

    public readonly pending$: Observable<boolean> = this.store.pipe(
        select(fromAppQuery.getModelStatus),
        map((status: ModelStatus) => status === ModelStatus.Pending)
    );

    constructor(private readonly store: Store<FeaturePartialState>) {}

    public reset(): void {
        this.store.dispatch(fromAppActions.Reset());
    }

    public resetError(): void {
        this.store.dispatch(fromAppActions.ResetError());
    }

    public resetIsChecked(): void {
        this.store.dispatch(fromAppActions.ResetIsChecked());
    }

    public loadUser(): void {
        this.store.dispatch(fromAppActions.LoadUser());
    }

    public checkUser(): void {
        this.store.dispatch(fromAppActions.FetchUser());
    }

    public logout(): void {
        this.store.dispatch(fromAppActions.Logout());
    }

    public updateUser(model: UpdateUserRequestStateModel): void {
        this.store.dispatch(fromAppActions.UpdateUser({ model }));
    }

    public repeatConfirmation(): void {
        this.store.dispatch(fromAppActions.RepeatConfirmation());
    }

    public register(email: string): void {
        this.store.dispatch(fromAppActions.Register({ email }));
    }

    public confirmRegistration(token: string): void {
        this.store.dispatch(fromAppActions.ConfirmRegistrationOptimistic({ token }));
    }
}
