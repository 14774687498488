import { Mapper, UpdateUserRequestApiModel, UpdateUserRequestStateModel } from '../models';

export class UpdateUserRequestModelMapper implements Mapper<UpdateUserRequestApiModel, UpdateUserRequestStateModel | null> {
    public mapFrom(raw: UpdateUserRequestApiModel): UpdateUserRequestStateModel | null{
        if (!raw) {
            return null;
        }

        return {
            firstName: raw.firstName ?? null,
            lastName: raw.lastName ?? null,
            password: raw.password ?? null
        };
    }

    public mapTo(model: UpdateUserRequestStateModel): UpdateUserRequestApiModel {
        const requestApiModel: UpdateUserRequestApiModel = {};

        if (model.firstName) {
            requestApiModel.firstName = model.firstName;
        }

        if (model.lastName) {
            requestApiModel.lastName = model.lastName;
        }

        if (model.password) {
            requestApiModel.password = model.password;
        }

        return requestApiModel;
    }
}
