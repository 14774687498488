export enum CALENDAR_INTEGRATION_TYPE_ENUM {
    GOOGLE = 'Google',
    YANDEX = 'Yandex',
    MICROSOFT = 'Microsoft',
    MAIL = 'Mail.ru',
    ICLOUD = 'iCloud',
}

export type CalendarIntegrationType =
    | CALENDAR_INTEGRATION_TYPE_ENUM.YANDEX
    | CALENDAR_INTEGRATION_TYPE_ENUM.GOOGLE
    | CALENDAR_INTEGRATION_TYPE_ENUM.MICROSOFT
    | CALENDAR_INTEGRATION_TYPE_ENUM.MAIL
    | CALENDAR_INTEGRATION_TYPE_ENUM.ICLOUD;
