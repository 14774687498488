import '@angular/localize/init';

// ================================
// Common
// ================================

export const COMMON_SAVE_BUTTON: string = $localize`:@@saveButton:Сохранить`;
export const COMMON_CLOSE_BUTTON: string = $localize`:@@closeButton:Закрыть`;
export const COMMON_READY_BUTTON: string = $localize`:@@readyButton:Готово`;
export const COMMON_PASSWORD_PLACEHOLDER: string = $localize`:@@passwordPlaceholder:Введите пароль`;
export const COMMON_EMAIL_INPUT_TITLE: string = $localize`:@@emailInputTitle:Адрес электронной почты`;
export const COMMON_PASSWORD_INPUT_TITLE: string = $localize`:@@passwordInputTitle:Пароль`;
export const COMMON_ERROR_MSG: string = $localize`:@@commonErrorMessage:Что-то пошло не так...`;
export const SAVED_TITLE: string = $localize`:@@savedTitle:Сохранено`;
export const TIME_MINUTES: string = $localize`:@@timeMinutes:минут`;
export const TIME_HOURS: string = $localize`:@@timeHours:часа`;

// ================================
// Onboarding
// ================================

export const SKIP_STEP_BUTTON: string = $localize`:@@skipStepButton:Настроить позже`;
export const NEXT_STEP_BUTTON: string = $localize`:@@nextStepButton:Ок, дальше`;
export const GO_TO_SETTINGS_BUTTON: string = $localize`:@@settingsButton:Перейти в настройки`;
export const FIRST_STEP_TITLE: string = $localize`:@@firstStepTitle:Добро пожаловать в Qlick!`;
export const JOB_CHOOSE_TITLE: string = $localize`:@@jobChooseTitle:Расскажите, как вы планируете использовать сервис`;
export const FIRST_STEP_DESCR: string = $localize`:@@firstStepDescr:Для начала работы осталось сделать несколько простых шагов. Это займет пару минут.`;
export const JOB_CHOOSE_DESCR: string = $localize`:@@jobChooseDescr:В зависимости от этого мы подходящим образом его настроим`;
export const JOB_CHOOSE_FIRST: string = $localize`:@@jobChooseFirst:Для планирования личных и рабочих встреч`;
export const JOB_CHOOSE_FIRST_DESCR: string = $localize`:@@jobChooseFirstDescr:Буду использовать Qlick для планирования своих встреч с гостями один-на-один`;
export const JOB_CHOOSE_SECOND: string = $localize`:@@jobChooseSecond:Для планирования ресурса студии, переговорки или чего-то такого`;
export const JOB_CHOOSE_SECOND_DESCR: string = $localize`:@@jobChooseSecondDescr:Клиенты будут записываться`;
export const JOB_CHOOSE_THIRD: string = $localize`:@@jobChooseThird:Для планирования групповых встреч внутри команды с внешними участниками`;
export const JOB_CHOOSE_THIRD_DESCR: string = $localize`:@@jobChooseThirdDescr:Буду использовать Qlick для планирования больших групповых встреч, когда надо собрать несколько участников`;
export const CALENDARS_STEP_TITLE: string = $localize`:@@calendarsStepTitle:Следующий шаг — календари`;
export const CALENDARS_CONNECT_TITLE: string = $localize`:@@calendarsConnectTitle:Введите логин и пароль приложения`;
export const LOCATIONS_STEP_TITLE: string = $localize`:@@locationsStepTitle:Теперь что-нибудь для конференций`;
export const ZOOM_CONNECT_TITLE: string = $localize`:@@zoomConnectTitle:Для встреч будет использоваться Zoom`;
export const LOCATIONS_STEP_DESCR: string = $localize`:@@locationsStepDescr:Когда кто-то записывается к вам через Qlick, мы умеем автоматически создавать конференцию. Выберите сервис, который хотите подключить первым:`;
export const ZOOM_CONNECT_DESCR: string = $localize`:@@zoomConnectDescr:Для встреч будет использоваться Zoom`;
export const WEEKDAY_MONDAY: string = $localize`:@@weekDayMonday:Понедельник`;
export const WEEKDAY_TUESDAY: string = $localize`:@@weekDayTuesday:Вторник`;
export const WEEKDAY_WEDNESDAY: string = $localize`:@@weekDayWednesday:Среда`;
export const WEEKDAY_THURSDAY: string = $localize`:@@weekDayThursday:Четверг`;
export const WEEKDAY_FRIDAY: string = $localize`:@@weekDayFriday:Пятница`;
export const WEEKDAY_SATURDAY: string = $localize`:@@weekDaySaturday:Суббота`;
export const WEEKDAY_SUNDAY: string = $localize`:@@weekDaySunday:Воскресенье`;
export const WEEKDAYS: string[] = [
    WEEKDAY_MONDAY,
    WEEKDAY_TUESDAY,
    WEEKDAY_WEDNESDAY,
    WEEKDAY_THURSDAY,
    WEEKDAY_FRIDAY,
    WEEKDAY_SATURDAY,
    WEEKDAY_SUNDAY
];

// ================================
// Aside schedule entries
// ================================

export const BUTTON_TITLE_FOR_ALL: string = $localize`:@@applyToEntireWeekButton:Сохранить для всех `;
export const BUTTON_TITLE_FOR_CURRENT_DAY: string = $localize`:@@applyToCurrentDayButton:Сохранить только на `;
export const BUTTON_TITLE_FOR_REST: string = $localize`:@@applyToRestDaysButton:Сохранить для всех следующих дней`;

// ================================
// Events page
// ================================

export const EVENTS_PAGE_TITLE: string = $localize`:@@eventsPageHeaderTitle:Все события`;
export const SEARCH_PLACEHOLDER: string = $localize`:@@eventsListSearchPlaceholder:Поиск по имени и имэйлу гостя`;

export const ADD_FILTER_BUTTON_TITLE: string = $localize`:@@addFilterButtonTitle:Добавить фильтр`;
export const TEAMMATE_FILTER_DIVIDER: string = $localize`:@@teammateFilterDivider:и ещё`;
export const EVENT_DATE_RANGE_FILTER_TITLE: string = $localize`:@@eventDateRangeFilterTitle:Дата события:`;
export const CREATE_DATE_RANGE_FILTER_TITLE: string = $localize`:@@createDateRangeFilterTitle:Дата создания:`;
export const TEAMMATE_FILTER_TITLE: string = $localize`:@@teammateFilterTitle:Сотрудник:`;
export const TEAMMATE_FILTER_SEARCH_PLACEHOLDER: string = $localize`:@@teammateFilterSearchPlaceholder:Найти сотрудника`;

export const EVENT_RESCHEDULE_ACTION: string = $localize`:@@eventRescheduleAction:Перенести`;
export const EVENT_CANCEL_ACTION: string = $localize`:@@eventCancelAction:Отменить`;

export const EVENT_OVAL_BUTTON_PAST: string = $localize`:@@eventOvalButtonPast:Предстоящие`;
export const EVENT_OVAL_BUTTON_FUTURE: string = $localize`:@@eventOvalButtonFuture:Прошедшие`;
export const EVENT_NEXT_PERIOD_INFO: string = $localize`:@@eventNextPeriodInfo:на следующие 30 дней`;

// ================================
// Formats page
// ================================

export const FORMATS_PAGE_HEADER: string = $localize`:@@formatsPageHeader:Форматы встреч`;

export const NEW_FORMAT_TITLE: string = $localize`:@@newFormatTitle:Новый формат`;
export const ADD_FORMAT_TITLE: string = $localize`:@@addFormatTitle:Добавить формат`;
export const PUBLISH_FORMAT_TITLE: string = $localize`:@@publishFormatTitle:Опубликовать`;

export const FORMAT_CARD_ACTIONS_HIDE: string = $localize`:@@formatCardActionsHide:Скрыть`;
export const FORMAT_CARD_ACTIONS_SHOW: string = $localize`:@@formatCardActionsShow:Отобразить`;
export const FORMAT_CARD_ACTIONS_EDIT: string = $localize`:@@formatCardActionsEdit:Редактировать`;
export const FORMAT_CARD_ACTIONS_DELETE: string = $localize`:@@formatCardActionsDelete:Удалить`;

export const FORMAT_TYPE_ONE_BY_ONE: string = $localize`:@@formatTypeOneByOne:один на один`;
export const FORMAT_TYPE_GROUP: string = $localize`:@@formatTypeGroup:групповое занятие`;

export const FORMAT_FORM_EVENT_TYPE_GROUP: string = $localize`:@@formatFormEventTypeGroup:Групповая запись`;
export const FORMAT_FORM_EVENT_TYPE_UNLIMITED: string = $localize`:@@formatFormEventTypeUnlimited:Сколько угодно участников`;
export const FORMAT_FORM_EVENT_TYPE_ONE_BY_ONE: string = $localize`:@@formatFormEventTypeOneByOne:Один на один`;

export const EVENT_PAYMENT_GROUP_MEETING: string = $localize`:@@formatPaymentGroupMeeting:Групповые события доступны в тарифах Премиум и Про`;
export const EVENT_PAYMENT_CUSTOM_MESSAGE: string = $localize`:@@formatPaymentCustomMessage:Стандартная страница с настраиваемым сообщением доступна в тарифах Премиум и Про`;
export const EVENT_PAYMENT_REDIRECT: string = $localize`:@@formatPaymentRedirect:Переадресация доступна в тарифе Про`;
export const EVENT_PAYMENT_FOR_FEE: string = $localize`:@@formatPaymentForFee:Платное участие доступно только в тарифе Про`;
export const EVENT_PAYMENT_INTEGRATION: string = $localize`:@@formatPaymentIntegration:Интеграции с сервисами оплаты доступны только в тарифе Про`;
export const EVENT_PAYMENT_NOTIFICATION: string = $localize`:@@formatPaymentNotification:Уведомления доступны только в тарифе Про`;

// ================================
// Integrations page
// ================================

export const INTEGRATIONS_PAGE_HEADER: string = $localize`:@@integrationsPageHeader:Интеграции`;

export const INTEGRATIONS_ZOOM_DESCR: string = $localize`:@@integrationsZoomDescr:Автоматически генерирует конференцию в Zoom для каждой записи`;
export const INTEGRATIONS_CP_DESCR: string = $localize`:@@integrationsCPDescr:Прием платежей в онлайне`;

export const INTEGRATION_CARD_ZOOM_ITEM_VIDEO: string = $localize`:@@integrationCardZoomItemVideo:Видео-звонки`;
export const INTEGRATION_CARD_ZOOM_ITEM_MARKETING: string = $localize`:@@integrationCardZoomItemMarketing:Маркетинг`;
export const INTEGRATION_CARD_ZOOM_DESC: string = $localize`:@@integrationCardZoomDescr:Для каждой забронированной встречи (консультации или занятия) будет создана конференция в вашем Zoom.`;

export const INTEGRATION_CARD_CP_ITEM_PAYMENTS: string = $localize`:@@integrationCardCPItemPayments:Приём платежей`;
export const INTEGRATION_CARD_CP_DESC: string = $localize`:@@integrationCardCPDescr:Принимайте платежи банковскими картами, Apple Pay и GPay. Сервис работает с ИП и ООО.`;

export const INTEGRATION_CONTROL_ZOOM_INPUT_TITLE: string = $localize`:@@personalZoomLinkInputTitle:Ссылка на персональный зал`;
export const INTEGRATION_CONTROL_ZOOM_INPUT_DESCR: string = $localize`:@@personalZoomLinkInputDescription:Ссылка будет подставляться автоматически, если интеграция в текущий момент недоступна`;
export const INTEGRATION_CONTROL_ZOOM_CHECKBOX_DESCR: string = $localize`:@@personalZoomLinkCheckboxDescription:Всегда использовать зал персональной конференции`;

export const INTEGRATION_CARD_COMMON_DESC: string = $localize`:@@integrationCardCommonDescr:При бронировании встречи Qlick автоматически создаст конференцию и отправит ссылку для подключения`;
export const INTEGRATION_COMMON_DESC: string = $localize`:@@integrationCardCommonDescr:Конференция для событий из Qlick`;
export const INTEGRATION_CONTROL_COMMON_INPUT_TITLE: string = $localize`:@@integrationCommonInputTitle:API-ключ`;

export const INTEGRATION_CONTROL_WEBINAR_INPUT_DESCR: string = $localize`:@@integrationWebinarInputDescr:Как получить этот ключ написано здесь`;

// ================================
// Calendars page
// ================================

export const CALENDARS_PAGE_HEADER: string = $localize`:@@calendarsPageHeader:Ваши календари`;
export const CALENDARS_YANDEX_PAGE_HEADER: string = $localize`:@@calendarsYandexPageHeader:Яндекс.Календарь`;
export const CALENDARS_YANDEX_NOT_CONNECTED_NAME: string = $localize`:@@calendarsYandexNotConnectedName:Яндекс.Почта или Яндекс.Коннект`;
export const CALENDARS_YANDEX_CONNECTED_NAME: string = $localize`:@@calendarsYandexConnectedName:Яндекс`;
export const CALENDARS_MAIL_PAGE_HEADER: string = $localize`:@@calendarsMailPageHeader:Календарь @mail.ru`;
export const CALENDARS_MAIL_NOT_CONNECTED_TITLE: string = $localize`:@@calendarsMailNotConnectedTitle:ВК или @mail.ru`;
export const CALENDARS_ICLOUD_PAGE_HEADER: string = $localize`:@@calendarsICloudPageHeader:Календарь iCloud от Apple`;
export const CALENDARS_ICLOUD_NOT_CONNECTED_TITLE: string = $localize`:@@calendarsICloudNotConnectedTitle:Календарь iCloud`;
export const CALENDARS_EXCHANGE_SERVER_ADDRESS: string = $localize`:@@calendarsExchangeServerAddress:Адрес сервера Exchange`;
export const CALENDARS_DOMAIN: string = $localize`:@@calendarsDomain:Домен`;
export const CALENDARS_USER_NAME: string = $localize`:@@calendarsUserName:Имя пользователя`;
export const CALENDARS_CONNECT_ERROR_MSG: string = $localize`:@@calendarsConnectErrorMsg:Произошла ошибка, напишите в чат поддержки`;
export const CALENDARS_GOOGLE_NOT_CONNECTED_NAME: string = $localize`:@@calendarsGoogleNotConnectedName:Gmail или Google Workspace`;
export const CALENDARS_GOOGLE_WITHOUT_NAME: string = $localize`:@@calendarsGoogleWithoutName:Google [ используется только для входа ]`;

// ================================
// Login page
// ================================

export const LOGIN_PAGE_GOOGLE_MSG_UNKNOWN_ERROR: string = $localize`:@@loginPageGoogleMsgUnknownError:Неизвестная ошибка: `;
export const LOGIN_PAGE_GOOGLE_MSG_AUTHORIZATION_NOT_FINISHED: string = $localize`:@@loginPageGoogleMsgAuthorizationNotFinished:Авторизация не закончена`;
export const REPEAT_EMAIL_CONFIRMATION_SUCCESS_MSG: string = $localize`:@@repeatEmailConfirmationSuccess:Письмо повторно отправлено`;

// ================================
// Payment page
// ================================

export const PAYMENT_PAGE_ALERT_TARIFF_NOT_FOUND: string = $localize`:@@paymentPageAlertTariffNotFound:Тариф не найден`;

export const PAYMENT_PAGE_TITLE_THANKS_FOR_PAYMENT: string = $localize`:@@thanksForThePayment:Спасибо за оплату`;
export const PAYMENT_PAGE_TITLE_SOMETHING_WRONG: string = $localize`:@@somethingWentWrong:Что-то пошло не так`;

// ================================
// Teammates page
// ================================

export const TEAMMATES_PAGE_HEADER: string = $localize`:@@teammatesPageHeader:Команда `;
export const TEAMMATES_PAGE_HEADER_BUTTON_TITLE: string = $localize`:@@teammatesPageHeaderButtonTitle:Добавить участника`;

export const TEAMMATES_PAGE_ASIDE_INPUT_TITLE: string = $localize`:@@usersEmailForInvitation:E-mail пользователя`;
export const TEAMMATES_PAGE_ASIDE_INPUT_DESCR: string = $localize`:@@requirementDescription:Он должен быть зарегистрирован в Qlick`;
export const TEAMMATES_PAGE_ASIDE_BUTTON_TITLE: string = $localize`:@@inviteTeamMemberButton:Пригласить`;

// ================================
// Payment page
// ================================

export const PAYMENT_PAGE_HEADER: string = $localize`:@@paymentPageTitle:Тарифы и оплата`;

// ================================
// Snack bar
// ================================

export const SNACK_BAR_CLOSE: string = $localize`:@@snackBarClosedTitle:Закрыть`;
export const SNACK_BAR_CHANGES_SAVED: string = $localize`:@@snackBarChangesSavedTitle:Изменения сохранены`;

export const SNACK_BAR_CORRECTED: string = $localize`:@@snackBarCorrectedTitle:Расписание отредактировано`;
export const SNACK_BAR_PUBLISHED: string = $localize`:@@snackBarPublishedTitle:Формат встречи опубликован`;
export const SNACK_BAR_FORMAT_SAVED: string = $localize`:@@snackBarFormatSavedTitle:Формат сохранен`;
export const SNACK_BAR_ALREADY_SAVED: string = $localize`:@@snackBarAlreadySavedTitle:Изменения уже сохранены автоматически`;

export const SNACK_BAR_FORMAT_REMOVED_FULL: string = $localize`:@@snackBarFormatHasBeenRemovedFull:Формат был удален`;
export const SNACK_BAR_FORMAT_REMOVED_PART_1: string = $localize`:@@snackBarFormatHasBeenRemovedPart1:Формат`;
export const SNACK_BAR_FORMAT_REMOVED_PART_2: string = $localize`:@@snackBarFormatHasBeenRemovedPart2:был удален`;

export const SNACK_BAR_USER_CHANGED: string = $localize`:@@snackBarUserChanged:Пользователь изменен`;
export const SNACK_BAR_PROFILE_CHANGED: string = $localize`:@@snackBarProfileChanged:Профиль изменен`;
export const SNACK_BAR_SAVING_ERROR: string = $localize`:@@snackBarSavingError:Ошибка сохранения:`;

export const SNACK_BAR_EVENT_CANCEL_SUCCESS: string = $localize`:@@snackBarEventCancelSuccess:Запись успешно отменена`;

// ================================
// Error messages
// ================================

export const ERROR_MSG_ALREADY_AUTHORIZED: string = $localize`:@@errorMessageAlreadyAuthorized:Пользователь уже авторизован`;
export const ERROR_MSG_EMAIL_EXISTS: string = $localize`:@@errorMessageEmailExists:Email уже зарегистрирован`;

// ================================
// Tariff plans
// ================================

export const LOCALIZATION_TARIFF_PRO: string = $localize`:@@tariffPro:Про`;
export const LOCALIZATION_TARIFF_STANDARD: string = $localize`:@@tariffStandard:Премиум`;
export const LOCALIZATION_TARIFF_DEFAULT: string = $localize`:@@tariffDefault:Базовый`;
