import { Mapper } from '../../models';
import { TeamMapper } from './team.mapper';
import { FullHostFragment } from 'libs/common/util/src/lib/models/types-generated';
import { PersonalHostProfile, TeamMemberHostProfile, HostProfile } from '../../models';

export class HostMapper implements Mapper<FullHostFragment, HostProfile> {
    private teamMapper = new TeamMapper();

    mapFrom(raw: FullHostFragment) {
        if (!raw) {
            return null;
        }

        const host: Omit<HostProfile, 'slug'> = {
            id: raw.id,
            jobTitle: raw.jobTitle,
            description: raw.description,
            phone: raw.phone,
            timezone: raw.timezone,
            avatar: raw.avatar,
            email: raw.email,
            fullName: (raw.firstName || raw.lastName) ? `${raw.firstName || ''} ${raw.lastName || ''}` : ''
        };

        if (raw.__typename === 'PersonalHostProfile') {
            return new PersonalHostProfile({
                ...host,
                slug: raw.slug,
                plan: {
                    id: raw.plan.id,
                    name: raw.plan.name,
                    currency: raw.plan.currency,
                    activityTypeLimit: raw.plan.activityTypeLimit,
                },
                option: raw.planOption ? {
                    id: raw.planOption.id,
                    duration: raw.planOption.duration,
                    price: raw.planOption.price
                } : undefined,

                expires: raw.expires ? new Date(raw.expires) : undefined,
                paymentData: {
                    cloudPaymentApiKey: raw.cloudpaymentsApiPassword,
                    cloudPaymentId: raw.cloudpaymentsPublicId
                }
            });
        } else if (raw.__typename === 'TeamMemberHostProfile'){
            return new TeamMemberHostProfile({
                ...host,
                team: this.teamMapper.mapFrom(raw.team),
                teamRole: raw.teamRole,
                calendarsCount: raw.googleCalendars.length
                // calendarsCount: raw.googleCalendars.length + raw.yandexCalendarsCount + raw.microsoftCalendars.length
            });
        }
    }

    mapTo(model: HostProfile): FullHostFragment {
        return null;
    }
}
