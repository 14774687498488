import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'qw-not-found-page',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [
        RouterLink
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {}
