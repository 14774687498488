import { Config, EnvConfig } from '@common/util';
import { environment } from '../environments/environment';

/** Used to set environment const before init app */
export function LoadEnvConfigFactory(): () => Config {
    return () => {
        EnvConfig.baseApiUrl = environment.baseApiUrl;
        EnvConfig.googleApiId = environment.googleApiId;
        EnvConfig.inProduction = environment.production;
        EnvConfig.cloudPaymentsPublicId = environment.cloudPaymentsPublicId;
        EnvConfig.crispWebsiteId = environment.crispWebsiteId;
        EnvConfig.integrationsApiUrl = environment.integrationsApiUrl;
        return EnvConfig;
    };
}
