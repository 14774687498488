import { Mapper, User } from '../../models';
import { FullUserFragment } from 'libs/common/util/src/lib/models/types-generated';

export class UserMapper implements Mapper<FullUserFragment, User> {
    mapFrom(raw: FullUserFragment): User {
        delete raw.__typename;
        return new User(raw);
    }

    mapTo(model: User): FullUserFragment {
        return model;
    }
}
