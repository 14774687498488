import * as moment from 'moment-timezone';
import {
    ERROR_MSG_ALREADY_AUTHORIZED,
    ERROR_MSG_EMAIL_EXISTS,
    INTEGRATION_CARD_COMMON_DESC,
    INTEGRATION_CARD_CP_DESC,
    INTEGRATION_CARD_CP_ITEM_PAYMENTS,
    INTEGRATION_CARD_ZOOM_DESC,
    INTEGRATION_CARD_ZOOM_ITEM_MARKETING,
    INTEGRATION_CARD_ZOOM_ITEM_VIDEO,
    LOCALIZATION_TARIFF_DEFAULT,
    LOCALIZATION_TARIFF_PRO
} from './admin.locale.constants';
import {
    EVENTS_LIST_SORT_COLUMNS,
    EventsListSortColumnType,
    LOCATION_TYPES_ENUM,
    LocationType,
    SCRIPT_NAMES_ENUM,
    CONTACTS_LIST_SORT_COLUMNS,
    ContactsListSortColumnType,
} from '../enums';
import {
    PageModel,
    ScriptModel,
    SortModel,
    MessageModel,
    Config,
    IntegrationCardData,
    LocationTypeCardModel
} from '../models';
import { Moment } from 'moment-timezone';


export const EnvConfig: Config = {
    inProduction: true,
    baseApiUrl: 'https://api.qlick.io/',
    // baseApiUrl: 'https://eu.api.aws.qlick.io/',
    integrationsApiUrl: 'https://integrations.qlick.io',
    googleApiId: '906370258859-816eddfi0sqqogtmlamo92p0u2gm559f.apps.googleusercontent.com',
    cloudPaymentsPublicId: 'pk_95bb531e5bcf4b1c2eb05a738ef19',
    crispWebsiteId: 'ab5aef82-1fec-4dff-8e6c-3a08ca6a8f6d',
};

export const TimezoneNames: { [key: string]: string } = {
    // Ru timezones
    'Asia/Anadyr': 'Азия / Анадырь',
    'Asia/Barnaul': 'Азия / Барнаул',
    'Asia/Chita': 'Азия / Чита',
    'Asia/Irkutsk': 'Азия / Иркутск',
    'Asia/Kamchatka': 'Азия / Камчатка',
    'Asia/Khandyga': 'Азия / Хандыга',
    'Asia/Krasnoyarsk': 'Азия / Красноярск',
    'Asia/Magadan': 'Азия / Магадан',
    'Asia/Novokuznetsk': 'Азия / Новокузнецк',
    'Asia/Novosibirsk': 'Азия / Новосибирск',
    'Asia/Omsk': 'Азия / Омск',
    'Asia/Sakhalin': 'Азия / Сахалин',
    'Asia/Srednekolymsk': 'Азия / Среднеколымск',
    'Asia/Tomsk': 'Азия / Томск',
    'Asia/Ust-Nera': 'Азия / Усть-Нера',
    'Asia/Vladivostok': 'Азия / Владивосток',
    'Asia/Yakutsk': 'Азия / Якутск',
    'Asia/Yekaterinburg': 'Азия / Екатеринбург',
    'Europe/Astrakhan': 'Европа / Астрахань',
    'Europe/Kaliningrad': 'Европа / Калининград',
    'Europe/Kirov': 'Европа / Киров',
    'Europe/Moscow': 'Европа / Москва',
    'Europe/Samara': 'Европа / Самара',
    'Europe/Saratov': 'Европа / Саратов',
    'Europe/Simferopol': 'Европа / Симферополь',
    'Europe/Ulyanovsk': 'Европа / Ульяновск',
    'Europe/Volgograd': 'Европа / Волгоград',

    // Names for additional timezones
    'Pacific/Samoa': 'Тихий океан / Сомоа',
    'US/Hawaii': 'США / Гавайи',
    'US/Alaska': 'США / Аляска',
    'America/Tijuana': 'Америка / Тихуана',
    'US/Mountain': 'США / Горный часовой пояс',
    'US/Arizona': 'США / Аризона',
    'US/Central': 'США / Центральное время',
    'America/Mexico_City': 'Америка / Мехико ',
    'US/Eastern': 'США / Восточное время',
    'America/Bogota': 'Америка / Богота',
    'Canada/Atlantic': 'Канада / Атлантический пояс',
    'Brazil/East': 'Бразилия / Восточное время',
    'Atlantic/Azores': 'Атлантика / Азоры',
    'Atlantic/Cape_Verde': 'Атлантика / Кабо-Верде',
    'Europe/London': 'Европа / Лондон',
    'Europe/Prague': 'Европа / Прага',
    'Europe/Warsaw': 'Европа / Варшава',
    'Europe/Paris': 'Европа / Париж',
    'Europe/Berlin': 'Европа / Берлин',
    'Europe/Bucharest': 'Европа / Бухарест',
    'Europe/Kiev': 'Европа / Киев',
    'Africa/Cairo': 'Африка / Каир',
    'Europe/Helsinki': 'Европа / Хельсинки',
    'Asia/Baghdad': 'Азия / Багдад',
    'Asia/Baku': 'Азия / Баку',
    'Asia/Kabul': 'Азия / Кабул',
    'Asia/Kolkata': 'Азия / Дели',
    'Asia/Kathmandu': 'Азия / Катманду',
    'Asia/Dhaka': 'Азия / Дакка',
    'Asia/Bangkok': 'Азия / Бангкок ',
    'Asia/Hong_Kong': 'Азия / Гонконг',
    'Asia/Singapore': 'Азия / Сингапур',
    'Asia/Seoul': 'Азия / Сеул',
    'Asia/Tokyo': 'Азия / Токио',
    'Australia/Adelaide': 'Австралия / Аделаида',
    'Australia/Sydney': 'Австралия / Сидней',
    'Australia/Brisbane': 'Австралия / Брисбен',
    'Pacific/Fiji': 'Тихий океан / Фиджи',
};

export const additionalTimezones: string[] = [
    'Pacific/Samoa', // (GMT-11:00)
    'US/Hawaii', // (GMT-10:00)
    'US/Alaska', // (GMT-9:00)
    'America/Tijuana', // (GMT-8:00)
    'US/Mountain', // (GMT-7:00)
    'US/Arizona', // (GMT-7:00)
    'US/Central', // (GMT-6:00)
    'America/Mexico_City', // (GMT-6:00)
    'US/Eastern', // (GMT-5:00)
    'America/Bogota', // (GMT-5:00)
    'Canada/Atlantic', // (GMT-4:00)
    'Brazil/East', // (GMT-3:00)
    'Atlantic/Azores', // (GMT-1:00)
    'Atlantic/Cape_Verde', // (GMT-1:00)
    'Europe/London', // (GMT)
    'Europe/Prague', // (GMT+1:00)
    'Europe/Warsaw', // (GMT+1:00)
    'Europe/Paris', // (GMT+1:00)
    'Europe/Berlin', // (GMT+1:00)
    'Europe/Bucharest', // (GMT+2:00)
    'Europe/Kiev', // (GMT+2:00)
    'Africa/Cairo', // (GMT+2:00)
    'Europe/Helsinki', // (GMT+2:00)
    'Asia/Baghdad', // (GMT+3:00)
    'Asia/Baku', // (GMT+4:00)
    'Asia/Kabul', // (GMT+4:30)
    'Asia/Kolkata', // (GMT+5:30)
    'Asia/Kathmandu', // (GMT+5:45)
    'Asia/Dhaka', // (GMT+6:00)
    'Asia/Bangkok', // (GMT+7:00)
    'Asia/Hong_Kong', // (GMT+8:00)
    'Asia/Singapore', // (GMT+8:00)
    'Asia/Seoul', // (GMT+9:00)
    'Asia/Tokyo', // (GMT+9:00)
    'Australia/Adelaide', // (GMT+9:30)
    'Australia/Sydney', // (GMT+10:00)
    'Australia/Brisbane', // (GMT+10:00)
    'Asia/Magadan', // (GMT+11:00)
    'Pacific/Fiji', // (GMT+12:00)
];

export const JobTitleArray: string[] = [
    'Психолог',
    'Юрист',
    'Преподаватель / репетитор',
    'Предприниматель',
    'Фитнес-тренер',
    'Топ-менеджер',
    'Продакт / проджект',
    'HR / рекрутер',
    'Коуч / трекер',
    'Бьюти-мастер',
    'Менеджер по продажам',
    'Руководитель / тимлид',
    'Компания',
    'Другое',
];

// tslint:disable: object-literal-key-quotes
export const JobTitleMap: { [key: string]: string } = {
    'Психолог': 'psychologist',
    'Юрист': 'lawyer',
    'Преподаватель / репетитор': 'tutor',
    'Предприниматель': 'businessman',
    'Фитнес-тренер': 'fitness_trainer',
    'Топ-менеджер': 'top_manager',
    'Продакт / проджект': 'product_manager',
    'HR / рекрутер': 'hr',
    'Коуч / трекер': 'coach',
    'Бьюти-мастер': 'beauty_master',
    'Менеджер по продажам': 'sales_manager',
    'Руководитель / тимлид': 'team_lead',
    'Компания': 'company',
    'Другое': 'other',
};
// tslint:enable: object-literal-key-quotes

export const timezones: Array<{ id: string; moment: Moment; }> = moment.tz
    .zonesForCountry('RU', true)
    .map((z: moment.MomentZoneOffset): { id: string; moment: Moment; } => {
        const timezone: moment.Moment = moment.tz(z.name);

        // Temporary exclude DST
        if (false && timezone.isDST()) {
            timezone.set({ hour: timezone.hour() + 1 });
        }

        return {
            id: z.name,
            moment: timezone
        };
    });

additionalTimezones.forEach((name: string): void => {
    const timezone: moment.Moment = moment.tz(name);

    // Temporary exclude DST
    if (false && timezone.isDST()) {
        timezone.set({ hour: timezone.hour() + 1 });
    }

    timezones.push({
        id: name,
        moment: timezone
    });
});

timezones.sort((a: { id: string; moment: Moment; }, b: { id: string; moment: Moment; }) => {
    return a.moment.utcOffset() - b.moment.utcOffset();
});

export const LocationTypesContextMenu: Array<{
    action: LocationType,
    title: string,
    icon: string
}> = [{
    action: LOCATION_TYPES_ENUM.ZOOM,
    title: 'Zoom',
    icon: 'zoom'
}, {
    action: LOCATION_TYPES_ENUM.SKYPE,
    title: 'Skype',
    icon: 'skype'
}, {
    action: LOCATION_TYPES_ENUM.GOOGLE,
    title: 'Google Meet',
    icon: 'google'
}, {
    action: LOCATION_TYPES_ENUM.SBERJAZZ,
    title: 'SberJazz',
    icon: 'sberjazz'
}, {
    action: LOCATION_TYPES_ENUM.JITSI,
    title: 'Jitsi',
    icon: 'jitsi'
}, {
    action: LOCATION_TYPES_ENUM.WEBINAR,
    title: 'Webinar',
    icon: 'webinar'
}, {
    action: LOCATION_TYPES_ENUM.LIVE,
    title: 'Встреча вживую',
    icon: 'live'
}, {
    action: LOCATION_TYPES_ENUM.PHONE,
    title: 'Телефон',
    icon: 'phone'
}];

// Onboarding page
export const SLUG_PATTERN: RegExp = /^[-a-zA-Z0-9_]{6,64}$/;

// Common
export const MAX_COMMENT_LENGTH: number = 512;
export const PHONE_MASK: RegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){5,14}(\s*)?$/;
export const URL_PATTERN_STRICT: RegExp = /(^((https|http):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$)/;
export const URL_PATTERN: RegExp = /(^(https|http):\/\/?[A-z0-9])/;
export const EMAIL_PATTERN: string = '^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$';
export const DATE_FORMAT_FOR_FILTERS: string = 'yyyy-MM-dd';
export const DATE_FORMAT_DD_MM_YYYY: string = 'dd.MM.yyyy';
export const DATE_FORMAT_EEEE: string = 'EEEE';
export const TIME_FORMAT_HH_MM: string = 'HH:mm';
export const DATE_FORMAT_DD_MM_YYYY_HH_MM: string = 'dd.MM.yyyy HH:mm';

export const COMMON_SEARCH_DELAY: number = 500;

export enum MessageCodes {
    MSG_REGISTRATION_EMAIL_EXISTS = 'email_exists',
    MSG_REGISTRATION_ALREADY_AUTHORIZED = 'user_authorized',
}

export const errorsMap: Map<string, MessageModel> = new Map([
    [
        MessageCodes.MSG_REGISTRATION_EMAIL_EXISTS,
        {
            msg: ERROR_MSG_EMAIL_EXISTS
        }
    ],
    [
        MessageCodes.MSG_REGISTRATION_ALREADY_AUTHORIZED,
        {
            msg: ERROR_MSG_ALREADY_AUTHORIZED
        }
    ]
]);

export const ScriptStore: ScriptModel[] = [
    {
        name: SCRIPT_NAMES_ENUM.APIS_GOOGLE_NEW,
        src: 'https://accounts.google.com/gsi/client',
        loaded: false
    },
    {
        name: SCRIPT_NAMES_ENUM.CLOUDPAYMENTS,
        src: 'https://widget.cloudpayments.ru/bundles/cloudpayments',
        loaded: false
    },
    {
        name: SCRIPT_NAMES_ENUM.CRISP,
        src: 'https://client.crisp.chat/l.js',
        loaded: false
    },
];

export namespace LIST_SORT_ORDER_BOOLEAN {
    export const ASC: boolean = true;
    export const DESC: boolean = false;
}

export const DEFAULT_PAGE_SIZE: number = 20;

export const DEFAULT_PAGE_MODEL: PageModel = {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pagesCount: 1,

    hasNext: null,
    hasPrev: false,
    isFirstPage: true,
    isLastPage: true,
    listSize: null,
    startIndex: 1,
    endIndex: DEFAULT_PAGE_SIZE
};

export const DEFAULT_CONTACTS_LIST_SORT_MODEL: SortModel<ContactsListSortColumnType> = {
    sortColumn: CONTACTS_LIST_SORT_COLUMNS.LAST_EVENT_DATE,
    sortOrder: LIST_SORT_ORDER_BOOLEAN.DESC
};

export const DEFAULT_EVENTS_LIST_SORT_MODEL: SortModel<EventsListSortColumnType> = {
    sortColumn: EVENTS_LIST_SORT_COLUMNS.DATE,
    sortOrder: LIST_SORT_ORDER_BOOLEAN.DESC
};

// Integrations
export const ZOOM_INTEGRATION_CARD_DATA: IntegrationCardData = {
    picture: {
        source: 'assets/images/logo-zoom-32_2x.png 2x, assets/images/logo-zoom-32.png 1x',
        src: 'assets/images/logo-zoom-32.png',
        alt: ''
    },
    status: {
        title: LOCALIZATION_TARIFF_DEFAULT,
        color: 'var(--clr-green-100)'
    },
    categoryItems: [INTEGRATION_CARD_ZOOM_ITEM_VIDEO, INTEGRATION_CARD_ZOOM_ITEM_MARKETING],
    description: INTEGRATION_CARD_ZOOM_DESC,
    title: 'Zoom',
};

export const CLOUD_PAYMENTS_INTEGRATION_CARD_DATA: IntegrationCardData = {
    picture: {
        source: 'assets/images/logo-cloudpayments-32_2x.png 2x, assets/images/logo-cloudpayments-32.png 1x',
        src: 'assets/images/logo-cloudpayments-32.png',
        alt: ''
    },
    status: {
        title: LOCALIZATION_TARIFF_PRO,
        color: 'var(--clr-black-100)'
    },
    categoryItems: [INTEGRATION_CARD_CP_ITEM_PAYMENTS],
    description: INTEGRATION_CARD_CP_DESC,
    title: 'Cloud Payments',
};

export const WEBINAR_INTEGRATION_CARD_DATA: IntegrationCardData = {
    picture: {
        source: 'assets/images/logo_webinar-32_2x.png 2x, assets/images/logo_webinar-32.png 1x',
        src: 'assets/images/logo_webinar-32.png',
        alt: ''
    },
    status: {
        title: LOCALIZATION_TARIFF_DEFAULT,
        color: 'var(--clr-black-100)'
    },
    categoryItems: [INTEGRATION_CARD_ZOOM_ITEM_VIDEO],
    description: INTEGRATION_CARD_COMMON_DESC,
    title: 'Webinar Meetings',
};

export const LocationTypeCards: LocationTypeCardModel[] = [
    {
        type: LOCATION_TYPES_ENUM.ZOOM,
        title: 'Zoom',
        icon: {
            src: 'assets/images/logo-zoom-32_2x.png',
            alt: 'Zoom'
        },
        checked: false
    },
    {
        type: LOCATION_TYPES_ENUM.GOOGLE,
        title: 'Google Meet',
        icon: {
            src: 'assets/images/logo-google-32.png',
            alt: 'Google Meet'
        },
        checked: false
    },
    {
        type: LOCATION_TYPES_ENUM.SKYPE,
        title: 'Skype',
        icon: {
            src: 'assets/images/logo-skype-64.png',
            alt: 'Skype'
        },
        checked: false
    },
    {
        type: LOCATION_TYPES_ENUM.SBERJAZZ,
        title: 'SberJazz',
        icon: {
            src: 'assets/images/sberjazz-64.png',
            alt: 'SberJazz'
        },
        checked: false
    },
    {
        type: LOCATION_TYPES_ENUM.JITSI,
        title: 'Jitsi',
        icon: {
            src: 'assets/images/logo-jitsi-64.png',
            alt: 'Jitsi'
        },
        checked: false
    }
];

export namespace BREAKPOINTS {
    export const xl: string = '120rem'; // 1920px
    export const lg: string = '80rem'; // 1280px
    export const md: string = '1024px';
    export const sm: string = '45rem'; // 720px
    export const xs: string = '37.5rem'; // 600px
}
