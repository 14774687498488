function actionTypeCreator(featureName: string, partialFeatureName: string, actionName: string): string {
    return `[${featureName}][${partialFeatureName}] ${actionName}`;
}

export function getFeatureActionTypeCreator(
    actionTypeCache: Set<string> = new Set<string>(),
    actionTypeCreatorFn: (featureName: string, partialFeatureName: string, actionName: string) => string = actionTypeCreator
): (featureName: string) => (partialFeatureName: string) => (actionName: string) => string {
    return (featureName: string): ((actionName: string) => (actionDesc: string) => string) => {
        return (partialFeatureName: string) => (actionName: string) => {
            const actionType: string = actionTypeCreatorFn(featureName, partialFeatureName, actionName);

            if (actionTypeCache.has(actionType)) {
                throw Error(`Action '${actionType}' duplicated in '${actionTypeCache}'`);
            } else {
                actionTypeCache.add(actionType);
            }

            return actionType;
        };
    };
}
