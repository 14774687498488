import { Observable } from 'rxjs';

import { HostProfile, TeamMemberHostProfile } from '../models';
import { UpdateHostProfileData } from 'libs/common/util/src/lib/models/types-generated';


export abstract class HostRepository {
    public abstract getHost(): Observable<HostProfile>;
    public abstract getAllowedHosts(): Observable<HostProfile[]>;
    public abstract getTeamHostProfiles(team: string): Observable<TeamMemberHostProfile[]>;

    // public abstract createHost(data: CreateHostProfileData): Observable<HostProfile>;
    public abstract updateHost(id: string, data: UpdateHostProfileData): Observable<HostProfile>;
}
