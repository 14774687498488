import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IntegrationRepository, IntegrationMapper, CalendarMapper, Integrations } from '@common/util';
import { LoadIntegrationsGQL } from 'libs/common/util/src/lib/models/types-generated';

@Injectable()
export class IntegrationsGraphQLRepository extends IntegrationRepository {
    private mapper: IntegrationMapper = new IntegrationMapper();
    private calendarMapper: CalendarMapper = new CalendarMapper();

    constructor(private readonly getGQL: LoadIntegrationsGQL) {
        super();
    }

    public getIntegrations(): Observable<Integrations> {
        return this.getGQL.fetch().pipe(
            map(answer => this.mapper.mapFrom(answer.data.integration))
        );
    }

    public getZoomIntegration(): Observable<boolean> {
        return this.getGQL.fetch().pipe(
            map(answer => this.mapper.mapFrom(answer.data?.integration)),
            map(integration => integration?.zoom)
        );
    }
}
