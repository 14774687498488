import { Injectable, Type, Injector, OnDestroy, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';

import { Subject, Observable, Subscription, merge } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface DropDownData {
    component: ComponentType<any>;
    answer: Subject<any>;
    relativeObject: ElementRef | HTMLElement;
    data?: any;
}

@Injectable({ providedIn: 'root' })
export class DropDownService implements OnDestroy {
    // TODO: оптимизация Overlay
    // TODO: переделать dropDownRef на класс

    public ddRef: { overlay: OverlayRef; info: DropDownData };
    private routerSub: Subscription;

    constructor(private overlay: Overlay, router: Router) {
        this.routerSub = router.events
            .pipe(filter((e) => e instanceof NavigationEnd && !!this.ddRef))
            .subscribe(() => {
                this.ddRef.overlay.dispose();
                this.ddRef = undefined;
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) { this.routerSub.unsubscribe(); }
    }

    public OpenTemplate<R = any, P = any>(
        template: TemplateRef<any>,
        viewRef: ViewContainerRef,
        relativeObject: ElementRef | HTMLElement
    ) {
        const overlayRef = this.CreateOverlay(relativeObject, 'qlick-dd-tooltip');
        const portal = new TemplatePortal(template, viewRef);

        overlayRef.attach(portal);

        merge(
            overlayRef.backdropClick(),
            overlayRef.keydownEvents().pipe(filter((keyEvent) => keyEvent.key === 'Escape')),
        ).subscribe(() => {
            overlayRef.detach();
            overlayRef.dispose();
        });
    }

    public Open<R = any, P = any>(
        component: Type<any>,
        relativeObject: ElementRef | HTMLElement,
        data?: P
    ): Observable<R> {
        const answer = new Subject<any>();
        const componentInfo: DropDownData = {
            component,
            answer,
            data,
            relativeObject,
        };

        const overlayRef = this.CreateOverlay(relativeObject, 'quick-dd-panel');
        const portal = this.CreatePortal(componentInfo, overlayRef);

        overlayRef.attach(portal);

        merge(
            overlayRef.backdropClick(),
            overlayRef.keydownEvents().pipe(filter((keyEvent) => keyEvent.key === 'Escape')),
            componentInfo.answer
        ).subscribe(() => {
            setTimeout(() => {
                if (this.ddRef) {
                    this.Detach(this.ddRef.overlay, this.ddRef.info);
                }
            }, 0);
        });

        this.ddRef = { overlay: overlayRef, info: componentInfo };



        return answer.asObservable();
    }

    // Overlay
    private CreateOverlay(relativeObject: HTMLElement | ElementRef, panelClass: string) {
        const overlayConfig = this.CreateOverlayConfig(relativeObject, panelClass);
        return this.overlay.create(overlayConfig);
    }

    private CreateOverlayConfig(relativeObject: HTMLElement | ElementRef, panelClass: string): OverlayConfig {
        const config: OverlayConfig = {
            width: 'auto',
            height: 'auto',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            hasBackdrop: true,
            backdropClass: 'quick-dd-backdrop',
            panelClass,
            disposeOnNavigation: true,
        };

        config.positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(relativeObject)
            .withPositions([
                {
                    overlayX: 'end',
                    overlayY: 'top',
                    originX: 'end',
                    originY: 'top',
                },
            ]);

        return new OverlayConfig(config);
    }

    // Portal
    private CreatePortal(componentInfo: DropDownData, overlay: OverlayRef) {
        const injector = Injector.create({ providers: [
            { provide: 'ddData', useValue: componentInfo.data },
            { provide: 'ddOverlay', useValue: overlay },
            { provide: 'ddAnswer', useValue: componentInfo.answer },
        ]});

        return new ComponentPortal(componentInfo.component, null, injector);
    }

    private Detach(overlay: OverlayRef, info: DropDownData) {
        overlay.detach();
        overlay.dispose();
        info.answer.complete();
        this.ddRef = undefined;
    }
}
