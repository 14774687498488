import { LocationLinkSettingsApiModel, LocationLinkSettingsStateModel, Mapper } from '../models';

export class LocationLinkSettingsModelMapper implements Mapper<LocationLinkSettingsApiModel, LocationLinkSettingsStateModel | null> {
    public mapFrom(raw: LocationLinkSettingsApiModel): LocationLinkSettingsStateModel | null {
        if (!raw) {
            return null;
        }

        return {
            hostProfileId: raw.host_profile ?? null,
            locationType: raw.location_type ?? null,
            createMeeting: raw.create_meeting ?? null,
            locationLink: raw.location_link ?? null,
            id: raw.id ?? null,
        };

    }

    public mapTo(model: LocationLinkSettingsStateModel): LocationLinkSettingsApiModel {
        const result: LocationLinkSettingsApiModel =  {
            host_profile: model.hostProfileId,
            location_type: model.locationType,
            create_meeting: model.createMeeting,
            location_link: model.locationLink,
        };

        if (model.id) {
            result.id = model.id;
        }

        return result;
    }
}
