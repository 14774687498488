export const svgIcons: Record<string, string> = {
    cancel: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.69678 13.303L13.3028 2.69702" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    reschedule: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 15.5C12.366 15.5 15.5 12.366 15.5 8.5C15.5 4.63401 12.366 1.5 8.5 1.5C4.63401 1.5 1.5 4.63401 1.5 8.5C1.5 12.366 4.63401 15.5 8.5 15.5Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.5 5.5L8.5 8.5H13.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 3.5V4.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 13.5V12.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.5 8.5H4.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    edit: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
                <path d="M13 7L9 3" data-cap="butt" data-color="color-2" data-name="butt color"/>
                <path d="M5.5 14.5l-5 1 1-5 10-10 4 4-10 10z" data-cap="butt"/>
            </g>
        </svg>`,
    hide: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                <path d="M.5 8s3-5.5 7.5-5.5S15.5 8 15.5 8s-3 5.5-7.5 5.5S.5 8 .5 8z"/>
                <path d="M5 8c0-1.657 1.343-3 3-3M11 8c0 1.657-1.343 3-3 3M1 15L15 1" data-color="color-2"/>
            </g>
        </svg>`,
    remove: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                <path d="M2.5 6.5v7c0 1.105.895 2 2 2h8c1.105 0 2-.895 2-2v-7"/>
                <path d="M1.5 3.5h14M6.5 3.5v-3h4v3" data-color="color-2"/>
                <path d="M8.5 7.5v5M11.5 7.5v5M5.5 7.5v5"/>
            </g>
        </svg>`,

    live: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="#4284D7">
                <circle cx="8" cy="7" r="2" data-color="color-2"/>
                <path d="M7.3 15.7c-.1-.1-4.2-3.7-4.2-3.8C1.7 10.7 1 8.9 1 7c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-.7 3.7-2.1 5-.1.1-4.1 3.7-4.2 3.8-.4.3-1 .3-1.4-.1zm-2.7-5l3.4 3 3.4-3c1-1 1.6-2.2 1.6-3.6 0-2.8-2.2-5-5-5S3 4.2 3 7c0 1.4.6 2.7 1.6 3.7 0-.1 0-.1 0 0z"/>
            </g>
        </svg>`,
    phone: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="#4284D7">
                <path d="M13.167 11.021L10.45 9.814a1.4 1.4 0 00-1.662.4l-.783.979A10.7 10.7 0 014.8 8l.979-.783a1.4 1.4 0 00.4-1.661L4.981 2.832a1.4 1.4 0 00-1.632-.787l-2.3.6A1.4 1.4 0 00.014 4.2a13.91 13.91 0 0011.792 11.786 1.4 1.4 0 001.552-1.037l.6-2.3a1.394 1.394 0 00-.791-1.628z"/>
                <path d="M16 8h-2a6.006 6.006 0 00-6-6V0a8.009 8.009 0 018 8z" data-color="color-2"/>
                <path d="M12 8h-2a2 2 0 00-2-2V4a4 4 0 014 4z" data-color="color-2"/>
            </g>
        </svg>`,
    skype: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
            <path fill="#174BA8" d="M5.33301 1.33334c-.52529 0-1.04543.10347-1.53074.30449-.4853.20101-.92625.49565-1.29769.86709-.37143.37143-.66607.81239-.86709 1.29769-.20102.4853-.30448 1.00545-.30448 1.53073 0 .52529.10346 1.04543.30448 1.53074.20102.4853.49566.92626.86709 1.29769.37144.37143.81239.66607 1.29769.86709.48531.20102 1.00545.30448 1.53074.30448s1.04543-.10346 1.53073-.30448c.4853-.20102.92626-.49566 1.2977-.86709.37143-.37143.66607-.81239.86709-1.29769.20102-.48531.30448-1.00545.30448-1.53074 0-.52528-.10346-1.04543-.30448-1.53073-.20102-.4853-.49566-.92626-.86709-1.29769-.37144-.37144-.8124-.66608-1.2977-.86709-.4853-.20102-1.00544-.30449-1.53073-.30449z"/>
            <path fill="#146CE8" d="M10.667 6.66666c-1.06087 0-2.07829.42142-2.82843 1.17157-.75015.75015-1.17158 1.76756-1.17158 2.82847 0 1.0608.42143 2.0782 1.17158 2.8284.75014.7501 1.76756 1.1716 2.82843 1.1716 1.0609 0 2.0783-.4215 2.8284-1.1716.7502-.7502 1.1716-1.7676 1.1716-2.8284 0-1.06091-.4214-2.07832-1.1716-2.82847-.7501-.75015-1.7675-1.17157-2.8284-1.17157z"/>
            <path fill="#30A6FF" d="M8 2c-.78793 0-1.56815.15519-2.2961.45672-.72795.30153-1.38939.74349-1.94654 1.30064S2.75825 4.97595 2.45672 5.7039C2.15519 6.43185 2 7.21207 2 8s.15519 1.56815.45672 2.2961c.30153.728.74349 1.3894 1.30064 1.9465.55715.5572 1.21859.9991 1.94654 1.3007C6.43185 13.8448 7.21207 14 8 14s1.56815-.1552 2.2961-.4567c.728-.3016 1.3894-.7435 1.9465-1.3007.5572-.5571.9991-1.2185 1.3007-1.9465C13.8448 9.56815 14 8.78793 14 8s-.1552-1.56815-.4567-2.2961c-.3016-.72795-.7435-1.38939-1.3007-1.94654-.5571-.55715-1.2185-.99911-1.9465-1.30064C9.56815 2.15519 8.78793 2 8 2z"/>
            <path fill="#fff" d="M5.65639 10.4386c0-.3456.363-.5413.61066-.5413.24767 0 .93867.4837 1.601.4837.35134 0 1.14034-.0344 1.14034-.76336 0-1.195-3.37167-1.10267-3.37167-3.09134 0-.346.095-1.93166 2.59467-1.93166.672 0 1.90331.19233 1.90331.831 0 .46866-.31931.58966-.53331.58966-.41467 0-.58167-.37433-1.42834-.37433-1.16933 0-1.14033.70066-1.14033.80266 0 1.00567 3.35768 1.00567 3.35768 3.08501 0 2.04696-2.49701 1.93796-2.86001 1.93796-.37267 0-1.874-.1813-1.874-1.028z"/>
        </svg>`,
    zoom: `
        <picture>
            <source srcset="assets/images/icon-zoom-32.png 2x, assets/images/icon-zoom-16.png 1x" />
            <img src="assets/images/icon-zoom-16.png" alt="" />
        </picture>`,
    zoom_link: `
        <picture>
            <source srcset="assets/images/icon-zoom-32.png 2x, assets/images/icon-zoom-16.png 1x" />
            <img src="assets/images/icon-zoom-16.png" alt="" />
        </picture>`,
    jitsi: `
        <picture>
            <source srcset="assets/images/icon-jitsi-32.png 2x, assets/images/icon-jitsi-16.png 1x" />
            <img src="assets/images/icon-jitsi-16.png" alt="" />
        </picture>`,
    google: `
        <picture>
            <source srcset="assets/images/google-meet-32.png 2x, assets/images/google-meet-16.png 1x" />
            <img src="assets/images/google-meet-16.png" alt="" />
        </picture>`,
    sberjazz: `
        <picture>
            <source srcset="assets/images/sberjazz-16.png" />
            <img src="assets/images/sberjazz-16.png" alt="" />
        </picture>`,
    webinar: `
        <picture>
            <source srcset="assets/images/logo_webinar-32.png 2x, assets/images/logo_webinar-16.png 1x" />
            <img src="assets/images/logo_webinar-16.png" alt="" />
        </picture>`,
    RU: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F0F0F0"/>
                <path d="M15.5024 10.7826C15.824 9.91594 16 8.97856 16 8C16 7.02144 15.824 6.08406 15.5024 5.21741H0.497594C0.176031 6.08406 0 7.02144 0 8C0 8.97856 0.176031 9.91594 0.497594 10.7826L8 11.4783L15.5024 10.7826Z" fill="#0052B4"/>
                <path d="M8.00003 16C11.4397 16 14.3721 13.829 15.5024 10.7826H0.49762C1.62796 13.829 4.56031 16 8.00003 16Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>`,
    EN: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F0F0F0"/>
            <path d="M1.65379 3.12946C1.02538 3.94705 0.551541 4.88939 0.275635 5.91311H4.43745L1.65379 3.12946Z" fill="#0052B4"/>
            <path d="M15.7244 5.91315C15.4485 4.88946 14.9747 3.94711 14.3463 3.12952L11.5627 5.91315H15.7244Z" fill="#0052B4"/>
            <path d="M0.275635 10.0871C0.551572 11.1108 1.02542 12.0531 1.65379 12.8707L4.43735 10.0871H0.275635Z" fill="#0052B4"/>
            <path d="M12.8706 1.65382C12.053 1.02542 11.1107 0.551572 10.087 0.275635V4.43742L12.8706 1.65382Z" fill="#0052B4"/>
            <path d="M3.12949 14.3462C3.94708 14.9747 4.88942 15.4485 5.91311 15.7244V11.5627L3.12949 14.3462Z" fill="#0052B4"/>
            <path d="M5.91308 0.275635C4.88939 0.551572 3.94705 1.02542 3.12949 1.65379L5.91308 4.43738V0.275635Z" fill="#0052B4"/>
            <path d="M10.087 15.7244C11.1107 15.4485 12.053 14.9747 12.8706 14.3463L10.087 11.5627V15.7244Z" fill="#0052B4"/>
            <path d="M11.5627 10.0871L14.3463 12.8707C14.9747 12.0532 15.4485 11.1108 15.7244 10.0871H11.5627Z" fill="#0052B4"/>
            <path d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z" fill="#D80027"/>
            <path d="M10.087 10.087L13.6569 13.6569C13.8211 13.4928 13.9777 13.3212 14.1271 13.1433L11.0708 10.087H10.087V10.087Z" fill="#D80027"/>
            <path d="M5.91304 10.087H5.91298L2.34314 13.6569C2.50726 13.8211 2.67886 13.9777 2.85673 14.1271L5.91304 11.0708V10.087Z" fill="#D80027"/>
            <path d="M5.91305 5.91312V5.91305L2.34317 2.34314C2.17899 2.50726 2.02236 2.67886 1.87292 2.85673L4.92927 5.91308H5.91305V5.91312Z" fill="#D80027"/>
            <path d="M10.087 5.91314L13.6569 2.3432C13.4928 2.17902 13.3212 2.02239 13.1433 1.87299L10.087 4.92933V5.91314Z" fill="#D80027"/>
        </svg>`,


};

export type svgIconType = keyof typeof svgIcons;
