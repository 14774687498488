import { Directive, Input, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CopyToClipboard, SNACK_BAR_CLOSE } from '@common/util';

@Directive({
    selector: '[qwCopyToClipboard]',
    standalone: true,
})
export class CopyToClipboardDirective {
    @Input('qwCopyToClipboard') public stringToCopy: string;

    constructor(private readonly sb: MatSnackBar) {}

    @HostListener('click', ['$event']) public Copy(event: MouseEvent): void {
        event.stopPropagation();
        CopyToClipboard(this.stringToCopy);
        this.sb.open('Ссылка скопирована', SNACK_BAR_CLOSE);
    }
}
