import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { fromSnackBarActions } from './snack-bar.actions';
import { FeaturePartialState } from '../feature.state';
import { SnackBarConfig } from '@common/util';

@Injectable()
export class SnackBarFacade {
    constructor(private store: Store<FeaturePartialState>) {}

    public showDefault(config: SnackBarConfig): void {
        this.store.dispatch(fromSnackBarActions.ShowDefault(config));
    }

    public dismiss(): void {
        this.store.dispatch(fromSnackBarActions.Dismiss());
    }
}
