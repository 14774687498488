import {
    ActivityTypeBaseApiModel,
    ActivityTypeBaseStateModel,
    ActivityTypeApiModel,
    ActivityTypeLocation,
    ActivityTypeStateModel
} from '../models';
import { randomColor } from '../helpers';

export function convertLocationModel(locations: ActivityTypeLocation[]): ActivityTypeLocation[] {
    return locations.map((location: ActivityTypeLocation) => ({
        type: location.type ?? null,
        config: location.config ?? {},
        configuration: location.configuration ?? {}
    }));
}

export function convertActivityTypeApiBaseModelToStateModel(
    api: ActivityTypeBaseApiModel | ActivityTypeApiModel
): ActivityTypeBaseStateModel {
    return {
        color: api.color ?? randomColor(),
        description: api.description ?? null,
        duration: api.duration ?? 30,
        name: api.name ?? null,
        id: api.id ?? null,
        locations: api.locations ? convertLocationModel(api.locations) : [],
        participantLimit: api.participant_limit ?? api.participantLimit ?? 1,
        requestPhone: api.request_phone ?? null,
        slug: api.slug ?? null,
    };
}

export function convertActivityTypeBaseStateModelToApiModel(
    state: ActivityTypeBaseStateModel | ActivityTypeStateModel
): ActivityTypeBaseApiModel {
    return {
        color: state.color ?? null,
        description: state.description ?? null,
        duration: state.duration ?? null,
        name: state.name ?? null,
        id: state.id ?? null,
        locations: state.locations ? convertLocationModel(state.locations) : [],
        participant_limit: state.participantLimit ?? null,
        request_phone: state.requestPhone ?? null,
        slug: state.slug ?? null,
    };
}
