import { fromSnackBarActions } from './snack-bar.actions';
import { initialSnackBarState, SnackBarState } from './snack-bar.state';
import { ActionReducer, createReducer, on } from '@ngrx/store';

export const snackBarReducer: ActionReducer<SnackBarState> = createReducer(
    initialSnackBarState,
    on(fromSnackBarActions.ShowDefault, (state: SnackBarState) => ({
        ...state,
        isOpen: true
    })),
    on(fromSnackBarActions.ShowSuccess, (state: SnackBarState) => ({
        ...state,
        isOpen: true
    })),
    on(fromSnackBarActions.ShowWarning, (state: SnackBarState) => ({
        ...state,
        isOpen: true
    })),
    on(fromSnackBarActions.ShowDanger, (state: SnackBarState) => ({
        ...state,
        isOpen: true
    })),


    on(fromSnackBarActions.Dismiss, (state: SnackBarState) => ({
        ...state,
        isOpen: false
    }))
);
