import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppFacade } from '@common/data-access';
import { UserStateModel } from '@common/util';

@Injectable()
export class ZoomConfirmGuard  {
    constructor(
        private readonly router: Router,
        private readonly appFacade: AppFacade
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> {
        return this.checkUser().pipe(
            switchMap(() => this.appFacade.user$.pipe(
                take(1),
                map((user: UserStateModel | null): UrlTree => {
                    return user?.id
                        ? this.router.createUrlTree(['admin', 'integrations'], { queryParams: { integration: 'zoom', ...route.queryParams } })
                        : this.router.createUrlTree(['admin']);
                })
            ))
        );
    }

    private checkUser(): Observable<boolean> {
        // TODO: should be refactored
        return this.appFacade.isAccountChecked$.pipe(
            switchMap(() => this.appFacade.user$.pipe(
                take(1),
                tap((user: UserStateModel | null) => this.prefetch(!!user?.id)),
                switchMap(() => this.appFacade.isAccountChecked$.pipe(
                    filter(Boolean),
                    take(1)
                ))
            )),
            take(1),
        );
    }

    private prefetch(isChecked: boolean): void {
        if (!isChecked) {
            this.appFacade.loadUser();
        }
    }
}
