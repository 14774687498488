export class User {
    public readonly id: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public password: string;

    public readonly hasUsablePassword: boolean;
    public readonly crispEmailHash: string;
    public readonly FullName: string;


    constructor(obj: Partial<User>) {
        Object.assign(this, obj);
        this.FullName = this.firstName + (this.lastName ? ` ${this.lastName}` : '');
    }
}
