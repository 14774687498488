import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { FeaturePartialState } from '../feature.state';
import { fromAppActions } from './app.actions';
import { fromSnackBarActions } from '../snack-bar/snack-bar.actions';
import { CommonApiService } from '@common/api';
import {
    MessageManagerService,
    UserStateModel,
    UserMetadataStateModel,
    LOGOUT_ACTION,
    COMMON_ERROR_MSG,
    REPEAT_EMAIL_CONFIRMATION_SUCCESS_MSG,
    SNACK_BAR_CLOSE,
    SNACK_BAR_SAVING_ERROR,
    SNACK_BAR_USER_CHANGED,
    AdminModeService,
    UtmLabelsModel,
    ApiModel,
    MIXPANEL_IDS_ENUM,
    MixpanelService,
    UtmTagsService
} from '@common/util';

@Injectable()
export class AppEffects {

    // ================================
    //  Logout
    // ================================

    public readonly onLogout$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.Logout),
            switchMap(() => this.apiService.logout().pipe(
                map(() => fromAppActions.LogoutSuccess()),
                catchError((error: HttpErrorResponse) => {
                    return of(fromAppActions.LogoutError({ error: this.messageService.showMessage(error.error?.code) }));
                })
            ))
        )
    );

    public readonly onLogoutSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LogoutSuccess),
            tap(() => void this.router.navigate(['admin', 'login'])),
            map(() => LOGOUT_ACTION)
        )
    );

    public readonly onLogoutError$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LogoutError),
            tap(() => void this.router.navigate(['admin', 'login'])),
            map(() => LOGOUT_ACTION)
        )
    );

    // ================================
    //  Load User
    // ================================

    public readonly onLoadUser$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LoadUser),
            switchMap(() => this.apiService.getUser().pipe(
                mergeMap((model: UserStateModel) => [
                    fromAppActions.LoadUserSuccess({ model }),
                    fromAppActions.LoadUserMetadata()
                ]),
                catchError((error: HttpErrorResponse) => {
                    return of(fromAppActions.LoadUserError({ error: this.messageService.showMessage(error.error?.code) }));
                })
            ))
        )
    );

    // TODO: temporary solution
    public readonly onLoadUserSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LoadUserSuccess),
            tap(({ model }: fromAppActions.LoadUserSuccess) => this.mode.setUser(model)),
        ), { dispatch: false }
    );

    // ================================
    //  Fetch User
    // ================================

    public readonly onFetchUser$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.FetchUser),
            switchMap(() => this.apiService.getUser().pipe(
                map((model: UserStateModel) => fromAppActions.FetchUserSuccess({ model })),
                catchError((error: HttpErrorResponse) => {
                    return of(fromAppActions.FetchUserError({ error: this.messageService.showMessage(error.error?.code) }));
                })
            ))
        )
    );

    // ================================
    //  Load User Metadata
    // ================================

    public readonly onLoadUserMetadata$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LoadUserMetadata),
            switchMap(() => this.apiService.getUserMetadata().pipe(
                map((model: UserMetadataStateModel) => fromAppActions.LoadUserMetadataSuccess({ model })),
                catchError((error: HttpErrorResponse) => {
                    return of(fromAppActions.LoadUserMetadataError({ error: this.messageService.showMessage(error.error?.code) }));
                })
            ))
        )
    );

    // TODO: temporary solution
    public readonly onLoadUserMetadataSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.LoadUserMetadataSuccess),
            tap(({ model }: fromAppActions.LoadUserMetadataSuccess) => this.mode.setUserMetaData(model)),
        ), { dispatch: false }
    );

    // ================================
    //  Update User
    // ================================

    public readonly onUpdateUser$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.UpdateUser),
            switchMap(({ model }: fromAppActions.UpdateUser) => this.apiService.updateUser(model).pipe(
                map((updatedModel: UserStateModel) => fromAppActions.UpdateUserSuccess({ model: updatedModel })),
                catchError((error: HttpErrorResponse) => {
                    return of(fromAppActions.UpdateUserError({ error: this.messageService.showMessage(error.error?.code) }));
                })
            ))
        )
    );

    public readonly onUpdateUserSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.UpdateUserSuccess),
            map(() => fromSnackBarActions.ShowDefault({ message: SNACK_BAR_USER_CHANGED, action: SNACK_BAR_CLOSE }))
        )
    );

    public readonly onUpdateUserError$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.UpdateUserError),
            map(({ error }: fromAppActions.UpdateUserError) => fromSnackBarActions.ShowDefault({
                message: error ?? SNACK_BAR_SAVING_ERROR,
                action: SNACK_BAR_CLOSE
            }))
        )
    );

    // ================================
    // Repeat Confirmation Email
    // ================================

    public readonly onRepeatConfirmation$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.RepeatConfirmation),
            switchMap(() => this.apiService.repeatConfirmation().pipe(
                map(() => fromAppActions.RepeatConfirmationSuccess()),
                catchError((error: HttpErrorResponse) =>
                    of(fromAppActions.RepeatConfirmationError({ error: this.messageService.showMessage(error.error?.code) }))
                )
            ))
        )
    );

    public readonly onRepeatConfirmationSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.RepeatConfirmationSuccess),
            map(() => fromSnackBarActions.ShowDefault({ message: REPEAT_EMAIL_CONFIRMATION_SUCCESS_MSG, action: SNACK_BAR_CLOSE }))
        )
    );

    public readonly onRepeatConfirmationError$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.RepeatConfirmationError),
            map(({ error }: fromAppActions.RepeatConfirmationError) =>
                fromSnackBarActions.ShowDefault({ message: error, action: SNACK_BAR_CLOSE })
            )
        )
    );

    // ================================
    // Register
    // ================================

    public readonly onRegister$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.Register),
            switchMap(({ email }: fromAppActions.Register) => {
                const utm_labels: UtmLabelsModel = this.utmService.getUtmTags();
                return this.apiService.registerNewAccount({ email, utm_labels }).pipe(
                    map((response: ApiModel) => {
                        if (response.ok) {
                            this.mixpanelService.init(email);
                            this.mixpanelService.track(MIXPANEL_IDS_ENUM.SIGN_UP, { email });
                            return fromAppActions.RegisterSuccess({ response, email });
                        } else {
                            return fromAppActions.RegisterError({ error: this.messageService.showMessage(response.error) });
                        }
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(fromAppActions.RegisterError({ error: this.messageService.showMessage(error.error?.code) }))
                    )
                );
            })
        )
    );

    public readonly onRegisterSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.RegisterSuccess),
            tap(() => void this.router.navigate(['admin', 'onboarding']))
        ), { dispatch: false }
    );

    public readonly onRegisterError$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.RegisterError),
            tap(({ error }: fromAppActions.RegisterError) => fromSnackBarActions.ShowDefault({
                message: error ?? COMMON_ERROR_MSG,
                action: SNACK_BAR_CLOSE
            }))
        ), { dispatch: false }
    );

    // ================================
    // Confirm registration
    // ================================

    public readonly onConfirmRegistrationOptimistic$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAppActions.ConfirmRegistrationOptimistic),
            switchMap(({ token }: fromAppActions.ConfirmRegistrationOptimistic) => {
                return this.apiService.confirmRegistration({ token }).pipe(
                    map(() => fromAppActions.ConfirmRegistrationSuccess()),
                    catchError(() => of(fromAppActions.ConfirmRegistrationError()))
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<FeaturePartialState>,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly apiService: CommonApiService,
        private readonly messageService: MessageManagerService,
        private readonly mode: AdminModeService,
        private readonly mixpanelService: MixpanelService,
        private readonly utmService: UtmTagsService,
    ) {}
}
