import { Mapper, Team } from '../../models';
import { TeamShortFragment  } from 'libs/common/util/src/lib/models/types-generated';

export class TeamMapper implements Mapper<TeamShortFragment, Team> {
    mapFrom(raw: TeamShortFragment): Team {
        return new Team(
            raw.id, raw.name, raw.slug, raw.currentUserRole, raw.timezone
        );
    }

    mapTo(model: Team): TeamShortFragment {
        return model;
    }
}
