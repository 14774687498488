import { BaseHostApiModel, BaseHostStateModel } from '../models';

export function convertBaseHostApiModelToStateModel(api: BaseHostApiModel): BaseHostStateModel {
    return {
        name: api.name ?? null,
        email: api.email ?? null,
        avatar: api.avatar ?? null,
        id: api.id ?? null
    };
}

export function convertBaseHostStateModelToApiModel(api: BaseHostStateModel): BaseHostApiModel {
    return {
        name: api.name ?? null,
        email: api.email ?? null,
        avatar: api.avatar ?? null,
        id: api.id ?? null
    };
}
