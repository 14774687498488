import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from './app.state';
import { FEATURE_STATE_KEY } from '../feature.keys';
import { FeaturePartialState, FeatureState } from '../feature.state';
import { fromFeatureQuerySelector } from '../feature.selectors';
import { ModelStatus, UserStateModel, UserMetadataStateModel } from '@common/util';

export namespace fromAppQuery {
    export const getState: MemoizedSelector<FeaturePartialState, AppState> = createSelector(
        fromFeatureQuerySelector.getFeature,
        (state: FeatureState) => state[FEATURE_STATE_KEY.APP_STATE]
    );

    export const errorMessage: MemoizedSelector<FeaturePartialState, string | null | undefined> = createSelector(
        getState,
        (state: AppState) => state.error
    );
    export const isAccountChecked: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.isAccountChecked
    );
    export const getUser: MemoizedSelector<FeaturePartialState, UserStateModel | null> = createSelector(
        getState,
        (state: AppState) => state.user
    );

    export const getUserMetaData: MemoizedSelector<FeaturePartialState, UserMetadataStateModel> = createSelector(
        getState,
        (state: AppState) => state.userMetaData
    );
    export const isEmailVerified: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.userMetaData.emailVerified
    );
    export const isOnboardingFinished: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.userMetaData.onboardingFinished
    );
    export const getCrispProfileId: MemoizedSelector<FeaturePartialState, string | null> = createSelector(
        getState,
        (state: AppState) => state.userMetaData.crispProfileId
    );

    export const getLoadingStatus: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.loading
    );
    export const getFetchingStatus: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.fetching
    );
    export const getModelStatus: MemoizedSelector<FeaturePartialState, ModelStatus> = createSelector(
        getState,
        (state: AppState) => state.status
    );

    export const isRegistering: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.isRegistering
    );

    export const isRegistered: MemoizedSelector<FeaturePartialState, boolean> = createSelector(
        getState,
        (state: AppState) => state.isRegistered
    );
}
