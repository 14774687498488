import { ActivityTypeApiModel, ActivityTypeNotificationSettings, ActivityTypeStateModel } from '../models';
import { CONFIRMATION_TYPES_ENUM, ConfirmationType, EVENT_TYPES_ENUM, EventType } from '../enums';
import {
    convertActivityTypeApiBaseModelToStateModel,
    convertActivityTypeBaseStateModelToApiModel
} from './activity-type-base-model.converter';

function convertActiveConfirmProperty(activeConfirmProperty: string): ConfirmationType {
    switch (activeConfirmProperty) {
        case CONFIRMATION_TYPES_ENUM.REDIRECT:
            return CONFIRMATION_TYPES_ENUM.REDIRECT;

        case CONFIRMATION_TYPES_ENUM.MESSAGE:
            return CONFIRMATION_TYPES_ENUM.MESSAGE;

        default:
            return CONFIRMATION_TYPES_ENUM.STANDARD;
    }
}

function convertNotificationSettingsFromApiToStateModel(api: ActivityTypeApiModel): ActivityTypeNotificationSettings {
    return {
        smsReminder: api?.sms_reminder ?? null,
        smsReminderText: api?.sms_reminder_text ?? null,
        smsReminderMinutes: api?.sms_reminder_minutes ?? null,

        mailReminder: api?.mail_reminder ?? null,
        mailReminderText: api?.mail_remainder_text ?? null,
        mailReminderMinutes: api?.mail_reminder_minutes ?? null,

        smsFollowup: api?.sms_followup ?? null,
        smsFollowupText: api?.sms_followup_text ?? null,
        smsFollowupMinutes: api?.sms_followup_minutes ?? null,

        mailFollowup: api?.mail_followup ?? null,
        mailFollowupText: api?.mail_followup_text ?? null,
        mailFollowupMinutes: api?.mail_followup_minutes ?? null,
    };
}

function participantLimitToEventType(participantLimit: number): EventType | undefined {
    switch (participantLimit) {
        case undefined:
            return undefined;

        case 0:
            return EVENT_TYPES_ENUM.UNLIMITED;

        case 1:
            return EVENT_TYPES_ENUM.ONE_BY_ONE;

        default:
            return EVENT_TYPES_ENUM.GROUP;
    }
}

function eventTypeToParticipantLimit(eventType: EventType, participantLimit: number): number {
    switch (eventType) {
        case EVENT_TYPES_ENUM.GROUP:
            return participantLimit;

        case EVENT_TYPES_ENUM.ONE_BY_ONE:
            return 1;

        case EVENT_TYPES_ENUM.UNLIMITED:
            return 0;
    }
}

function convertNotificationSettingsFromStateToApiModel(state: ActivityTypeStateModel): Pick<
    ActivityTypeApiModel,
    | 'mail_reminder'
    | 'mail_remainder_text'
    | 'mail_reminder_minutes'
    | 'sms_reminder'
    | 'sms_reminder_text'
    | 'sms_reminder_minutes'
> {
    return {
        mail_reminder: state.notificationSettings?.mailReminder ?? false,
        mail_remainder_text: state.notificationSettings?.mailReminderText ?? '',
        mail_reminder_minutes: state.notificationSettings?.mailReminderMinutes ?? 30,

        sms_reminder: state.notificationSettings?.smsReminder ?? false,
        sms_reminder_text: state.notificationSettings?.smsReminderText ?? '',
        sms_reminder_minutes: state.notificationSettings?.smsReminderMinutes ?? 30,
    };
}

export function convertActivityTypeApiModelToStateModel(api: ActivityTypeApiModel): ActivityTypeStateModel {
    return {
        ...convertActivityTypeApiBaseModelToStateModel(api),

        profile: api.profile ?? null,
        active: api.active ?? null,
        preventBeforeHours: api.prevent_before_hours ?? 0,
        slotStep: api.slot_step ?? 30,
        sortOrder: api.sort_order ?? null,

        scheduleDays: api.schedule_days ?? 60,
        lastScheduleDate: api.last_schedule_date ?? null,
        scheduleRangeBegin: api.schedule_range_begin ?? null,
        scheduleRangeEnd: api.schedule_range_end ?? null,

        price: api.price ?? 0,
        currency: api.currency ?? null,
        currencySymbol: api.currency_symbol ?? null,

        activeConfirmProperty: convertActiveConfirmProperty(api.active_confirm_property ?? api.activeConfirmProperty),
        redirectTo: api.redirect_to ?? api.redirectTo ?? null,
        customMessage: api.custom_message ?? null,
        customSuccessMessage: api.custom_success_message ?? api.customSuccessMessage ?? null,

        notificationSettings: convertNotificationSettingsFromApiToStateModel(api),

        eventType: participantLimitToEventType(api.participant_limit) || EVENT_TYPES_ENUM.ONE_BY_ONE
    };
}

export function convertActivityTypeStateModelToApiModel(state: ActivityTypeStateModel): ActivityTypeApiModel {
    return {
        ...convertActivityTypeBaseStateModelToApiModel(state),
        ...convertNotificationSettingsFromStateToApiModel(state),
        participant_limit: state.participantLimit ? eventTypeToParticipantLimit(state.eventType, state.participantLimit) : 1,

        profile: state.profile ?? null,
        active: state.active ?? null,
        prevent_before_hours: state.preventBeforeHours ?? null,
        slot_step: state.slotStep ?? null,
        sort_order: state.sortOrder ?? null,

        schedule_days: state.scheduleDays ?? null,
        last_schedule_date: state.lastScheduleDate ?? null,
        schedule_range_begin: state.scheduleRangeBegin ?? null,
        schedule_range_end: state.scheduleRangeEnd ?? null,

        price: state.price ?? null,
        currency: state.currency ?? null,
        currency_symbol: state.currencySymbol ?? null,

        active_confirm_property: convertActiveConfirmProperty(state.activeConfirmProperty),
        redirect_to: state.redirectTo ?? '',
        custom_message: state.customMessage ?? '',
        custom_success_message: state.customSuccessMessage ?? '',
    };
}
