import { ModelStatus, ListAsyncState, UserStateModel, UserMetadataStateModel } from '@common/util';

export interface AppState extends ListAsyncState {
    user: UserStateModel | null;
    userMetaData: UserMetadataStateModel;

    isAccountChecked: boolean;
    confirmRegistration: boolean;

    isRegistering: boolean;
    isRegistered: boolean;
}

export const initialAppState: AppState = {
    user: null,

    userMetaData: {
        onboardingFinished: false,
        emailVerified: true,
        crispProfileId: null,
        /** Current value needed for correct display Telegram notification on login page */
        tgHostEnabled: true,
        tgLink: null,
        tgUsername: null
    },

    isAccountChecked: false,
    confirmRegistration: false,

    isRegistering: false,
    isRegistered: false,

    loading: false,
    fetching: false,
    status: ModelStatus.Init,
    error: null
};
