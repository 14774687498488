import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface MessageBusEvent<T = string> {
    readonly type: T;
}

export class MessageBus<T extends MessageBusEvent<M>, M> {
    private eventSource: Subject<T> = new Subject<T>();

    public emit(event: T): void {
        this.eventSource.next(event);
    }

    public onEmit(): Observable<T> {
        return this.eventSource.asObservable();
    }

    public ofType(eventType: M): Observable<T> {
        return this.onEmit().pipe(
            filter((event: T) => event.type === eventType),
            map((event: T) => event)
        );
    }
}

export enum MESSAGE_EVENT_TYPES_ENUM {
    OPEN_NAV = 'openNavigation',
}

export namespace fromBusEvents {
    export class OpenNav implements MessageBusEvent {
        public type: MESSAGE_EVENT_TYPES_ENUM.OPEN_NAV = MESSAGE_EVENT_TYPES_ENUM.OPEN_NAV;
    }
}

export type MessageEventType = fromBusEvents.OpenNav;

@Injectable({ providedIn: 'root' })
export class MessageBusService extends MessageBus<MessageEventType, MESSAGE_EVENT_TYPES_ENUM> {}
