import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, of, Subject, tap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import {
    Account,
    HostProfile,
    Mode,
    PersonalHostProfile,
    RequestContext,
    Team,
    TeamMemberHostProfile,
    UserMetadataStateModel,
    UserStateModel
} from '../models';
import { HostRepository } from '../repository/host.repository';
import { TeamRepository } from '../repository/team.repository';
import { GetAccountMode, getContext } from '../helpers';


const accountStorageKey: string = 'qlickContext';

function compareAccount(a: Account | null, b: Account | null): boolean {
    return !!a && !!b && a.id === b.id && a.slug === b.slug;
}

@Injectable({ providedIn: 'root' })
export class AdminModeService implements OnDestroy {
    /** Allowed accounts */
    public readonly allowedAccounts$: Observable<Account[]>;
    /** Current account */
    public readonly account$: Observable<Account>;
    /** For create new account */
    public readonly newAccount$: Observable<Account | null>;
    /** Current hostProfile */
    public readonly hostProfile$: Observable<HostProfile>;
    /** Current mode */
    public readonly mode$: Observable<Mode | null>;

    // TODO: temporary solution, should be removed after refactoring
    public readonly user$: Observable<UserStateModel | null>;
    public readonly userMetaData$: Observable<UserMetadataStateModel | null>;

    private readonly allowedAccountsSource: BehaviorSubject<Account[]> = new BehaviorSubject<Account[]>([]);
    private readonly accountSource: BehaviorSubject<Account | null | undefined> = new BehaviorSubject<Account | null | undefined>(undefined);
    private readonly setAccountExternallySource: Subject<UserStateModel | null> = new Subject<UserStateModel | null>();

    private readonly userSource: BehaviorSubject<UserStateModel | null> = new BehaviorSubject<UserStateModel | null>(null);
    private readonly userMetaDataSource: BehaviorSubject<UserMetadataStateModel | null> =
        new BehaviorSubject<UserMetadataStateModel | null>(null);

    private readonly destroySource: Subject<void> = new Subject<void>();

    constructor(
        private readonly hostRepo: HostRepository,
        private readonly teamRepo: TeamRepository,
    ) {
        this.user$ = this.userSource.asObservable().pipe(
            distinctUntilChanged((x: UserStateModel | null, y: UserStateModel | null) => {
                return (!!x && !!y && JSON.stringify(x) === JSON.stringify(y));
            }),
            shareReplay(1)
        );
        this.userMetaData$ = this.userMetaDataSource.asObservable().pipe(
            distinctUntilChanged((x: UserMetadataStateModel | null, y: UserMetadataStateModel | null) => {
                return (!!x && !!y && JSON.stringify(x) === JSON.stringify(y));
            }),
            shareReplay(1)
        );
        this.allowedAccounts$ = this.allowedAccountsSource.asObservable();
        this.account$ = this.accountSource.asObservable().pipe(
            filter((account: Account | null | undefined): account is Account => !!account),
            distinctUntilChanged((x: Account, y: Account) => {
                return (!!x && !!y && JSON.stringify(x) === JSON.stringify(y));
            }),
            shareReplay(1)
        );
        this.newAccount$ = this.accountSource.asObservable().pipe(
            filter((account: Account | null | undefined) => typeof account === undefined || account === null || !!account),
            distinctUntilChanged((x: Account | null, y: Account | null) => {
                return (!!x && !!y && JSON.stringify(x) === JSON.stringify(y));
            }),
            shareReplay(1)
        );
        this.mode$ = this.accountSource.pipe(
            filter(Boolean),
            map((account: Account) => GetAccountMode(account))
        );
        this.hostProfile$ = this.accountSource.pipe(
            filter((account: Account | null | undefined) => account instanceof PersonalHostProfile || account instanceof TeamMemberHostProfile)
        ) as Observable<HostProfile>;

        this.setAccountExternallySource.pipe(
            switchMap((user: UserStateModel | null) => {
                return (user?.id ? this.DataLoader : of(null)) as Observable<Account[]>;
            }),
            filter((result: Account[]): boolean => result instanceof Array),
            takeUntil(this.destroySource)
        ).subscribe((allowedAccounts: Account[]): void => {
            this.allowedAccountsSource.next(allowedAccounts);
            this.currentAccount = this.DefaultCurrentAccount;
        });
    }

    private get DataLoader(): Observable<Account[]> {
        return forkJoin([
            this.hostRepo.getAllowedHosts(),
            this.teamRepo.getTeams()
        ]).pipe(
            map(([hosts, teams]: [HostProfile[], Team[]]) => [...hosts, ...teams]),
            shareReplay(1)
        );
    }

    public ngOnDestroy(): void {
        this.destroySource.next();
        this.destroySource.complete();
    }

    public set currentAccount(account: Account | null) {
        if (account && this.allowedAccountsSource.value.length === 0) {
            this.allowedAccountsSource.next([account]);
        }

        if (account && this.allowedAccountsSource.value.includes(account)) {
            localStorage.setItem(accountStorageKey, JSON.stringify(getContext(account)));
            this.accountSource.next(account);
        }

        if (!account) {
            this.accountSource.next(account);
        }
    }

    public setUser(user: UserStateModel | null): void {
        this.userSource.next(user);
        this.setAccountByUser(user);
    }

    public setAccountByUser(user: UserStateModel | null): void {
        this.setAccountExternallySource.next(user);
    }

    public setUserMetaData(userMetaData: UserMetadataStateModel | null): void {
        this.userMetaDataSource.next(userMetaData);
    }

    public UpdateCurrentAccount(changedAccount: Account): void {
        const indexOfAccount: number = this.allowedAccountsSource.value.findIndex((account: Account) => compareAccount(account, changedAccount));
        if (indexOfAccount >= 0 && compareAccount(this.accountSource.value, changedAccount)) {
            const newHosts: Account[] = this.allowedAccountsSource.value.slice();
            newHosts.splice(indexOfAccount, 1, changedAccount);
            this.allowedAccountsSource.next(newHosts);
            this.currentAccount = changedAccount;
        }
    }

    private get DefaultCurrentAccount(): Account | null {
        const allowedAccounts: Account[] = this.allowedAccountsSource.value;
        const firstAccount: Account | null = (allowedAccounts?.length > 0 ? allowedAccounts[0] : null);
        const qlickContextStr: string | null = localStorage.getItem(accountStorageKey);
        let qlickContext: RequestContext | null = null;

        if (qlickContextStr) {
            qlickContext = JSON.parse(qlickContextStr);
        }

        if (qlickContext) {
            return allowedAccounts.find((account: Account): boolean | undefined => {
                if ('team' in qlickContext! && account instanceof Team) {
                    return account.id === qlickContext.team;
                } else if ('profile' in qlickContext! && !(account instanceof Team)) {
                    return account.id === qlickContext.profile;
                } else {
                    return undefined;
                }
            }) || firstAccount;
        } else {
            return firstAccount;
        }
    }
}
