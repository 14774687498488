import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { TeamRepository, TeamMapper, Team, TeamRole  } from '@common/util';
import { GetTeamGQL, GetAllTeamsGQL } from 'libs/common/util/src/lib/models/types-generated';

@Injectable()
export class TeamGraphQLRepository extends TeamRepository {
    private mapper: TeamMapper = new TeamMapper();

    constructor(private readonly getGQL: GetTeamGQL, private readonly getAllGQL: GetAllTeamsGQL) {
        super();
    }

    public getTeam(id: string): Observable<Team> {
        return this.getGQL.fetch({ id }).pipe(
            map(answer => this.mapper.mapFrom(answer.data.team))
        );
    }

    public getTeams(): Observable<Team[]> {
        return this.getAllGQL.fetch().pipe(
            map(answer => answer.data.teams),
            map(teams => teams.edges.map(edge => {
                return this.mapper.mapFrom(edge.node);
            }).filter(team => {
                return team.currentUserRole === TeamRole.Admin || team.currentUserRole === TeamRole.Owner;
            }))
        );
    }
}
