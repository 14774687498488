import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: Date;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
}

export enum CalendarAccessRole {
  FreeBusyReader = 'freeBusyReader',
  None = 'none',
  Owner = 'owner',
  Reader = 'reader',
  Writer = 'writer'
}

/** Создание запроса на оплату подписки */
export interface CreatePaymentRequest {
  __typename?: 'CreatePaymentRequest';
  result?: Maybe<CreatePaymentRequestResult>;
}

export interface CreatePaymentRequestFailure {
  __typename?: 'CreatePaymentRequestFailure';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export type CreatePaymentRequestResult = CreatePaymentRequestFailure | CreatePaymentRequestSuccess;

export interface CreatePaymentRequestSuccess {
  __typename?: 'CreatePaymentRequestSuccess';
  amount?: Maybe<Scalars['Int']>;
  plan?: Maybe<PaymentPlan>;
  token?: Maybe<Scalars['String']>;
}

export interface DayScheduleEntry {
  __typename?: 'DayScheduleEntry';
  /** Начало интервала */
  begin?: Maybe<Scalars['Time']>;
  /** Конец интервала */
  end?: Maybe<Scalars['Time']>;
}

export interface DayScheduleEntryData {
  /** Начало интервала */
  begin?: InputMaybe<Scalars['Time']>;
  /** Конец интервала */
  end?: InputMaybe<Scalars['Time']>;
}

/** Отключение интеграции с Google */
export interface DeleteGoogleIntegration {
  __typename?: 'DeleteGoogleIntegration';
  success?: Maybe<Scalars['Boolean']>;
}

export interface GoogleCalendar {
  __typename?: 'GoogleCalendar';
  accessRole?: Maybe<CalendarAccessRole>;
  backgroundColor?: Maybe<Scalars['String']>;
  createEvent?: Maybe<Scalars['Boolean']>;
  foregroundColor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  includeBusy?: Maybe<Scalars['Boolean']>;
  summary?: Maybe<Scalars['String']>;
}

export interface GoogleIntegration {
  __typename?: 'GoogleIntegration';
  calendarAccess?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
}

export interface HostProfile {
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleCalendars?: Maybe<Array<Maybe<GoogleCalendar>>>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  microsoftCalendars?: Maybe<Array<Maybe<MicrosoftCalendar>>>;
  timezone?: Maybe<Scalars['String']>;
  zoomSettings?: Maybe<ZoomSettings>;
}

export interface HostProfileNode {
  __typename?: 'HostProfileNode';
  node?: Maybe<HostProfile>;
}

export interface HostProfiles {
  __typename?: 'HostProfiles';
  edges?: Maybe<Array<Maybe<HostProfileNode>>>;
}

export interface Location {
  __typename?: 'Location';
  contact?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface MicrosoftCalendar {
  __typename?: 'MicrosoftCalendar';
  canEdit?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  createEvent?: Maybe<Scalars['Boolean']>;
  haveMeetingProvider?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  includeBusy?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
}

export interface MicrosoftCalendarData {
  createEvent?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  includeBusy?: InputMaybe<Scalars['Boolean']>;
}

export interface MicrosoftIntegration {
  __typename?: 'MicrosoftIntegration';
  calendarAccess?: Maybe<Scalars['Boolean']>;
}

export interface ParticipantProfile {
  __typename?: 'ParticipantProfile';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface PaymentPlan {
  __typename?: 'PaymentPlan';
  activityTypeLimit?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface PaymentPlanOption {
  __typename?: 'PaymentPlanOption';
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
}

export interface PersonalHostProfile extends HostProfile {
  __typename?: 'PersonalHostProfile';
  activePlan?: Maybe<PaymentPlan>;
  avatar?: Maybe<Scalars['String']>;
  cloudpaymentsApiPassword?: Maybe<Scalars['String']>;
  cloudpaymentsPublicId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  googleCalendars?: Maybe<Array<Maybe<GoogleCalendar>>>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  microsoftCalendars?: Maybe<Array<Maybe<MicrosoftCalendar>>>;
  phone?: Maybe<Scalars['String']>;
  plan?: Maybe<PaymentPlan>;
  planOption?: Maybe<PaymentPlanOption>;
  slug: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  zoomSettings?: Maybe<ZoomSettings>;
}

export interface ProfileCreateError {
  __typename?: 'ProfileCreateError';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export type ProfileCreateResult = ProfileCreateError | ProfileCreateSuccess;

export interface ProfileCreateSuccess {
  __typename?: 'ProfileCreateSuccess';
  hostProfile?: Maybe<PersonalHostProfile>;
}

export interface ProfileUpdateError {
  __typename?: 'ProfileUpdateError';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export type ProfileUpdateResult = ProfileUpdateError | ProfileUpdateSuccess;

export interface ProfileUpdateSuccess {
  __typename?: 'ProfileUpdateSuccess';
  hostProfile?: Maybe<HostProfile>;
}

export interface RootMutation {
  __typename?: 'RootMutation';
  /** Создание запроса на оплату подписки */
  createPaymentRequest?: Maybe<CreatePaymentRequest>;
  /** Отключение интеграции с Google */
  deleteGoogleIntegration?: Maybe<DeleteGoogleIntegration>;
  /** Обновление настроек профиля хоста */
  updateHostProfile?: Maybe<UpdateHostProfile>;
  /** Обновление данных пользователя */
  updateUser?: Maybe<UpdateUser>;
}


export interface RootMutationCreatePaymentRequestArgs {
  option: Scalars['Int'];
}


export interface RootMutationUpdateHostProfileArgs {
  data?: InputMaybe<UpdateHostProfileData>;
  profile: Scalars['ID'];
}


export interface RootMutationUpdateUserArgs {
  data: UserData;
}

export interface RootQuery {
  __typename?: 'RootQuery';
  hostProfile?: Maybe<HostProfile>;
  hostProfiles?: Maybe<HostProfiles>;
  integration?: Maybe<UserIntegration>;
  team?: Maybe<Team>;
  teams?: Maybe<Teams>;
  user?: Maybe<User>;
}


export interface RootQueryHostProfileArgs {
  id?: InputMaybe<Scalars['ID']>;
}


export interface RootQueryTeamArgs {
  id?: InputMaybe<Scalars['ID']>;
}

export interface Team {
  __typename?: 'Team';
  currentUserRole: TeamRole;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  member?: Maybe<HostProfile>;
  members?: Maybe<TeamMembers>;
  name: Scalars['String'];
  slug: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
}


export interface TeamMemberArgs {
  id: Scalars['ID'];
}

export interface TeamEdge {
  __typename?: 'TeamEdge';
  node: Team;
}

export interface TeamMemberHostProfile extends HostProfile {
  __typename?: 'TeamMemberHostProfile';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleCalendars?: Maybe<Array<Maybe<GoogleCalendar>>>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  microsoftCalendars?: Maybe<Array<Maybe<MicrosoftCalendar>>>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  team: Team;
  teamRole: TeamRole;
  timezone?: Maybe<Scalars['String']>;
  yandexCalendarsCount?: Maybe<Scalars['Int']>;
  zoomSettings?: Maybe<ZoomSettings>;
}

export interface TeamMemberNode {
  __typename?: 'TeamMemberNode';
  node?: Maybe<HostProfile>;
}

export interface TeamMembers {
  __typename?: 'TeamMembers';
  edges?: Maybe<Array<Maybe<TeamMemberNode>>>;
}

export enum TeamRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export interface Teams {
  __typename?: 'Teams';
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
}

/** Обновление настроек профиля хоста */
export interface UpdateHostProfile {
  __typename?: 'UpdateHostProfile';
  result?: Maybe<ProfileUpdateResult>;
}

export interface UpdateHostProfileData {
  /** Аватар пользователя в base64 */
  avatar?: InputMaybe<Scalars['String']>;
  cloudpaymentsApiPassword?: InputMaybe<Scalars['String']>;
  cloudpaymentsPublicId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  googleCalendars?: InputMaybe<Array<InputMaybe<MicrosoftCalendarData>>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  microsoftCalendars?: InputMaybe<Array<InputMaybe<MicrosoftCalendarData>>>;
  phone?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  zoomSettings?: InputMaybe<ZoomSettingsData>;
}

/** Обновление данных пользователя */
export interface UpdateUser {
  __typename?: 'UpdateUser';
  result?: Maybe<UserDataUpdateSuccess>;
}

export interface User {
  __typename?: 'User';
  carrotquestIdHash?: Maybe<Scalars['String']>;
  crispEmailHash?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasUsablePassword?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}

export interface UserData {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}

export interface UserDataUpdateSuccess {
  __typename?: 'UserDataUpdateSuccess';
  user?: Maybe<User>;
}

export interface UserIntegration {
  __typename?: 'UserIntegration';
  google?: Maybe<GoogleIntegration>;
  microsoft?: Maybe<MicrosoftIntegration>;
  zoom?: Maybe<Scalars['Boolean']>;
}

export interface ZoomSettings {
  __typename?: 'ZoomSettings';
  createMeeting?: Maybe<Scalars['Boolean']>;
}

export interface ZoomSettingsData {
  createMeeting: Scalars['Boolean'];
}

export type GetGoogleIntegrationQueryVariables = Exact<{
  profile: Scalars['ID'];
}>;


export type GetGoogleIntegrationQuery = { __typename?: 'RootQuery', integration?: { __typename?: 'UserIntegration', google?: { __typename?: 'GoogleIntegration', picture?: string | null, email?: string | null, calendarAccess?: boolean | null } | null } | null, hostProfile?: { __typename?: 'PersonalHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | { __typename?: 'TeamMemberHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | null };

export type FullGoogleIntegrationFragment = { __typename?: 'GoogleIntegration', picture?: string | null, email?: string | null, calendarAccess?: boolean | null };

export type GCalendarInfoFragment = { __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null };

export type SetGoogleEventCalendarMutationVariables = Exact<{
  profile: Scalars['ID'];
  calendarId: Scalars['Int'];
}>;


export type SetGoogleEventCalendarMutation = { __typename?: 'RootMutation', updateHostProfile?: { __typename?: 'UpdateHostProfile', result?: { __typename?: 'ProfileUpdateError', code?: string | null, description?: string | null } | { __typename?: 'ProfileUpdateSuccess', hostProfile?: { __typename?: 'PersonalHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | { __typename?: 'TeamMemberHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | null } | null } | null };

export type SetGoogleBlockerCalendarMutationVariables = Exact<{
  profile: Scalars['ID'];
  googleBloks?: InputMaybe<Array<InputMaybe<MicrosoftCalendarData>> | InputMaybe<MicrosoftCalendarData>>;
}>;


export type SetGoogleBlockerCalendarMutation = { __typename?: 'RootMutation', updateHostProfile?: { __typename?: 'UpdateHostProfile', result?: { __typename?: 'ProfileUpdateError', code?: string | null, description?: string | null } | { __typename?: 'ProfileUpdateSuccess', hostProfile?: { __typename?: 'PersonalHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | { __typename?: 'TeamMemberHostProfile', googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, name?: string | null, color?: string | null } | null> | null } | null } | null } | null };

export type SetMsEventCalendarMutationVariables = Exact<{
  profile: Scalars['ID'];
  calendarId: Scalars['Int'];
}>;


export type SetMsEventCalendarMutation = { __typename?: 'RootMutation', updateHostProfile?: { __typename?: 'UpdateHostProfile', result?: { __typename?: 'ProfileUpdateError', code?: string | null, description?: string | null } | { __typename?: 'ProfileUpdateSuccess', hostProfile?: { __typename?: 'PersonalHostProfile', microsoftCalendars?: Array<{ __typename?: 'MicrosoftCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, color?: string | null, name?: string | null } | null> | null } | { __typename?: 'TeamMemberHostProfile', microsoftCalendars?: Array<{ __typename?: 'MicrosoftCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, color?: string | null, name?: string | null } | null> | null } | null } | null } | null };

export type MsCalendarInfoFragment = { __typename?: 'MicrosoftCalendar', id?: number | null, includeBusy?: boolean | null, createEvent?: boolean | null, color?: string | null, name?: string | null };

export type FullMicrosoftIntegrationFragment = { __typename?: 'MicrosoftIntegration', calendarAccess?: boolean | null };

export type GetHostQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHostQuery = { __typename?: 'RootQuery', hostProfile?: { __typename?: 'PersonalHostProfile', slug: string, phone?: string | null, expires?: Date | null, cloudpaymentsApiPassword?: string | null, cloudpaymentsPublicId?: string | null, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, plan?: { __typename?: 'PaymentPlan', id?: number | null, name?: string | null, currency?: string | null, activityTypeLimit?: number | null } | null, planOption?: { __typename?: 'PaymentPlanOption', id?: number | null, duration?: number | null, price?: number | null } | null } | { __typename?: 'TeamMemberHostProfile', phone?: string | null, teamRole: TeamRole, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, team: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole }, googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null } | null> | null } | null };

export type GetAllowedHostQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllowedHostQuery = { __typename?: 'RootQuery', hostProfiles?: { __typename?: 'HostProfiles', edges?: Array<{ __typename?: 'HostProfileNode', node?: { __typename?: 'PersonalHostProfile', slug: string, phone?: string | null, expires?: Date | null, cloudpaymentsApiPassword?: string | null, cloudpaymentsPublicId?: string | null, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, plan?: { __typename?: 'PaymentPlan', id?: number | null, name?: string | null, currency?: string | null, activityTypeLimit?: number | null } | null, planOption?: { __typename?: 'PaymentPlanOption', id?: number | null, duration?: number | null, price?: number | null } | null } | { __typename?: 'TeamMemberHostProfile', phone?: string | null, teamRole: TeamRole, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, team: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole }, googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null } | null> | null } | null } | null> | null } | null };

export type GetTeamHostProfilesQueryVariables = Exact<{
  team?: InputMaybe<Scalars['ID']>;
}>;


export type GetTeamHostProfilesQuery = { __typename?: 'RootQuery', team?: { __typename?: 'Team', members?: { __typename?: 'TeamMembers', edges?: Array<{ __typename?: 'TeamMemberNode', node?: { __typename?: 'PersonalHostProfile', slug: string, phone?: string | null, expires?: Date | null, cloudpaymentsApiPassword?: string | null, cloudpaymentsPublicId?: string | null, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, plan?: { __typename?: 'PaymentPlan', id?: number | null, name?: string | null, currency?: string | null, activityTypeLimit?: number | null } | null, planOption?: { __typename?: 'PaymentPlanOption', id?: number | null, duration?: number | null, price?: number | null } | null } | { __typename?: 'TeamMemberHostProfile', phone?: string | null, teamRole: TeamRole, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, team: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole }, googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null } | null> | null } | null } | null> | null } | null } | null };

export type UpdateHostMutationVariables = Exact<{
  host?: InputMaybe<UpdateHostProfileData>;
  id: Scalars['ID'];
}>;


export type UpdateHostMutation = { __typename?: 'RootMutation', updateHostProfile?: { __typename?: 'UpdateHostProfile', result?: { __typename: 'ProfileUpdateError', code?: string | null, description?: string | null } | { __typename: 'ProfileUpdateSuccess', hostProfile?: { __typename?: 'PersonalHostProfile', slug: string, phone?: string | null, expires?: Date | null, cloudpaymentsApiPassword?: string | null, cloudpaymentsPublicId?: string | null, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, plan?: { __typename?: 'PaymentPlan', id?: number | null, name?: string | null, currency?: string | null, activityTypeLimit?: number | null } | null, planOption?: { __typename?: 'PaymentPlanOption', id?: number | null, duration?: number | null, price?: number | null } | null } | { __typename?: 'TeamMemberHostProfile', phone?: string | null, teamRole: TeamRole, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, team: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole }, googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null } | null> | null } | null } | null } | null };

type FullHost_PersonalHostProfile_Fragment = { __typename?: 'PersonalHostProfile', slug: string, phone?: string | null, expires?: Date | null, cloudpaymentsApiPassword?: string | null, cloudpaymentsPublicId?: string | null, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, plan?: { __typename?: 'PaymentPlan', id?: number | null, name?: string | null, currency?: string | null, activityTypeLimit?: number | null } | null, planOption?: { __typename?: 'PaymentPlanOption', id?: number | null, duration?: number | null, price?: number | null } | null };

type FullHost_TeamMemberHostProfile_Fragment = { __typename?: 'TeamMemberHostProfile', phone?: string | null, teamRole: TeamRole, id: string, timezone?: string | null, jobTitle?: string | null, description?: string | null, avatar?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, team: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole }, googleCalendars?: Array<{ __typename?: 'GoogleCalendar', id?: number | null } | null> | null };

export type FullHostFragment = FullHost_PersonalHostProfile_Fragment | FullHost_TeamMemberHostProfile_Fragment;

export type TeamShortFragment = { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole };

export type DayFragment = { __typename?: 'DayScheduleEntry', begin?: any | null, end?: any | null };

export type LoadIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadIntegrationsQuery = { __typename?: 'RootQuery', integration?: { __typename?: 'UserIntegration', zoom?: boolean | null } | null };

export type IntegrationsFragment = { __typename?: 'UserIntegration', zoom?: boolean | null, google?: { __typename?: 'GoogleIntegration', picture?: string | null, email?: string | null, calendarAccess?: boolean | null } | null, microsoft?: { __typename?: 'MicrosoftIntegration', calendarAccess?: boolean | null } | null };

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTeamQuery = { __typename?: 'RootQuery', team?: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole } | null };

export type GetAllTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTeamsQuery = { __typename?: 'RootQuery', teams?: { __typename?: 'Teams', edges?: Array<{ __typename?: 'TeamEdge', node: { __typename?: 'Team', id: string, name: string, slug: string, timezone?: string | null, currentUserRole: TeamRole } } | null> | null } | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'RootQuery', user?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, crispEmailHash?: string | null, hasUsablePassword?: boolean | null } | null };

export type UpdateUserMutationVariables = Exact<{
  user: UserData;
}>;


export type UpdateUserMutation = { __typename?: 'RootMutation', updateUser?: { __typename?: 'UpdateUser', result?: { __typename: 'UserDataUpdateSuccess', user?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, crispEmailHash?: string | null, hasUsablePassword?: boolean | null } | null } | null } | null };

export type FullUserFragment = { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, crispEmailHash?: string | null, hasUsablePassword?: boolean | null };

export type DeleteGoogleIntegrationMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteGoogleIntegrationMutation = { __typename?: 'RootMutation', deleteGoogleIntegration?: { __typename?: 'DeleteGoogleIntegration', success?: boolean | null } | null };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  CalendarAccessRole: CalendarAccessRole;
  CreatePaymentRequest: ResolverTypeWrapper<Omit<CreatePaymentRequest, 'result'> & { result?: Maybe<ResolversTypes['CreatePaymentRequestResult']> }>;
  CreatePaymentRequestFailure: ResolverTypeWrapper<CreatePaymentRequestFailure>;
  String: ResolverTypeWrapper<Scalars['String']>;
  CreatePaymentRequestResult: ResolversTypes['CreatePaymentRequestFailure'] | ResolversTypes['CreatePaymentRequestSuccess'];
  CreatePaymentRequestSuccess: ResolverTypeWrapper<CreatePaymentRequestSuccess>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DayScheduleEntry: ResolverTypeWrapper<DayScheduleEntry>;
  DayScheduleEntryData: DayScheduleEntryData;
  DeleteGoogleIntegration: ResolverTypeWrapper<DeleteGoogleIntegration>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  GoogleCalendar: ResolverTypeWrapper<GoogleCalendar>;
  GoogleIntegration: ResolverTypeWrapper<GoogleIntegration>;
  HostProfile: ResolversTypes['PersonalHostProfile'] | ResolversTypes['TeamMemberHostProfile'];
  ID: ResolverTypeWrapper<Scalars['ID']>;
  HostProfileNode: ResolverTypeWrapper<HostProfileNode>;
  HostProfiles: ResolverTypeWrapper<HostProfiles>;
  JSONString: ResolverTypeWrapper<Scalars['JSONString']>;
  Location: ResolverTypeWrapper<Location>;
  MicrosoftCalendar: ResolverTypeWrapper<MicrosoftCalendar>;
  MicrosoftCalendarData: MicrosoftCalendarData;
  MicrosoftIntegration: ResolverTypeWrapper<MicrosoftIntegration>;
  ParticipantProfile: ResolverTypeWrapper<ParticipantProfile>;
  PaymentPlan: ResolverTypeWrapper<PaymentPlan>;
  PaymentPlanOption: ResolverTypeWrapper<PaymentPlanOption>;
  PersonalHostProfile: ResolverTypeWrapper<PersonalHostProfile>;
  ProfileCreateError: ResolverTypeWrapper<ProfileCreateError>;
  ProfileCreateResult: ResolversTypes['ProfileCreateError'] | ResolversTypes['ProfileCreateSuccess'];
  ProfileCreateSuccess: ResolverTypeWrapper<ProfileCreateSuccess>;
  ProfileUpdateError: ResolverTypeWrapper<ProfileUpdateError>;
  ProfileUpdateResult: ResolversTypes['ProfileUpdateError'] | ResolversTypes['ProfileUpdateSuccess'];
  ProfileUpdateSuccess: ResolverTypeWrapper<ProfileUpdateSuccess>;
  RootMutation: ResolverTypeWrapper<{}>;
  RootQuery: ResolverTypeWrapper<{}>;
  Team: ResolverTypeWrapper<Team>;
  TeamEdge: ResolverTypeWrapper<TeamEdge>;
  TeamMemberHostProfile: ResolverTypeWrapper<TeamMemberHostProfile>;
  TeamMemberNode: ResolverTypeWrapper<TeamMemberNode>;
  TeamMembers: ResolverTypeWrapper<TeamMembers>;
  TeamRole: TeamRole;
  Teams: ResolverTypeWrapper<Teams>;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  UpdateHostProfile: ResolverTypeWrapper<Omit<UpdateHostProfile, 'result'> & { result?: Maybe<ResolversTypes['ProfileUpdateResult']> }>;
  UpdateHostProfileData: UpdateHostProfileData;
  UpdateUser: ResolverTypeWrapper<UpdateUser>;
  User: ResolverTypeWrapper<User>;
  UserData: UserData;
  UserDataUpdateSuccess: ResolverTypeWrapper<UserDataUpdateSuccess>;
  UserIntegration: ResolverTypeWrapper<UserIntegration>;
  ZoomSettings: ResolverTypeWrapper<ZoomSettings>;
  ZoomSettingsData: ZoomSettingsData;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  CreatePaymentRequest: Omit<CreatePaymentRequest, 'result'> & { result?: Maybe<ResolversParentTypes['CreatePaymentRequestResult']> };
  CreatePaymentRequestFailure: CreatePaymentRequestFailure;
  String: Scalars['String'];
  CreatePaymentRequestResult: ResolversParentTypes['CreatePaymentRequestFailure'] | ResolversParentTypes['CreatePaymentRequestSuccess'];
  CreatePaymentRequestSuccess: CreatePaymentRequestSuccess;
  Int: Scalars['Int'];
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  DayScheduleEntry: DayScheduleEntry;
  DayScheduleEntryData: DayScheduleEntryData;
  DeleteGoogleIntegration: DeleteGoogleIntegration;
  Boolean: Scalars['Boolean'];
  GoogleCalendar: GoogleCalendar;
  GoogleIntegration: GoogleIntegration;
  HostProfile: ResolversParentTypes['PersonalHostProfile'] | ResolversParentTypes['TeamMemberHostProfile'];
  ID: Scalars['ID'];
  HostProfileNode: HostProfileNode;
  HostProfiles: HostProfiles;
  JSONString: Scalars['JSONString'];
  Location: Location;
  MicrosoftCalendar: MicrosoftCalendar;
  MicrosoftCalendarData: MicrosoftCalendarData;
  MicrosoftIntegration: MicrosoftIntegration;
  ParticipantProfile: ParticipantProfile;
  PaymentPlan: PaymentPlan;
  PaymentPlanOption: PaymentPlanOption;
  PersonalHostProfile: PersonalHostProfile;
  ProfileCreateError: ProfileCreateError;
  ProfileCreateResult: ResolversParentTypes['ProfileCreateError'] | ResolversParentTypes['ProfileCreateSuccess'];
  ProfileCreateSuccess: ProfileCreateSuccess;
  ProfileUpdateError: ProfileUpdateError;
  ProfileUpdateResult: ResolversParentTypes['ProfileUpdateError'] | ResolversParentTypes['ProfileUpdateSuccess'];
  ProfileUpdateSuccess: ProfileUpdateSuccess;
  RootMutation: {};
  RootQuery: {};
  Team: Team;
  TeamEdge: TeamEdge;
  TeamMemberHostProfile: TeamMemberHostProfile;
  TeamMemberNode: TeamMemberNode;
  TeamMembers: TeamMembers;
  Teams: Teams;
  Time: Scalars['Time'];
  UpdateHostProfile: Omit<UpdateHostProfile, 'result'> & { result?: Maybe<ResolversParentTypes['ProfileUpdateResult']> };
  UpdateHostProfileData: UpdateHostProfileData;
  UpdateUser: UpdateUser;
  User: User;
  UserData: UserData;
  UserDataUpdateSuccess: UserDataUpdateSuccess;
  UserIntegration: UserIntegration;
  ZoomSettings: ZoomSettings;
  ZoomSettingsData: ZoomSettingsData;
};

export type NgModuleDirectiveArgs = {
  module: Scalars['String'];
};

export type NgModuleDirectiveResolver<Result, Parent, ContextType = any, Args = NgModuleDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type NamedClientDirectiveArgs = {
  name: Scalars['String'];
};

export type NamedClientDirectiveResolver<Result, Parent, ContextType = any, Args = NamedClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CreatePaymentRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePaymentRequest'] = ResolversParentTypes['CreatePaymentRequest']> = {
  result?: Resolver<Maybe<ResolversTypes['CreatePaymentRequestResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePaymentRequestFailureResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePaymentRequestFailure'] = ResolversParentTypes['CreatePaymentRequestFailure']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePaymentRequestResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePaymentRequestResult'] = ResolversParentTypes['CreatePaymentRequestResult']> = {
  __resolveType: TypeResolveFn<'CreatePaymentRequestFailure' | 'CreatePaymentRequestSuccess', ParentType, ContextType>;
};

export type CreatePaymentRequestSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePaymentRequestSuccess'] = ResolversParentTypes['CreatePaymentRequestSuccess']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['PaymentPlan']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DayScheduleEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DayScheduleEntry'] = ResolversParentTypes['DayScheduleEntry']> = {
  begin?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteGoogleIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteGoogleIntegration'] = ResolversParentTypes['DeleteGoogleIntegration']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleCalendarResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleCalendar'] = ResolversParentTypes['GoogleCalendar']> = {
  accessRole?: Resolver<Maybe<ResolversTypes['CalendarAccessRole']>, ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  foregroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  includeBusy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleIntegration'] = ResolversParentTypes['GoogleIntegration']> = {
  calendarAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostProfile'] = ResolversParentTypes['HostProfile']> = {
  __resolveType: TypeResolveFn<'PersonalHostProfile' | 'TeamMemberHostProfile', ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['GoogleCalendar']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoftCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['MicrosoftCalendar']>>>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomSettings?: Resolver<Maybe<ResolversTypes['ZoomSettings']>, ParentType, ContextType>;
};

export type HostProfileNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostProfileNode'] = ResolversParentTypes['HostProfileNode']> = {
  node?: Resolver<Maybe<ResolversTypes['HostProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostProfilesResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostProfiles'] = ResolversParentTypes['HostProfiles']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['HostProfileNode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonStringScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONString'], any> {
  name: 'JSONString';
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  contact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MicrosoftCalendarResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicrosoftCalendar'] = ResolversParentTypes['MicrosoftCalendar']> = {
  canEdit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  haveMeetingProvider?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  includeBusy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MicrosoftIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicrosoftIntegration'] = ResolversParentTypes['MicrosoftIntegration']> = {
  calendarAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParticipantProfile'] = ResolversParentTypes['ParticipantProfile']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlan'] = ResolversParentTypes['PaymentPlan']> = {
  activityTypeLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentPlanOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentPlanOption'] = ResolversParentTypes['PaymentPlanOption']> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonalHostProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalHostProfile'] = ResolversParentTypes['PersonalHostProfile']> = {
  activePlan?: Resolver<Maybe<ResolversTypes['PaymentPlan']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cloudpaymentsApiPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cloudpaymentsPublicId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['GoogleCalendar']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoftCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['MicrosoftCalendar']>>>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['PaymentPlan']>, ParentType, ContextType>;
  planOption?: Resolver<Maybe<ResolversTypes['PaymentPlanOption']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomSettings?: Resolver<Maybe<ResolversTypes['ZoomSettings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileCreateErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileCreateError'] = ResolversParentTypes['ProfileCreateError']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileCreateResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileCreateResult'] = ResolversParentTypes['ProfileCreateResult']> = {
  __resolveType: TypeResolveFn<'ProfileCreateError' | 'ProfileCreateSuccess', ParentType, ContextType>;
};

export type ProfileCreateSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileCreateSuccess'] = ResolversParentTypes['ProfileCreateSuccess']> = {
  hostProfile?: Resolver<Maybe<ResolversTypes['PersonalHostProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileUpdateErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileUpdateError'] = ResolversParentTypes['ProfileUpdateError']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileUpdateResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileUpdateResult'] = ResolversParentTypes['ProfileUpdateResult']> = {
  __resolveType: TypeResolveFn<'ProfileUpdateError' | 'ProfileUpdateSuccess', ParentType, ContextType>;
};

export type ProfileUpdateSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileUpdateSuccess'] = ResolversParentTypes['ProfileUpdateSuccess']> = {
  hostProfile?: Resolver<Maybe<ResolversTypes['HostProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RootMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootMutation'] = ResolversParentTypes['RootMutation']> = {
  createPaymentRequest?: Resolver<Maybe<ResolversTypes['CreatePaymentRequest']>, ParentType, ContextType, RequireFields<RootMutationCreatePaymentRequestArgs, 'option'>>;
  deleteGoogleIntegration?: Resolver<Maybe<ResolversTypes['DeleteGoogleIntegration']>, ParentType, ContextType>;
  updateHostProfile?: Resolver<Maybe<ResolversTypes['UpdateHostProfile']>, ParentType, ContextType, RequireFields<RootMutationUpdateHostProfileArgs, 'profile'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['UpdateUser']>, ParentType, ContextType, RequireFields<RootMutationUpdateUserArgs, 'data'>>;
};

export type RootQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootQuery'] = ResolversParentTypes['RootQuery']> = {
  hostProfile?: Resolver<Maybe<ResolversTypes['HostProfile']>, ParentType, ContextType, Partial<RootQueryHostProfileArgs>>;
  hostProfiles?: Resolver<Maybe<ResolversTypes['HostProfiles']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['UserIntegration']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType, Partial<RootQueryTeamArgs>>;
  teams?: Resolver<Maybe<ResolversTypes['Teams']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  currentUserRole?: Resolver<ResolversTypes['TeamRole'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['HostProfile']>, ParentType, ContextType, RequireFields<TeamMemberArgs, 'id'>>;
  members?: Resolver<Maybe<ResolversTypes['TeamMembers']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamEdge'] = ResolversParentTypes['TeamEdge']> = {
  node?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberHostProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMemberHostProfile'] = ResolversParentTypes['TeamMemberHostProfile']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['GoogleCalendar']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  microsoftCalendars?: Resolver<Maybe<Array<Maybe<ResolversTypes['MicrosoftCalendar']>>>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  teamRole?: Resolver<ResolversTypes['TeamRole'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yandexCalendarsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoomSettings?: Resolver<Maybe<ResolversTypes['ZoomSettings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMemberNode'] = ResolversParentTypes['TeamMemberNode']> = {
  node?: Resolver<Maybe<ResolversTypes['HostProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMembersResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamMembers'] = ResolversParentTypes['TeamMembers']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['TeamMemberNode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Teams'] = ResolversParentTypes['Teams']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['TeamEdge']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export type UpdateHostProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateHostProfile'] = ResolversParentTypes['UpdateHostProfile']> = {
  result?: Resolver<Maybe<ResolversTypes['ProfileUpdateResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUser'] = ResolversParentTypes['UpdateUser']> = {
  result?: Resolver<Maybe<ResolversTypes['UserDataUpdateSuccess']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  carrotquestIdHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crispEmailHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasUsablePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDataUpdateSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDataUpdateSuccess'] = ResolversParentTypes['UserDataUpdateSuccess']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserIntegration'] = ResolversParentTypes['UserIntegration']> = {
  google?: Resolver<Maybe<ResolversTypes['GoogleIntegration']>, ParentType, ContextType>;
  microsoft?: Resolver<Maybe<ResolversTypes['MicrosoftIntegration']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ZoomSettings'] = ResolversParentTypes['ZoomSettings']> = {
  createMeeting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  CreatePaymentRequest?: CreatePaymentRequestResolvers<ContextType>;
  CreatePaymentRequestFailure?: CreatePaymentRequestFailureResolvers<ContextType>;
  CreatePaymentRequestResult?: CreatePaymentRequestResultResolvers<ContextType>;
  CreatePaymentRequestSuccess?: CreatePaymentRequestSuccessResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DayScheduleEntry?: DayScheduleEntryResolvers<ContextType>;
  DeleteGoogleIntegration?: DeleteGoogleIntegrationResolvers<ContextType>;
  GoogleCalendar?: GoogleCalendarResolvers<ContextType>;
  GoogleIntegration?: GoogleIntegrationResolvers<ContextType>;
  HostProfile?: HostProfileResolvers<ContextType>;
  HostProfileNode?: HostProfileNodeResolvers<ContextType>;
  HostProfiles?: HostProfilesResolvers<ContextType>;
  JSONString?: GraphQLScalarType;
  Location?: LocationResolvers<ContextType>;
  MicrosoftCalendar?: MicrosoftCalendarResolvers<ContextType>;
  MicrosoftIntegration?: MicrosoftIntegrationResolvers<ContextType>;
  ParticipantProfile?: ParticipantProfileResolvers<ContextType>;
  PaymentPlan?: PaymentPlanResolvers<ContextType>;
  PaymentPlanOption?: PaymentPlanOptionResolvers<ContextType>;
  PersonalHostProfile?: PersonalHostProfileResolvers<ContextType>;
  ProfileCreateError?: ProfileCreateErrorResolvers<ContextType>;
  ProfileCreateResult?: ProfileCreateResultResolvers<ContextType>;
  ProfileCreateSuccess?: ProfileCreateSuccessResolvers<ContextType>;
  ProfileUpdateError?: ProfileUpdateErrorResolvers<ContextType>;
  ProfileUpdateResult?: ProfileUpdateResultResolvers<ContextType>;
  ProfileUpdateSuccess?: ProfileUpdateSuccessResolvers<ContextType>;
  RootMutation?: RootMutationResolvers<ContextType>;
  RootQuery?: RootQueryResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  TeamEdge?: TeamEdgeResolvers<ContextType>;
  TeamMemberHostProfile?: TeamMemberHostProfileResolvers<ContextType>;
  TeamMemberNode?: TeamMemberNodeResolvers<ContextType>;
  TeamMembers?: TeamMembersResolvers<ContextType>;
  Teams?: TeamsResolvers<ContextType>;
  Time?: GraphQLScalarType;
  UpdateHostProfile?: UpdateHostProfileResolvers<ContextType>;
  UpdateUser?: UpdateUserResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDataUpdateSuccess?: UserDataUpdateSuccessResolvers<ContextType>;
  UserIntegration?: UserIntegrationResolvers<ContextType>;
  ZoomSettings?: ZoomSettingsResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  NgModule?: NgModuleDirectiveResolver<any, any, ContextType>;
  namedClient?: NamedClientDirectiveResolver<any, any, ContextType>;
};

export const GCalendarInfoFragmentDoc = gql`
    fragment GCalendarInfo on GoogleCalendar {
  id
  includeBusy
  createEvent
  name: summary
  color: foregroundColor
}
    `;
export const MsCalendarInfoFragmentDoc = gql`
    fragment MSCalendarInfo on MicrosoftCalendar {
  id
  includeBusy
  createEvent
  color
  name
}
    `;
export const TeamShortFragmentDoc = gql`
    fragment TeamShort on Team {
  id
  name
  slug
  timezone
  currentUserRole
}
    `;
export const FullHostFragmentDoc = gql`
    fragment FullHost on HostProfile {
  id
  timezone
  jobTitle
  description
  avatar
  firstName
  lastName
  email
  ... on PersonalHostProfile {
    slug
    phone
    expires
    cloudpaymentsApiPassword
    cloudpaymentsPublicId
    plan: activePlan {
      id
      name
      currency
      activityTypeLimit
    }
    planOption {
      id
      duration
      price
    }
  }
  ... on TeamMemberHostProfile {
    phone
    team {
      ...TeamShort
    }
    teamRole
    googleCalendars {
      id
    }
  }
}
    ${TeamShortFragmentDoc}`;
export const DayFragmentDoc = gql`
    fragment Day on DayScheduleEntry {
  begin
  end
}
    `;
export const FullGoogleIntegrationFragmentDoc = gql`
    fragment FullGoogleIntegration on GoogleIntegration {
  picture
  email
  calendarAccess
}
    `;
export const FullMicrosoftIntegrationFragmentDoc = gql`
    fragment FullMicrosoftIntegration on MicrosoftIntegration {
  calendarAccess
}
    `;
export const IntegrationsFragmentDoc = gql`
    fragment Integrations on UserIntegration {
  google {
    ...FullGoogleIntegration
  }
  microsoft {
    ...FullMicrosoftIntegration
  }
  zoom
}
    ${FullGoogleIntegrationFragmentDoc}
${FullMicrosoftIntegrationFragmentDoc}`;
export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  firstName
  lastName
  email
  crispEmailHash
  hasUsablePassword
}
    `;
export const GetGoogleIntegrationDocument = gql`
    query GetGoogleIntegration($profile: ID!) {
  integration {
    google {
      ...FullGoogleIntegration
    }
  }
  hostProfile(id: $profile) {
    googleCalendars {
      ...GCalendarInfo
    }
  }
}
    ${FullGoogleIntegrationFragmentDoc}
${GCalendarInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGoogleIntegrationGQL extends Apollo.Query<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables> {
    document = GetGoogleIntegrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetGoogleEventCalendarDocument = gql`
    mutation SetGoogleEventCalendar($profile: ID!, $calendarId: Int!) {
  updateHostProfile(
    profile: $profile
    data: {googleCalendars: [{id: $calendarId, createEvent: true}]}
  ) {
    result {
      ... on ProfileUpdateSuccess {
        hostProfile {
          googleCalendars {
            ...GCalendarInfo
          }
        }
      }
      ... on ProfileUpdateError {
        code
        description
      }
    }
  }
}
    ${GCalendarInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGoogleEventCalendarGQL extends Apollo.Mutation<SetGoogleEventCalendarMutation, SetGoogleEventCalendarMutationVariables> {
    document = SetGoogleEventCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetGoogleBlockerCalendarDocument = gql`
    mutation SetGoogleBlockerCalendar($profile: ID!, $googleBloks: [MicrosoftCalendarData]) {
  updateHostProfile(profile: $profile, data: {googleCalendars: $googleBloks}) {
    result {
      ... on ProfileUpdateSuccess {
        hostProfile {
          googleCalendars {
            ...GCalendarInfo
          }
        }
      }
      ... on ProfileUpdateError {
        code
        description
      }
    }
  }
}
    ${GCalendarInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGoogleBlockerCalendarGQL extends Apollo.Mutation<SetGoogleBlockerCalendarMutation, SetGoogleBlockerCalendarMutationVariables> {
    document = SetGoogleBlockerCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetMsEventCalendarDocument = gql`
    mutation SetMSEventCalendar($profile: ID!, $calendarId: Int!) {
  updateHostProfile(
    profile: $profile
    data: {microsoftCalendars: [{id: $calendarId, createEvent: true}]}
  ) {
    result {
      ... on ProfileUpdateSuccess {
        hostProfile {
          microsoftCalendars {
            ...MSCalendarInfo
          }
        }
      }
      ... on ProfileUpdateError {
        code
        description
      }
    }
  }
}
    ${MsCalendarInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMsEventCalendarGQL extends Apollo.Mutation<SetMsEventCalendarMutation, SetMsEventCalendarMutationVariables> {
    document = SetMsEventCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHostDocument = gql`
    query GetHost {
  hostProfile {
    ...FullHost
  }
}
    ${FullHostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHostGQL extends Apollo.Query<GetHostQuery, GetHostQueryVariables> {
    document = GetHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllowedHostDocument = gql`
    query GetAllowedHost {
  hostProfiles {
    edges {
      node {
        ...FullHost
      }
    }
  }
}
    ${FullHostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllowedHostGQL extends Apollo.Query<GetAllowedHostQuery, GetAllowedHostQueryVariables> {
    document = GetAllowedHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTeamHostProfilesDocument = gql`
    query GetTeamHostProfiles($team: ID) {
  team(id: $team) {
    members {
      edges {
        node {
          ...FullHost
        }
      }
    }
  }
}
    ${FullHostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTeamHostProfilesGQL extends Apollo.Query<GetTeamHostProfilesQuery, GetTeamHostProfilesQueryVariables> {
    document = GetTeamHostProfilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHostDocument = gql`
    mutation updateHost($host: UpdateHostProfileData, $id: ID!) {
  updateHostProfile(data: $host, profile: $id) {
    result {
      __typename
      ... on ProfileUpdateError {
        code
        description
      }
      ... on ProfileUpdateSuccess {
        hostProfile {
          ...FullHost
        }
      }
    }
  }
}
    ${FullHostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHostGQL extends Apollo.Mutation<UpdateHostMutation, UpdateHostMutationVariables> {
    document = UpdateHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadIntegrationsDocument = gql`
    query LoadIntegrations {
  integration {
    zoom
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadIntegrationsGQL extends Apollo.Query<LoadIntegrationsQuery, LoadIntegrationsQueryVariables> {
    document = LoadIntegrationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTeamDocument = gql`
    query GetTeam($id: ID!) {
  team(id: $id) {
    ...TeamShort
  }
}
    ${TeamShortFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTeamGQL extends Apollo.Query<GetTeamQuery, GetTeamQueryVariables> {
    document = GetTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllTeamsDocument = gql`
    query GetAllTeams {
  teams {
    edges {
      node {
        ...TeamShort
      }
    }
  }
}
    ${TeamShortFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllTeamsGQL extends Apollo.Query<GetAllTeamsQuery, GetAllTeamsQueryVariables> {
    document = GetAllTeamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query GetUser {
  user {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserData!) {
  updateUser(data: $user) {
    result {
      __typename
      user {
        ...FullUser
      }
    }
  }
}
    ${FullUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGoogleIntegrationDocument = gql`
    mutation DeleteGoogleIntegration {
  deleteGoogleIntegration {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGoogleIntegrationGQL extends Apollo.Mutation<DeleteGoogleIntegrationMutation, DeleteGoogleIntegrationMutationVariables> {
    document = DeleteGoogleIntegrationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }