import {
    Mode,
    RequestContext,
    Account,
    AllowedLanguages,
    Team,
    PersonalHostProfile,
    TeamMemberHostProfile,
    Tariff,
    TariffPlans
} from '../models';

export function numericDecline(count: number, words: string[]): string {
    const cases: ReadonlyArray<number> = [2, 0, 1, 1, 1, 2] as const;
    return words[
        count % 100 > 4 && count % 100 < 20
            ? 2
            : cases[count % 10 < 5 ? count % 10 : 5]
    ];
}

export function getCurrentLanguage(): AllowedLanguages {
    return typeof window !== 'undefined' && /^\/ru(\/|$)/.test(window.location.pathname) ? AllowedLanguages.RU : AllowedLanguages.EN;
}

export function numericDeclineLocalized(count: number, wordsRU: string[], wordsEN: string[]): string {
    const language: AllowedLanguages = getCurrentLanguage();

    // in case of 3 ru variants and 2 en variants, duplicate last en to match
    // e.g ['час', 'часа', 'часов'], ['hour', 'hours'] --> ['час', 'часа', 'часов'], ['hour', 'hours', 'hours']
    if (wordsRU.length > wordsEN.length) {
        wordsEN.push(wordsEN[wordsEN.length - 1]);
    }

    return language === AllowedLanguages.RU ? numericDecline(count, wordsRU) : numericDecline(count, wordsEN);
}

export function serviceDuration(duration: number): string {
    const h: number = Math.floor(duration / 60);
    const m: number = duration % 60;

    let result: string = '';

    if (h > 0) {
        result = `${h} ${numericDeclineLocalized(h, ['час', 'часа', 'часов'], ['hour', 'hours'])} `;
    }

    if (m > 0) {
        result += `${m} ${numericDeclineLocalized(m, ['минута', 'минуты', 'минут'], ['minute', 'minutes'])}`;
    }

    return result;
}

export function getDaysOffset(duration: number): string {
    if (duration > 0) {
        const hours: number = 24;
        const minutes: number = 60;
        const seconds: number = 60;
        const milliseconds: number = 1000;

        const msPerDay: number = hours * minutes * seconds * milliseconds; // 86400000

        const d: number = Math.floor(duration / msPerDay);

        return `${d} ${numericDeclineLocalized(d, ['день', 'дня', 'дней'], ['day', 'days'])} `;
    } else {
        return '-';
    }
}

export function CopyToClipboard(str: string): void {
    const el: HTMLTextAreaElement = document.createElement('textarea'); // Create a <textarea> element
    el.value = str; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected: false | Range =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
}

export function GetAccountMode(account: Account): Mode | null {
    if (account instanceof Team) {
        return Mode.TEAM;
    } else if (account instanceof PersonalHostProfile) {
        return Mode.PERSONAL;
    } else if (account instanceof TeamMemberHostProfile) {
        return Mode.TEAM_MEMBER;
    }
    return null;
}

export function AllowForAccount(currentMode: Mode | null, needMode: Mode | Mode[]): boolean {
    const needModeArray: Mode[] = needMode instanceof Array ? needMode.slice() : [needMode];
    if (currentMode === null) {
        return false;
    } else {
        return needModeArray.includes(currentMode);
    }
}

const charMap: { [key: string]: string} = {
    'а': 'a',
    'б': 'b',
    'в': 'v',
    'д': 'd',
    'е': 'e',
    'з': 'z',
    'й': 'y',
    'к': 'k',
    'л': 'l',
    'м': 'm',
    'н': 'n',
    'о': 'o',
    'п': 'p',
    'р': 'r',
    'с': 's',
    'т': 't',
    'у': 'u',
    'ф': 'f',
    'ь': '',
    'г': 'g',
    'и': 'i',
    'ъ': '',
    'ы': 'i',
    'э': 'e',
    'ё': 'yo',
    'ж': 'zh',
    'х': 'kh',
    'ц': 'ts',
    'ч': 'ch',
    'ш': 'sh',
    'щ': 'shch',
    'ю': 'yu',
    'я': 'ya',
    ' ': '-',
};
export function Transliterate(word: string): string {
    return (word || '').toLowerCase()
        .split('')
        .map((char: string) => charMap[char] ?? char)
        .join('');
}

export function getContext(account: Account): RequestContext {
    return account instanceof Team ? { team: account.id } : { profile: account.id };
}

export function getRandomValue(max: number, min: number = 0): number {
    return Math.floor(Math.random() * max - min) + min;
}

export function getTariffPlanByOldOption(option: number | null): Tariff | undefined {
    const options: Tariff[] = [
        TariffPlans.Default,
        TariffPlans.Standard,
        TariffPlans.StandardAnnual,
        TariffPlans.Pro,
        TariffPlans.ProAnnual,
        TariffPlans.Lifetime
    ];

    debugger
    return options.find((plan: Tariff) => plan.option === option);
}
