export enum SCRIPT_NAMES_ENUM {
    APIS_GOOGLE_NEW = 'apis.google.new',
    CLOUDPAYMENTS = 'cloudpayments',
    CRISP = 'crisp'
}

export type ScriptNameType =
    | SCRIPT_NAMES_ENUM.APIS_GOOGLE_NEW
    | SCRIPT_NAMES_ENUM.CLOUDPAYMENTS
    | SCRIPT_NAMES_ENUM.CRISP;
