import { Component, ChangeDetectionStrategy, Input, OnInit, AfterContentChecked, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute, UrlTree, UrlSegment } from '@angular/router';

import { map, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { HostProfile, ActivityTypeStateModel } from '@common/util';

@Component({
    selector: 'qw-event-info',
    templateUrl: './event-info.component.html',
    styleUrls: ['./event-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventInfoComponent implements OnInit, AfterContentChecked, OnDestroy {
    @Input() public host: HostProfile;
    @Input() public activity: ActivityTypeStateModel;
    public showClose: boolean;
    public showBack: boolean;

    private readonly destroySource: Subject<void> = new Subject<void>();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    public ngOnInit(): void {
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd  => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url.includes('finish') || event.url.includes('location')),
            takeUntil(this.destroySource)
        ).subscribe((showBack: boolean) => this.showBack = showBack);

        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd  => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url.includes('cancellation')),
            takeUntil(this.destroySource)
        ).subscribe((showClose: boolean) => this.showClose = showClose);
    }

    public ngAfterContentChecked(): void {
        this.showBack = this.router.url.split('?')[0].includes('finish') || this.router.url.split('?')[0].includes('location');
        this.showClose = this.router.url.includes('cancellation');
    }

    public ngOnDestroy(): void {
        this.destroySource.next();
        this.destroySource.complete();
    }

    public GoBack(): void {
        const currentUrl: UrlTree = this.router.parseUrl(this.router.url);
        const segments: UrlSegment[] = currentUrl.root.children.primary.segments;
        const lastSegmentPath: string = segments[segments.length - 1].path;
        const commands: string[] = segments.slice(0, segments.length - 1).map((s: UrlSegment) => s.path);

        if (this.activity) {
            switch (lastSegmentPath) {
                case 'finish':
                    if (this.activity.locations.length > 1) {
                        commands.push('location');
                    } else {
                        commands.push('start');
                    }
                    break;

                case 'location':
                    commands.push('start');
                    break;

                default:
                    commands.pop();
            }

            void this.router.navigate(commands, { queryParams: this.route.snapshot.queryParams });
        }
    }

    public Close(): void {
        parent.postMessage({ name: 'Close Qlick widget' }, '*'); // closes parent iframe
        void this.router.navigate(['/']);
    }
}
