import { PaginationStateModel } from '../models';

export function convertPaginationApiModelToStateModel<A, S>(itemConverter: (item: A) => S, api: any): PaginationStateModel<S> {
    return {
        list: Boolean(api.list) ? api.list.map((item: A) => itemConverter(item)) : [],
        listSize: api.allCount ?? null,
        pageNumber: api.pageNumber ?? null,
        pageSize: api.pageSize ?? null,
        pagesCount: api.numPages ?? null,
        sortColumn: api.sortColumn ?? null,
        sortOrder: api.sortOrder ?? null,
        nextPage: api.next ?? null,
        previousPage: api.previous ?? null
    };
}
