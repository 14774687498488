import { Injectable } from '@angular/core';

import { map, tap } from 'rxjs/operators';

import { HostRepository, HostMapper, TeamMemberHostProfile } from '@common/util';
import {
    GetHostGQL,
    UpdateHostGQL,
    GetAllowedHostGQL,
    UpdateHostProfileData,
    GetTeamHostProfilesGQL
} from 'libs/common/util/src/lib/models/types-generated';

@Injectable()
export class HostGraphQLRepository extends HostRepository {
    private mapper: HostMapper = new HostMapper();

    constructor(
        private readonly getGQL: GetHostGQL,
        private readonly getAllowedGQL: GetAllowedHostGQL,
        private readonly getTeamProfilesGQL: GetTeamHostProfilesGQL,
        private readonly updateGQL: UpdateHostGQL
    ) {
        super();
    }

    public getHost() {
        return this.getGQL.fetch().pipe(
            map(answer => this.mapper.mapFrom(answer.data.hostProfile))
        );
    }

    public getAllowedHosts() {
        return this.getAllowedGQL.fetch().pipe(
            map(answer => {
                return answer.data.hostProfiles?.edges?.map(data => this.mapper.mapFrom(data.node));
            })
        );
    }

    public getTeamHostProfiles(team: string) {
        return this.getTeamProfilesGQL.fetch({ team }).pipe(
            map(answer => answer.data.team.members),
            map(members => {
                return members.edges.map(data => this.mapper.mapFrom(data.node) as TeamMemberHostProfile);
            })
        );
    }

    public updateHost(id: string, data: UpdateHostProfileData) {
        return this.updateGQL.mutate({
            id, host: data
        }).pipe(
            map(answer => answer.data.updateHostProfile.result),
            map(updateResult => {
                if (updateResult.__typename === 'ProfileUpdateSuccess') {
                    return this.mapper.mapFrom(updateResult.hostProfile);
                } else {
                    throw new Error(updateResult.description);
                }
            })
        );
    }
}
