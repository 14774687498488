import { ActivityTypeStateModel } from './activity-type-state.model';
import { TARIFF_PLAN_NAME_ENUM, TariffPlanName } from '../enums';
import { Team, TeamRole } from './team.model';

export interface Plan {
    id: number;
    name: string;
    currency: string;
    activityTypeLimit: number;
}

export interface Option {
    id: number;
    duration: number;
    price: number;
}

export interface HostProfile {
    id: string;
    timezone: string;
    description: string;
    jobTitle: string;
    avatar: string;
    phone: string;
    slug: string;
    fullName: string;
    email: string;
    trackingId?: string;
    timezone_str?: string;
    job_title?: string;

    paymentData?: { cloudPaymentId?: string, cloudPaymentApiKey?: string };
}

export class PersonalHostProfile implements HostProfile {
    id: string;
    timezone: string;
    description: string;
    jobTitle: string;
    avatar: string;
    activityTypes: ActivityTypeStateModel[];

    fullName: string;
    email: string;
    slug: string;
    phone: string;
    expires: Date;

    plan?: Plan;
    option?: Option;
    timezone_str?: string;
    job_title?: string;

    paymentData: { cloudPaymentId?: string, cloudPaymentApiKey?: string };

    constructor(obj: Partial<PersonalHostProfile>) {
        Object.assign(this, obj);
    }

    public get PlanId(): TariffPlanName {
        switch (this.plan?.id) {
            case 3: return TARIFF_PLAN_NAME_ENUM.STANDARD;
            case 4: return TARIFF_PLAN_NAME_ENUM.PRO;
            default: return TARIFF_PLAN_NAME_ENUM.DEFAULT;
        }
    }
}

export class TeamMemberHostProfile implements HostProfile {
    id: string;
    timezone: string;
    description: string;
    jobTitle: string;
    avatar: string;
    activityTypes: ActivityTypeStateModel[];

    fullName: string;
    email: string;
    phone: string;
    team: Team;
    teamRole: TeamRole;
    calendarsCount: number;

    timezone_str?: string;
    job_title?: string;

    constructor(obj: Partial<TeamMemberHostProfile>) {
        Object.assign(this, obj);
    }

    get slug(): string {
        return this.team.slug;
    }
}

export class TeamMemberHostProfileShort implements Partial<HostProfile> {
    avatar: string;
    email: string;
    id: string;
    fullName: string;
    phone: string;
    slug: string;
    timezone: string;
}
