import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRepository, UserMapper, User } from '@common/util';
import { UserData, GetUserGQL, UpdateUserGQL } from 'libs/common/util/src/lib/models/types-generated';

@Injectable()
export class UserGraphQLRepository extends UserRepository {
    mapper = new UserMapper();

    constructor(
        private userGQL: GetUserGQL,
        private updateUserGQL: UpdateUserGQL,
    ) {
        super();
    }

    getUser(): Observable<User> {
        return this.userGQL.fetch().pipe(
            map(answer => this.mapper.mapFrom(answer.data.user))
        );
    }

    updateUser(data: UserData): Observable<User> {
        return this.updateUserGQL.mutate({
            user: data
        }).pipe(
            // @ts-ignore
            map(answer => answer.data.updateUser.result),
            map(updateResult => {
                if (updateResult.__typename === 'UserDataUpdateSuccess') {
                    return this.mapper.mapFrom(updateResult.user);
                }
                throw new Error('UserNotUpdate');
            })
        );
    }
}
