import { CURRENCY_ENUM, TARIFF_PLAN_NAME_ENUM, TariffPlanName } from '../enums';
import { LOCALIZATION_TARIFF_DEFAULT, LOCALIZATION_TARIFF_PRO, LOCALIZATION_TARIFF_STANDARD } from '../constants';

export const PlansList: TariffPlanName[] = [TARIFF_PLAN_NAME_ENUM.DEFAULT, TARIFF_PLAN_NAME_ENUM.STANDARD, TARIFF_PLAN_NAME_ENUM.PRO];

export interface IPaymentPlanOption {
    id: number;
    duration: number;
    price: number;
    description: string;
}

export interface IPaymentPlan {
    id: number;
    name: TariffPlanName;
    activityTypeLimit: number;
    currency: CURRENCY_ENUM;
    options: IPaymentPlanOption[];
    description?: string;
}

export interface IPaymentPlansResponse {
    plans: IPaymentPlan[];
    activeOptionId: number;
}

export interface Tariff {
    option: number;
    name: string;
    description: string;
    currency: CURRENCY_ENUM;
    activityTypeLimit: number;
    amount?: number;
    durationInMonth?: number;
}

export namespace TariffPlans {
    export const Default: Tariff = {
        option: null,
        name: LOCALIZATION_TARIFF_DEFAULT,
        description: 'Бесплатный тариф, доступен один формат встреч',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 1
    };

    export const Standard: Tariff = {
        option: 1,
        name: LOCALIZATION_TARIFF_STANDARD,
        description: 'Qlick Премиум на 1 месяц',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 0,
        amount: 700,
        durationInMonth: 1
    };

    export const StandardAnnual: Tariff = {
        option: 2,
        name: LOCALIZATION_TARIFF_STANDARD,
        description: 'Qlick Премиум на 1 год',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 0,
        amount: 7000
    };

    export const Pro: Tariff = {
        option: 3,
        name: LOCALIZATION_TARIFF_PRO,
        description: 'Qlick Про на 1 месяц',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 0,
        amount: 1100,
        durationInMonth: 1
    };

    export const ProAnnual: Tariff = {
        option: 4,
        name: LOCALIZATION_TARIFF_PRO,
        description: 'Qlick Про на 1 год',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 0,
        amount: 11000
    };

    export const Lifetime: Tariff = {
        option: 5,
        name: LOCALIZATION_TARIFF_PRO,
        description: 'Qlick Про навсегда со скидкой 81%',
        currency: CURRENCY_ENUM.RUB,
        activityTypeLimit: 0,
        amount: 17000
    };
}

export interface PaymentInfo {
    amount: number;
    token: string;
}
