import { Component } from '@angular/core';

@Component({
    selector: 'qw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public displayi18n: boolean = false;
    public oranges: number = 1;
}
