import { Observable } from 'rxjs';
import { ICalendar, ICalendarIntegration } from '../models';

export abstract class CalendarRepository {
    public abstract getIntegration(profile: string): Observable<ICalendarIntegration>;
    public abstract setEventCalendar(profile: string, calendarId: number): Observable<ICalendar[]>;
    public abstract setBlockCalendars(profile: string, blockersData: Array<ICalendar>): Observable<Array<ICalendar>>;
}

export abstract class GoogleCalendarRepository extends CalendarRepository {}
