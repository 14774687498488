export enum LOCATION_TYPES_ENUM {
    ZOOM = 'zoom',
    ZOOM_LINK = 'zoom_link',
    SKYPE = 'skype',
    GOOGLE = 'google',
    SBERJAZZ = 'sberjazz',
    JITSI = 'jitsi',
    WEBINAR = 'webinar',
    LIVE = 'live',
    PHONE = 'phone',
}

export type LocationType =
    | LOCATION_TYPES_ENUM.ZOOM
    | LOCATION_TYPES_ENUM.ZOOM_LINK
    | LOCATION_TYPES_ENUM.SKYPE
    | LOCATION_TYPES_ENUM.GOOGLE
    | LOCATION_TYPES_ENUM.SBERJAZZ
    | LOCATION_TYPES_ENUM.JITSI
    | LOCATION_TYPES_ENUM.WEBINAR
    | LOCATION_TYPES_ENUM.LIVE
    | LOCATION_TYPES_ENUM.PHONE;
