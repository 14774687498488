import { ContactApiModel, ContactStateModel } from '../models';
import { convertBaseHostApiModelToStateModel, convertBaseHostStateModelToApiModel } from './host-converter';

export function convertContactApiModelToStateModel(api: ContactApiModel): ContactStateModel {
    return {
        id: api.id ?? null,
        participant: convertBaseHostApiModelToStateModel(api.participant),
        lastEvent: api.last_record_dt ?? null,
        eventCount: api.records_count ?? null,
        phone: api.phone ?? null,
        comment: api.comment ?? null
    };
}

export function convertContactStateModelToApiModel(state: ContactStateModel): ContactApiModel {
    return {
        id: state.id ?? null,
        participant: convertBaseHostStateModelToApiModel(state.participant),
        last_record_dt: state.lastEvent,
        records_count: state.eventCount,
        phone: state.phone,
        comment: state.comment ?? null
    };
}
