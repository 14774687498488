import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FEATURE_KEY } from './state/feature.keys';
import { FEATURE_REDUCERS, featureReducers } from './state/feature.reducers';

import { CommonApiModule } from '@common/api';
import { SnackBarService } from '@common/util';

import { AppEffects } from './state/app/app.effects';
import { FeatureEffects } from './state/feature.effects';
import { SnackBarEffects } from './state/snack-bar/snack-bar.effects';

import { AppFacade } from './state/app/app.facade';
import { FeatureFacade } from './state/feature.facade';
import { SnackBarFacade } from './state/snack-bar/snack-bar.facade';

@NgModule({
    imports: [
        CommonModule,
        CommonApiModule,
        StoreModule.forFeature(FEATURE_KEY.FEATURE, FEATURE_REDUCERS),
        EffectsModule.forFeature([
            FeatureEffects,

            AppEffects,
            SnackBarEffects
        ])
    ],
    providers: [
        FeatureFacade,
        AppFacade,
        SnackBarFacade,
        SnackBarService,
        {
            provide: FEATURE_REDUCERS,
            useValue: featureReducers
        }
    ]
})
export class CommonDataAccessModule {}
