import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { UserStateModel } from '@common/util';
import { AppFacade } from '@common/data-access';

@Injectable()
export class AuthGuard  {
    constructor(private readonly appFacade: AppFacade, private readonly router: Router) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<true | UrlTree> {
        return this.Route(state.url);
    }

    private Route(newUrl: string): Observable<true | UrlTree> {
        return this.checkUser().pipe(
            switchMap(() => this.appFacade.user$),
            map((user: UserStateModel | null): true | UrlTree => {
                if (newUrl.includes('/login') && user?.id) {
                    return this.router.createUrlTree(['admin']);
                } else if (!newUrl.includes('/login') && !user?.id) {
                    return this.router.createUrlTree(['admin/login']);
                }

                return true;
            })
        );
    }

    private checkUser(): Observable<boolean> {
        // TODO: should be refactored
        return this.appFacade.isAccountChecked$.pipe(
            switchMap(() => this.appFacade.user$.pipe(
                take(1),
                tap((user: UserStateModel | null) => this.prefetch(!!user?.id)),
                switchMap(() => this.appFacade.isAccountChecked$.pipe(
                    filter(Boolean),
                    take(1)
                ))
            )),
            take(1),
        );
    }

    private prefetch(isChecked: boolean): void {
        if (!isChecked) {
            this.appFacade.loadUser();
        }
    }
}

// @Injectable()
// export class AuthResolveGuard implements Resolve<void> {
//     constructor(private readonly appFacade: AppFacade) {}
//
//     public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
//         return this.appFacade.user$.pipe(
//             take(1),
//             filter((user: UserStateModel) => !user?.id),
//             tap(() => this.appFacade.loadUserProfile()),
//             map(() => undefined)
//         );
//     }
// }
