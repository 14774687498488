import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlatformService {
    public readonly isPlatformBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) public readonly platformId: string) {
        this.isPlatformBrowser = isPlatformBrowser(platformId);
    }
}
