import { Pipe, PipeTransform } from '@angular/core';
import { TariffPlanName } from '../enums';
import { Account, AllowedLanguages, Mode, PersonalHostProfile, PlansList, Team, TeamMemberHostProfile, TeamRole } from '../models';
import { AllowForAccount } from '../helpers';

@Pipe({
    name: 'allowByPlanPermission',
    standalone: true
})
export class AllowByPlanPermissionPipe implements PipeTransform {
    public transform(requiredPlan: TariffPlanName, host: Account): boolean {
        if (host instanceof PersonalHostProfile) {
            const hostIndex: number = PlansList.indexOf(host.PlanId);
            const needIndex: number = PlansList.indexOf(requiredPlan);
            return hostIndex >= needIndex;
        }
        return true;
    }
}

@Pipe({
    name: 'flag',
    standalone: true
})
export class FlagPipe implements PipeTransform {
    public transform(location: AllowedLanguages): string {
        switch (location) {
            case AllowedLanguages.RU: return '🇷🇺';
            case AllowedLanguages.EN: return '🇬🇧';
            default: return '🇷🇺';
        }
    }
}

@Pipe({
    name: 'allowForAccount',
    standalone: true
})
export class AllowForAccountPipe implements PipeTransform {
    public transform(currentMode: Mode | null, needMode: Mode | Mode[]): boolean {
        return AllowForAccount(currentMode, needMode);
    }
}

@Pipe({
    name: 'accountDescription',
    standalone: true
})
export class AccountDescriptionPipe implements PipeTransform {
    public transform(account: Account): string | null {
       if (account instanceof Team) {
           return null;
       } else if (account instanceof PersonalHostProfile) {
           return $localize`:@@personalAccount:Персональный аккаунт`;
       } else if (account instanceof TeamMemberHostProfile) {
           return account.team.name;
       } else {
           return null;
       }
    }
}

@Pipe({
    name: 'teamRole',
    standalone: true
})
export class TeamRolePipe implements PipeTransform {
    public transform(role: TeamRole): string {
        switch (role) {
            case TeamRole.Admin: return 'Администратор';
            case TeamRole.Owner: return 'Владелец';
            case TeamRole.Member: return 'Участник';
        }
    }
}
