import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonApiService } from './common-api-service';
import { CommonApiFacade } from './common-api-facade';

@NgModule({
    imports: [CommonModule],
    providers: [CommonApiService, CommonApiFacade]
})
export class CommonApiModule {}
