import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
    private readonly defaultConfig: Readonly<MatSnackBarConfig> = {
        duration: 3000
    };

    constructor(private readonly snackBar: MatSnackBar) {}

    public default(
        message: string,
        action: string | undefined = undefined,
        config: MatSnackBarConfig = this.defaultConfig
    ): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, action, { ...config });
    }

    public warning(
        message: string,
        action: string | undefined = undefined,
        config: MatSnackBarConfig = this.defaultConfig
    ): MatSnackBarRef<TextOnlySnackBar> {
        config = { ...config, panelClass: 'snack-bar-warning' };
        return this.snackBar.open(message, action, { ...config });
    }

    public danger(
        message: string,
        action: string | undefined = undefined,
        config: MatSnackBarConfig = this.defaultConfig
    ): MatSnackBarRef<TextOnlySnackBar> {
        config = { ...config, panelClass: 'snack-bar-danger' };
        return this.snackBar.open(message, action, { ...config });
    }

    public success(
        message: string,
        action: string | undefined = undefined,
        config: MatSnackBarConfig = this.defaultConfig
    ): MatSnackBarRef<TextOnlySnackBar> {
        config = { ...config, panelClass: 'snack-bar-success' };
        return this.snackBar.open(message, action, { ...config });
    }

    public dismiss(): void {
        this.snackBar.dismiss();
    }
}
