import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Tariff } from '../models';
import { CURRENCY_ENUM } from '../enums';

export const PAYMENT_SERVICE_DATA: InjectionToken<{
    publicId: string
}> = new InjectionToken<{ publicId: string }>('PAYMENT_SERVICE_DATA');


export interface TariffPaymentData extends Tariff {
    hostProfileId: string;
    email?: string;
}
export interface OrderPaymentData {
    email: string;
    amount: number;
    token: string;
    description: string;
    hostPaymentId: string;
    currency: CURRENCY_ENUM;
}

@Injectable()
export abstract class PaymentService<TDATA= any, ODATA= any> {
    public abstract TariffPayment(params: TDATA): Observable<any>;
    public abstract OrderPayment(params: ODATA): Observable<any>;
}
