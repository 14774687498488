import { AppState, initialAppState } from './app.state';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromAppActions } from './app.actions';
import { ModelStatus } from '@common/util';

export const appReducer: ActionReducer<AppState> = createReducer(
    initialAppState,

    on(fromAppActions.Reset, (): AppState => ({
        ...initialAppState,
    })),
    on(fromAppActions.ResetError, (state: AppState): AppState => ({
        ...state,
        error: null
    })),
    on(fromAppActions.ResetIsChecked, (state: AppState): AppState => ({
        ...state,
        isAccountChecked: false
    })),

    // ================================
    //  Logout
    // ================================

    on(fromAppActions.Logout, (state: AppState): AppState => ({
        ...state,
        fetching: true,
        status: ModelStatus.Pending,
        error: null
    })),
    on(fromAppActions.LogoutSuccess, (): AppState => ({
        ...initialAppState
    })),
    on(fromAppActions.LogoutError, (): AppState => ({
        ...initialAppState
    })),

    // ================================
    //  Load User
    // ================================

    on(fromAppActions.LoadUser, (state: AppState): AppState => ({
        ...state,
        loading: true,
        status: ModelStatus.Pending,
        isAccountChecked: false,
        error: null
    })),
    on(fromAppActions.LoadUserSuccess, (state: AppState, { model }: fromAppActions.LoadUserSuccess): AppState => ({
        ...state,
        user: model,
        loading: false,
        status: ModelStatus.Success,
        isAccountChecked: true,
        error: null
    })),
    on(fromAppActions.LoadUserError, (state: AppState, { error }: fromAppActions.LoadUserError): AppState => ({
        ...state,
        loading: false,
        status: ModelStatus.Error,
        isAccountChecked: true,
        error: error ?? null
    })),

    // ================================
    //  Fetch User
    // ================================

    on(fromAppActions.FetchUser, (state: AppState): AppState => ({
        ...state,
        fetching: true,
        status: ModelStatus.Pending,
        error: null
    })),
    on(fromAppActions.FetchUserSuccess, (state: AppState, { model }: fromAppActions.FetchUserSuccess): AppState => ({
        ...state,
        user: model,
        fetching: false,
        status: ModelStatus.Success,
        error: null
    })),
    on(fromAppActions.FetchUserError, (state: AppState, { error }: fromAppActions.FetchUserError): AppState => ({
        ...state,
        fetching: false,
        status: ModelStatus.Error,
        error: error ?? null
    })),

    // ================================
    //  Load User Metadata
    // ================================

    on(fromAppActions.LoadUserMetadata, (state: AppState): AppState => ({
        ...state,
        error: null
    })),
    on(fromAppActions.LoadUserMetadataSuccess, (state: AppState, { model }: fromAppActions.LoadUserMetadataSuccess): AppState => ({
        ...state,
        userMetaData: model,
        error: null
    })),
    on(fromAppActions.LoadUserMetadataError, (state: AppState, { error }: fromAppActions.LoadUserMetadataError): AppState => ({
        ...state,
        error: error ?? null
    })),

    // ================================
    //  Update User
    // ================================

    on(fromAppActions.UpdateUser, (state: AppState): AppState => ({
        ...state,
        fetching: true,
        status: ModelStatus.Pending,
        error: null
    })),
    on(fromAppActions.UpdateUserSuccess, (state: AppState, { model }: fromAppActions.UpdateUserSuccess): AppState => ({
        ...state,
        user: model,
        fetching: false,
        status: ModelStatus.Success,
        error: null
    })),
    on(fromAppActions.UpdateUserError, (state: AppState, { error }: fromAppActions.UpdateUserError): AppState => ({
        ...state,
        fetching: false,
        status: ModelStatus.Error,
        error: error ?? null
    })),

    // ================================
    //  Repeat Confirmation Email
    // ================================

    on(fromAppActions.RepeatConfirmation, (state: AppState): AppState => ({
        ...state,
        status: ModelStatus.Pending,
        error: null
    })),
    on(fromAppActions.RepeatConfirmationSuccess, (state: AppState): AppState => ({
        ...state,
        status: ModelStatus.Success,
        error: null
    })),
    on(fromAppActions.RepeatConfirmationError, (state: AppState, { error }: fromAppActions.RepeatConfirmationError): AppState => ({
        ...state,
        status: ModelStatus.Error,
        error: error ?? null
    })),

    // ================================
    // Register
    // ================================

    on(fromAppActions.Register, (state: AppState): AppState => ({
        ...state,
        isRegistering: true,
        error: null,
    })),
    on(fromAppActions.RegisterSuccess, (state: AppState): AppState => ({
        ...state,
        isRegistering: false,
        isRegistered: true,
        error: null
    })),
    on(fromAppActions.RegisterError, (state: AppState, { error }: fromAppActions.RegisterError): AppState => ({
        ...state,
        isRegistering: false,
        isRegistered: false,
        error
    })),
);
