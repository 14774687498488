import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree, Params, UrlSegment } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ActivityTypeStateModel, HostAnswer } from '@common/util';
import { CommonApiService } from '@common/api';

// TODO: wrong place for this guard, need to move to qw-widget-core module
@Injectable()
export class WidgetExistGuard  {
    constructor(
        private readonly router: Router,
        private readonly apiFacade: CommonApiService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        if (route.url.length && route.url.length < 6) {
            const host: string = route.url[0].toString();
            const slug: string = route.url[1]?.toString();
            return this.CheckWidget(host, slug, route.url, route.queryParams);
        }
        return of(true);
    }

    private CheckWidget(host: string, slug: string, url: UrlSegment[], queryParams?: Params): Observable<boolean | UrlTree> {
        return this.apiFacade.loadHost(host).pipe(
            map((hostInfo: HostAnswer): UrlTree => {
                if (!slug) {
                    return this.router.createUrlTree(['widget', host], { queryParams });
                }

                const activity: ActivityTypeStateModel | undefined = hostInfo.activityTypes.find((at: ActivityTypeStateModel): boolean => {
                    return at.slug === slug;
                });

                if (!activity) {
                    return this.router.createUrlTree(['widget', host], { queryParams });
                }

                const urlStr: string = url.map((segment: UrlSegment) => segment.toString()).join('/');
                return this.router.createUrlTree([`widget/${urlStr}`], { queryParams });
            }),
            catchError(() => {
                return of(true);
            })
        );
    }
}
