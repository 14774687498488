import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import { TransferHttpCacheModule } from '@nguniversal/common';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

import { QwAdminCoreUtilModule } from '@qw-admin/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminShellComponent, NotFoundComponent } from '@common/components';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { clearStateReducer } from './clear-state.reducer';

import * as moment from 'moment-timezone';
import { NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';

import { environment } from '../environments/environment';
import { LoadEnvConfigFactory } from './app.config.factory';

registerLocaleData(localeRu, 'ru-RU');
moment.locale('ru');


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        AppRoutingModule,
        AdminShellComponent,
        NotFoundComponent,
        TransferHttpCacheModule,
        ApolloModule,
        QwAdminCoreUtilModule.forRoot(),
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictStateSerializability: true,
                    strictActionSerializability: true,
                    strictActionWithinNgZone: true,
                    strictActionTypeUniqueness: true
                },
                metaReducers: [clearStateReducer]
            }
        ),
        EffectsModule.forRoot([]),
        !environment.production
            ? StoreDevtoolsModule.instrument({ name: 'Qlick' })
            : []
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: ru_RU
        },
        {
            provide: LOCALE_ID,
            useValue: 'ru-RU'
        },
        {
            provide: APP_BASE_HREF,
            useValue: ((): string => {
                if (typeof window !== 'undefined' && /^\/ru(\/|$)/.test(window.location.pathname)) {
                    return '/ru';
                }

                return '/';
            })()
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useFactory: (location: string): string => {
                switch (location) {
                    case 'ru-RU':
                        return 'RUB';
                    default:
                        return 'USD';
                }
            },
            deps: [LOCALE_ID]
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: 'api/graphql',
                        withCredentials: true
                    }),

                    defaultOptions: {
                        watchQuery: { fetchPolicy: 'no-cache' },
                        query: { fetchPolicy: 'no-cache' },
                        mutate: { fetchPolicy: 'no-cache' },
                    },
                };
            },
            deps: [HttpLink],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: LoadEnvConfigFactory,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
