export enum CALENDAR_INTEGRATION_URL_ENUM {
    YANDEX = 'https://caldav.yandex.ru',
    ICLOUD = 'https://caldav.icloud.com',
    MAIL = 'https://calendar.mail.ru'
}

export type CalendarIntegrationUrl =
    | CALENDAR_INTEGRATION_URL_ENUM.YANDEX
    | CALENDAR_INTEGRATION_URL_ENUM.ICLOUD
    | CALENDAR_INTEGRATION_URL_ENUM.MAIL;
