import { CalendarIntegrationApiModel, ICalendarIntegration } from '../models';

export function convertCalendarIntegrationApiModelToStateModel(api: any): ICalendarIntegration[] {
    return api.map((el: any) => ({
        type: el.integration_type ?? null,
        id: el.id ?? null,
        hostId: el.host ?? null,

        // TODO change api model: use email instead of username
        email: el.username ?? null,
        serviceUrl: el.service_url ?? null,
        // calendarAccess: el.can_access ?? null,
        calendarAccess: true,
        calendars: el.calendars.map((c: any) => ({
            id: c.id ?? null,
            name: c.name ?? null,
            calendarUrl: c.calendar_url ?? null,
            includeBusy: c.include_busy ?? null,
            createEvent: c.can_write ?? null
        }))
    }));
}

export function convertCalendarIntegrationStateModelToApiModel(state: ICalendarIntegration): CalendarIntegrationApiModel {
    return {
        integration_type: state.type ?? null,
        id: state.id ?? null,
        host: state.hostId ?? null,
        username: state.email ?? null,
        service_url: state.serviceUrl ?? null,
        can_access: state.calendarAccess ?? null,
        password: '',
        calendars: state.calendars.map((c: any) => ({
            id: c.id ?? null,
            name: c.name ?? null,
            calendar_url: c.calendarUrl ?? null,
            include_busy: c.includeBusy ?? null,
            can_write: c.createEvent ?? null
        }))
    };
}
